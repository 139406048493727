import mock from '@/@fake-db/mock'
/* eslint-disable global-require */
const data = {
    "token": {
        "animationUrl": null,
        "artworkAsset": {
            "__typename": "MultimediaAsset",
            "contentType": "image/png",
            "filename": "01f07919-a2a0-4bfa-9627-51557fe2512e.png",
            "id": "01f07919-a2a0-4bfa-9627-51557fe2512e",
            "imageTransform": null
        },
        "id": "UmlnaHRzVG9rZW46NjlmYmRmMjktNzc4Yy00ZjU2LTgzMWUtYzBjMmY4YWNmNTNi",
        "mintNumber": 1,
        "royalId": "69fbdf29-778c-4f56-831e-c0c2f8acf53b",
        "tier": {
            "chainContract": {
                "address": "0x7c885c4bFd179fb59f1056FBea319D579A278075",
                "chainId": "137",
                "implAddress": "0x7c885c4bFd179fb59f1056FBea319D579A278075",
                "network": {
                    "explorers": [
                        {
                            "url": "https://polygonscan.com"
                        }
                    ],
                    "name": "Matic(Polygon) Mainnet"
                }
            },
            "edition": {
                "contributors": [
                    {
                        "profile": {
                            "avatarAsset": {
                                "__typename": "MultimediaAsset",
                                "contentType": "image/jpeg",
                                "filename": "3b563eb1-01c5-4f4d-8214-bb02eecbd4b5.jpg",
                                "id": "3b563eb1-01c5-4f4d-8214-bb02eecbd4b5",
                                "imageTransform": null
                            },
                            "name": "Nas",
                            "slug": "Nas"
                        },
                        "royaltyRightsPercentage": 50,
                        "royaltyRightsUnit": "ASSET"
                    }
                ],
                "permalink": "nas-rare",
                "rightsAsset": {
                    "childAssets": [],
                    "title": "Rare",
                    "type": "SINGLE"
                },
                "startTime": "2022-01-21T02:00:00.000000Z",
                "title": "Rare"
            },
            "maxSupply": 400,
            "tierSale": {
                "saleConfig": {
                    "__typename": "PrimeTierSaleStrategyFlash",
                    "startsAt": "2022-01-21T02:00:00Z"
                }
            },
            "type": "PLATINUM"
        },
        "tokenId": "2041694201525630780780247644590609268737",
        "wallet": {
            "address": "0xf5b0533bad49b29a41ea5b96fd1c43acbba37201",
            "chainId": "137",
            "id": "c5c1cda8-0c8c-4a6b-824b-49d210c9ef51",
            "source": "METAMASK",
            "user": null
        }
    }
}
/* eslint-disable global-require */
const tokenUri = "/token";
const url = new RegExp(`${tokenUri}/*`);

mock.onGet(url).reply(200, data);
