import mock from './mock'

/* eslint-disable import/extensions */

// Apps
import './data/apps/calendar'

// pages
import './data/pages/home'
import './data/pages/marketplace'
import './data/pages/artist'
import './data/pages/token'
import './data/pages/edition'
import './data/pages/myprofile'
import './data/pages/userprofile'
import './data/pages/dashboard'

/* eslint-enable import/extensions */

mock.onAny().passThrough() // forwards the matched request over network
