import mock from '@/@fake-db/mock'
/* eslint-disable global-require */
const data = {
    content:
        {
            "page":
                {
                    "id":
                        "UGFnZV9QYWdlOjE",
                    "slug":
                        "home",
                    "title":
                        "Royal - Own your favorite music.",
                    "description":
                        "Own music and earn royalties alongside artists, on Royal",
                    "coverImage":
                        null,
                    "content":
                        [
                            {
                                "__typename": "Content_TitleSection",
                                "title": "Invest in artists.",
                                "subtitle": "Own your favorite music. "
                            },
                            {
                                "__typename": "Content_HeroUnit",
                                "title": "Announcing Soon",
                                "titleTag": "sold out",
                                "image": {
                                    "url": "https://images.us-east-1.aws.slicknode.com/4272d063fd588930b016ca542d1824e85e3f22f3/2400x1094/palace-ui-royal-io/79b1c88a-23c2-4bd6-8412-2aecd3035670/40e9127d-4810-4400-ab52-0174ad40219c.jpg"
                                },
                                "contentNode": {
                                    "id": "Q29udGVudE5vZGU6NjU4MjcxMTAtNjg2Ny00YTYxLTk3MGUtNjBiOTMwZjA1ZDc2"
                                },
                                "ctaGuest": null,
                                "ctaUser": null,
                                "timer": null
                            },
                            {
                                "__typename": "Content_EditionList",
                                "title": "Featured Drops",
                                "editions": [
                                    {
                                        "id": "UmlnaHRzX0VkaXRpb246NA",
                                        "__typename": "Rights_Edition",
                                        "title": "Underrated",
                                        "statusLabel": "Sold Out",
                                        "permalink": "Ollie-Underrated",
                                        "asset": {
                                            "__typename": "Rights_EpAsset"
                                        },
                                        "coverImage": {
                                            "url": "https://images.us-east-1.aws.slicknode.com/d4dc56bb535f90e5a0a226c48506786beac1eb80/664x664/palace-ui-royal-io/adecaea3-a6d5-47a5-818b-ea72e41caade/0b53add2-ea49-45a1-860d-6ad859b9789a.jpeg"
                                        },
                                        "contributors": {
                                            "edges": [
                                                {
                                                    "node": {
                                                        "profile": {
                                                            "name": "Ollie",
                                                            "slug": "Ollie",
                                                            "avatarAsset": {
                                                                "url": "https://images.us-east-1.aws.slicknode.com/6a3714601bbd11dbaa19fe91f20c61dddf62d21c/48x48/smart/palace-ui-royal-io/27132389-c768-4483-ad93-c1da866d4e27/abbfa9d9-798a-4702-84e7-78214f73f12d.jpeg"
                                                            }
                                                        }
                                                    }
                                                }
                                            ]
                                        }
                                    },
                                    {
                                        "id": "UmlnaHRzX0VkaXRpb246MQ",
                                        "__typename": "Rights_Edition",
                                        "title": "He’s Not You",
                                        "statusLabel": "Sold Out",
                                        "permalink": "verite-hes-not-you",
                                        "asset": {
                                            "__typename": "Rights_SingleAsset"
                                        },
                                        "coverImage": {
                                            "url": "https://images.us-east-1.aws.slicknode.com/1ab065c57dc2cd539d983489a193f9baf64f1798/664x664/palace-ui-royal-io/788560d3-975b-4e13-a416-75e28701bf9c/6cc952c3-aa47-4d55-bcbc-9ab9709191f4.jpeg"
                                        },
                                        "contributors": {
                                            "edges": [
                                                {
                                                    "node": {
                                                        "profile": {
                                                            "name": "Vérité",
                                                            "slug": "Verite",
                                                            "avatarAsset": {
                                                                "url": "https://images.us-east-1.aws.slicknode.com/a6b0cf92c79b0abea2d88bd9e13a5ddcaf118185/48x48/smart/palace-ui-royal-io/16d0f23f-a3c1-4f6f-92c3-805046bcf2b8/7b38ec27-e2f8-491b-8d1b-5c9b08373519.jpeg"
                                                            }
                                                        }
                                                    }
                                                }
                                            ]
                                        }
                                    },
                                    {
                                        "id": "UmlnaHRzX0VkaXRpb246Mg",
                                        "__typename": "Rights_Edition",
                                        "title": "Ultra Black",
                                        "statusLabel": "Sold Out",
                                        "permalink": "Nas-UltraBlack",
                                        "asset": {
                                            "__typename": "Rights_SingleAsset"
                                        },
                                        "coverImage": {
                                            "url": "https://images.us-east-1.aws.slicknode.com/6bdadc4bf400f050493baa9571ac5cb296c16075/664x664/palace-ui-royal-io/6978ba63-acd8-4fd6-be98-d7654ec5fc3b/05229fc6-19b5-4da2-930b-d90829810b08.png"
                                        },
                                        "contributors": {
                                            "edges": [
                                                {
                                                    "node": {
                                                        "profile": {
                                                            "name": "Nas",
                                                            "slug": "Nas",
                                                            "avatarAsset": {
                                                                "url": "https://images.us-east-1.aws.slicknode.com/3e9d7bb2ea612aaed8f0c75ab27814b068544236/48x48/smart/palace-ui-royal-io/1cf0073a-e3c3-45ed-ad25-fb1e2412f264/395ba3a0-0cfa-4a12-9602-6f91d3522429.jpeg"
                                                            }
                                                        }
                                                    }
                                                }
                                            ]
                                        }
                                    },

                                ]
                            },
                            {
                                "__typename": "Content_FeatureList",
                                "title": "How it works",
                                "description": "Artists and fans co-own music together.\n",
                                "features": [
                                    {
                                        "id": "Q29udGVudF9GZWF0dXJlTGlzdEl0ZW06MQ",
                                        "title": "Artists sell.",
                                        "description": "Musicians use royal to sell royalty ownership in their songs and give collectors access to special perks.\n",
                                        "image": {
                                            "url": "https://images.us-east-1.aws.slicknode.com/da58dee653e2858bd511fe74dbca0d9005093de3/600x600/palace-ui-royal-io/6fcfd628-794d-46ef-bdfb-793ba4283caa/37f2b378-b8d8-4fa2-824c-1dc70daf1490.jpeg"
                                        }
                                    },
                                    {
                                        "id": "Q29udGVudF9GZWF0dXJlTGlzdEl0ZW06Mg",
                                        "title": "Collectors buy.",
                                        "description": "Collectors can buy royalty ownership in songs directly from their favorite artists in the form of tokens.\n",
                                        "image": {
                                            "url": "https://images.us-east-1.aws.slicknode.com/e83642a871529035c89e7b1beb5cd9ec7860756b/600x600/palace-ui-royal-io/5dc6a613-0ff8-4f97-b08d-4e0bddf493d7/96136dae-2528-4a84-9636-ca4a7aaceb6a.jpeg"
                                        }
                                    },
                                    {
                                        "id": "Q29udGVudF9GZWF0dXJlTGlzdEl0ZW06Mw",
                                        "title": "Both rise.",
                                        "description": "Collectors can claim royalties for the music they own after they’ve accrued. When artists grow, everyone wins.\n",
                                        "image": {
                                            "url": "https://images.us-east-1.aws.slicknode.com/7feec2efce4766c5eeb1d4ef8e9c8537ade7818d/600x600/palace-ui-royal-io/5ad595b3-5a8f-469e-86dc-47ea10f02e73/5f2e0e70-9cc0-4210-92be-0a9348652e17.jpeg"
                                        }
                                    }
                                ]
                            },
                            {
                                "__typename": "Content_CalloutBlock",
                                "id": "Q29udGVudF9DYWxsb3V0QmxvY2s6MQ",
                                "title": "We're building tools to connect artists and fans all around the world like never before.",
                                "titleTag": "Why royal"
                            },
                            {
                                "__typename": "Content_Banner",
                                "title": null,
                                "banner": {
                                    "url": "https://images.us-east-1.aws.slicknode.com/31ef4676b836b37b82ce70c9ce7b72a08e1c97f2/full-fit-in/x960/palace-ui-royal-io/467ff01e-7c7a-4c0f-959f-8508f34e2c89/07b17edb-046e-4c96-9761-7b333f54bde6.jpeg"
                                },
                                "link": null
                            },
                            {
                                "__typename": "Content_CtaSection",
                                "id": "Q29udGVudF9DdGFTZWN0aW9uOjE",
                                "title": "Are you an artist?",
                                "titleTag": "Artist",
                                "description": "Want to partner with us on this project? We'd love to hear from you. Please get in touch with us to get started.",
                                "ctaButton": {
                                    "label": "Apply for a drop",
                                    "url": "/artist-submission"
                                }
                            },
                            {
                                "__typename": "Content_TickerSection",
                                "title": "Hear from artists ///"
                            },
                            {
                                "__typename": "Content_TestimonialSection",
                                "testimonials": [
                                    {
                                        "id": "Q29udGVudF9UZXN0aW1vbmlhbDoy",
                                        "title": "Nas",
                                        "text": "\"I am excited to partner with Royal in order for the world to connect with my music in a new way.\"",
                                        "image": {
                                            "url": "https://images.us-east-1.aws.slicknode.com/cc11bd39158dd0245b14f1808919db4e3ab7b538/2000x/palace-ui-royal-io/dd826ca4-94d4-4885-80bb-1a0c85a1901b/f77aa88d-afa8-495f-b8dc-c3baa8b7fe62.jpg"
                                        }
                                    }
                                ]
                            },
                            {
                                "__typename": "Content_Accordion",
                                "id": "Q29udGVudF9BY2NvcmRpb246MQ",
                                "title": "Have questions? We got you.",
                                "titleTag": "FAQ",
                                "ctaButton": {
                                    "label": "Visit our help center",
                                    "url": "https://support.royal.io/hc/en-us"
                                },
                                "items": [
                                    {
                                        "id": "Q29udGVudF9BY2NvcmRpb25JdGVtOjE",
                                        "title": "What is royal?",
                                        "content": [
                                            {
                                                "__typename": "Content_Richtext",
                                                "text": "Royal enables anyone to own a piece of their favorite songs to earn royalties alongside the artist. We believe in artists' freedom. Royal opens the door for artists to retain independence and creative control as fans fuel their careers.\n"
                                            }
                                        ]
                                    },
                                    {
                                        "id": "Q29udGVudF9BY2NvcmRpb25JdGVtOjI",
                                        "title": "How does it work?",
                                        "content": [
                                            {
                                                "__typename": "Content_Richtext",
                                                "text": "Artists choose what % of the song's royalties to put up for sale. They can also bundle fan experiences, special tracks, digital art - it's all up to them.\n\nYou can purchase these streaming royalties in the form of 'tokens', directly from the artist. Any extra bundled benefits will be attached to this token.\n\nOnce you've bought a token, you can claim royalties for the song after they've accrued. The time to payout will vary depending on the artist.\n\nIf you'd like, you can put your token up for sale on an NFT exchange. When someone else buys this token, they'll get all the royalties and additional benefits that come with it."
                                            }
                                        ]
                                    },
                                    {
                                        "id": "Q29udGVudF9BY2NvcmRpb25JdGVtOjM",
                                        "title": "When I purchase a token, what do I get?",
                                        "content": [
                                            {
                                                "__typename": "Content_Richtext",
                                                "text": "Each token on Royal represents:\n- A percentage of the royalties from streaming services. This percentage is determined by which tier you purchase. The higher tier you own, the larger the royalty payout.\n- A license for non-commercial use of the the digital art associated with the token.\n- Any additional perks that the artist chooses to include."
                                            }
                                        ]
                                    },
                                    {
                                        "id": "Q29udGVudF9BY2NvcmRpb25JdGVtOjQ",
                                        "title": "How do I pay on royal?",
                                        "content": [
                                            {
                                                "__typename": "Content_Richtext",
                                                "text": "You can pay with credit card or crypto, using your own wallet or one we create for you. Learn more about how to purchase tokens in our help center [here](https://support.royal.io/hc/en-us/articles/4413332224787-How-can-I-purchase-a-token-)."
                                            }
                                        ]
                                    },
                                    {
                                        "id": "Q29udGVudF9BY2NvcmRpb25JdGVtOjU",
                                        "title": "What crypto wallet does royal support?",
                                        "content": [
                                            {
                                                "__typename": "Content_Richtext",
                                                "text": "You don't have to have an existing crypto wallet to purchase a token. But if you do, we support MetaMask."
                                            }
                                        ]
                                    }
                                ]
                            }
                        ]
                }
        }
}
/* eslint-disable global-require */
mock.onGet('/home/data').reply(() => [200, data.content])
