import mock from '@/@fake-db/mock'
/* eslint-disable global-require */
const data = {
    "data": {
        "tokens": {
            "edges": [
                {
                    "node": {
                        "__typename": "RightsToken",
                        "animationUrl": null,
                        "artworkAsset": {
                            "__typename": "MultimediaAsset",
                            "contentType": "image/png",
                            "filename": "01f07919-a2a0-4bfa-9627-51557fe2512e.png",
                            "id": "01f07919-a2a0-4bfa-9627-51557fe2512e",
                            "imageTransform": null
                        },
                        "id": "UmlnaHRzVG9rZW46NjlmYmRmMjktNzc4Yy00ZjU2LTgzMWUtYzBjMmY4YWNmNTNi",
                        "mintNumber": 1,
                        "royalId": "69fbdf29-778c-4f56-831e-c0c2f8acf53b",
                        "tier": {
                            "chainContract": {
                                "chainId": "137"
                            },
                            "edition": {
                                "contractAsset": {
                                    "downloadUrl": "https://royalio-prod-storage.s3.us-east-2.amazonaws.com/abfb0e7a-6d0a-4908-a3e9-cf87d1275349.pdf?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAXY35ED6HVSOV45B6%2F20220322%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Date=20220322T013738Z&X-Amz-Expires=3600&X-Amz-SignedHeaders=host&X-Amz-Signature=4f7cb8c4c09d3a41c607caa75e6e515cf5e41adab0f8f2e228295d2dd6be763b",
                                    "externalUrl": null,
                                    "filename": "abfb0e7a-6d0a-4908-a3e9-cf87d1275349.pdf",
                                    "type": "BLOB"
                                },
                                "contributors": [
                                    {
                                        "profile": {
                                            "avatarAsset": {
                                                "__typename": "MultimediaAsset",
                                                "contentType": "image/jpeg",
                                                "filename": "3b563eb1-01c5-4f4d-8214-bb02eecbd4b5.jpg",
                                                "id": "3b563eb1-01c5-4f4d-8214-bb02eecbd4b5",
                                                "imageTransform": null
                                            },
                                            "name": "Nas",
                                            "slug": "Nas"
                                        }
                                    }
                                ],
                                "permalink": "nas-rare",
                                "rightsAsset": {
                                    "childAssets": [],
                                    "title": "Rare",
                                    "type": "SINGLE"
                                },
                                "title": "Rare"
                            },
                            "maxSupply": 400,
                            "type": "PLATINUM"
                        },
                        "tokenId": "2041694201525630780780247644590609268737",
                        "wallet": {
                            "address": "0xf5b0533bad49b29a41ea5b96fd1c43acbba37201",
                            "chainId": "137",
                            "id": "c5c1cda8-0c8c-4a6b-824b-49d210c9ef51",
                            "source": "METAMASK",
                            "user": null
                        }
                    }
                },
                {
                    "node": {
                        "__typename": "RightsToken",
                        "animationUrl": null,
                        "artworkAsset": {
                            "__typename": "MultimediaAsset",
                            "contentType": "image/jpg",
                            "filename": "dde26975-0af0-4401-88e6-e7c6c145ae27.jpg",
                            "id": "dde26975-0af0-4401-88e6-e7c6c145ae27",
                            "imageTransform": null
                        },
                        "id": "UmlnaHRzVG9rZW46NjlhMTg2MmYtZTVmZC00ZTU5LWJiZDEtZjJiNGRiNzliZjRm",
                        "mintNumber": 1,
                        "royalId": "69a1862f-e5fd-4e59-bbd1-f2b4db79bf4f",
                        "tier": {
                            "chainContract": {
                                "chainId": "137"
                            },
                            "edition": {
                                "contractAsset": {
                                    "downloadUrl": "https://royalio-prod-storage.s3.us-east-2.amazonaws.com/5c87f98f-4b6f-43f9-bcc0-2f6a2bf73e89.pdf?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAXY35ED6HVSOV45B6%2F20220322%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Date=20220322T013738Z&X-Amz-Expires=3600&X-Amz-SignedHeaders=host&X-Amz-Signature=fb1841fdd807cd233a3f42d265ef82beacf1b8194af7bfe5699dfa35c0e4f6b1",
                                    "externalUrl": null,
                                    "filename": "5c87f98f-4b6f-43f9-bcc0-2f6a2bf73e89.pdf",
                                    "type": "BLOB"
                                },
                                "contributors": [
                                    {
                                        "profile": {
                                            "avatarAsset": {
                                                "__typename": "MultimediaAsset",
                                                "contentType": "image/jpg",
                                                "filename": "4730bb97-77cd-445c-b702-d127c9a85b55.jpg",
                                                "id": "4730bb97-77cd-445c-b702-d127c9a85b55",
                                                "imageTransform": null
                                            },
                                            "name": "Ollie",
                                            "slug": "Ollie"
                                        }
                                    }
                                ],
                                "permalink": "ollie-underrated",
                                "rightsAsset": {
                                    "childAssets": [
                                        {
                                            "royalId": "c8685afa-bd9b-463a-8fe6-9c181319a342"
                                        },
                                        {
                                            "royalId": "1cc88c34-57a4-4b50-96bb-fc091a4de418"
                                        },
                                        {
                                            "royalId": "6c7096bf-c384-4469-800b-f4a13514881b"
                                        },
                                        {
                                            "royalId": "97011f2b-6173-481b-aefe-78379c31df7a"
                                        }
                                    ],
                                    "title": "Underrated",
                                    "type": "EP"
                                },
                                "title": "Underrated"
                            },
                            "maxSupply": 50,
                            "type": "PLATINUM"
                        },
                        "tokenId": "9527906273786276976974489008089509920769",
                        "wallet": {
                            "address": "0x975461115fb1c728349be48b526241ff0b859870",
                            "chainId": "137",
                            "id": "2aa096c8-eaa4-45e8-9816-385754cccf95",
                            "source": "METAMASK",
                            "user": {
                                "profiles": [
                                    {
                                        "avatarAsset": null,
                                        "slug": "_UG1X4KLwsTYx13TR35N"
                                    }
                                ],
                                "royalId": "5da5d633-4bf6-4f27-96ae-b6c1a7f0d988"
                            }
                        }
                    }
                },
                {
                    "node": {
                        "__typename": "RightsToken",
                        "animationUrl": "https://api.royal.io/assets/26bd3138-af95-40e8-a443-c939cc3311bb",
                        "artworkAsset": {
                            "__typename": "MultimediaAsset",
                            "contentType": "image/png",
                            "filename": "69a8b75d-28bd-4754-8df8-81294d1c5d17.png",
                            "id": "69a8b75d-28bd-4754-8df8-81294d1c5d17",
                            "imageTransform": null
                        },
                        "id": "UmlnaHRzVG9rZW46YjdlYTU2MWQtMmY3Ny00NmI3LWEyOTAtNDE5ZmM4NWZiNThj",
                        "mintNumber": 1,
                        "royalId": "b7ea561d-2f77-46b7-a290-419fc85fb58c",
                        "tier": {
                            "chainContract": {
                                "chainId": "137"
                            },
                            "edition": {
                                "contractAsset": {
                                    "downloadUrl": "https://royalio-prod-storage.s3.us-east-2.amazonaws.com/5c87f98f-4b6f-43f9-bcc0-2f6a2bf73e89.pdf?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAXY35ED6HVSOV45B6%2F20220322%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Date=20220322T013738Z&X-Amz-Expires=3600&X-Amz-SignedHeaders=host&X-Amz-Signature=fb1841fdd807cd233a3f42d265ef82beacf1b8194af7bfe5699dfa35c0e4f6b1",
                                    "externalUrl": null,
                                    "filename": "5c87f98f-4b6f-43f9-bcc0-2f6a2bf73e89.pdf",
                                    "type": "BLOB"
                                },
                                "contributors": [
                                    {
                                        "profile": {
                                            "avatarAsset": {
                                                "__typename": "MultimediaAsset",
                                                "contentType": "image/jpg",
                                                "filename": "4730bb97-77cd-445c-b702-d127c9a85b55.jpg",
                                                "id": "4730bb97-77cd-445c-b702-d127c9a85b55",
                                                "imageTransform": null
                                            },
                                            "name": "Ollie",
                                            "slug": "Ollie"
                                        }
                                    }
                                ],
                                "permalink": "ollie-underrated",
                                "rightsAsset": {
                                    "childAssets": [
                                        {
                                            "royalId": "c8685afa-bd9b-463a-8fe6-9c181319a342"
                                        },
                                        {
                                            "royalId": "1cc88c34-57a4-4b50-96bb-fc091a4de418"
                                        },
                                        {
                                            "royalId": "6c7096bf-c384-4469-800b-f4a13514881b"
                                        },
                                        {
                                            "royalId": "97011f2b-6173-481b-aefe-78379c31df7a"
                                        }
                                    ],
                                    "title": "Underrated",
                                    "type": "EP"
                                },
                                "title": "Underrated"
                            },
                            "maxSupply": 10,
                            "type": "DIAMOND"
                        },
                        "tokenId": "9187623906865338513511114400657741709313",
                        "wallet": {
                            "address": "0xc5bf5e2b135d1e07660ead4ee76e46062d41238e",
                            "chainId": "137",
                            "id": "f6cfbaf4-edb9-4f01-bb01-b609bb6ed335",
                            "source": "METAMASK",
                            "user": {
                                "profiles": [
                                    {
                                        "avatarAsset": {
                                            "__typename": "MultimediaAsset",
                                            "contentType": "image/png",
                                            "filename": "8d385580-24ea-42f6-9f49-afe5e88ec0eb.png",
                                            "id": "8d385580-24ea-42f6-9f49-afe5e88ec0eb",
                                            "imageTransform": null
                                        },
                                        "slug": "UKHwnWJMbDQD7N6Jgg9l"
                                    }
                                ],
                                "royalId": "1d18a9be-11f7-4115-9b4c-5b5a80cefb9b"
                            }
                        }
                    }
                },
                {
                    "node": {
                        "__typename": "RightsToken",
                        "animationUrl": null,
                        "artworkAsset": {
                            "__typename": "MultimediaAsset",
                            "contentType": "image/jpeg",
                            "filename": "a0f3fc4a-b6c8-48d9-b2d0-1db317256471.jpg",
                            "id": "a0f3fc4a-b6c8-48d9-b2d0-1db317256471",
                            "imageTransform": null
                        },
                        "id": "UmlnaHRzVG9rZW46MjJmOWFjYTQtODk2OC00NjI0LWJmNjktZGYzYTE1OWRmZDBl",
                        "mintNumber": 1,
                        "royalId": "22f9aca4-8968-4624-bf69-df3a159dfd0e",
                        "tier": {
                            "chainContract": {
                                "chainId": "1"
                            },
                            "edition": {
                                "contractAsset": {
                                    "downloadUrl": "https://royalio-prod-storage.s3.us-east-2.amazonaws.com/a2fe6b89-a80e-4bca-b5de-0ab77a915ce8.pdf?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAXY35ED6HVSOV45B6%2F20220322%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Date=20220322T013738Z&X-Amz-Expires=3600&X-Amz-SignedHeaders=host&X-Amz-Signature=d9a2fb0e9b43d8ad47f4ddb509faf766c9926774fd2e022eb6a4392a01db09fe",
                                    "externalUrl": null,
                                    "filename": "a2fe6b89-a80e-4bca-b5de-0ab77a915ce8.pdf",
                                    "type": "BLOB"
                                },
                                "contributors": [
                                    {
                                        "profile": {
                                            "avatarAsset": {
                                                "__typename": "MultimediaAsset",
                                                "contentType": "image/jpeg",
                                                "filename": "e9b746d6-7aa7-412b-9b18-06e9db4c33d4.jpg",
                                                "id": "e9b746d6-7aa7-412b-9b18-06e9db4c33d4",
                                                "imageTransform": null
                                            },
                                            "name": "3LAU",
                                            "slug": "3LAU"
                                        }
                                    }
                                ],
                                "permalink": "waveform",
                                "rightsAsset": {
                                    "childAssets": [],
                                    "title": "WAVEFORM",
                                    "type": "SINGLE"
                                },
                                "title": "WAVEFORM"
                            },
                            "maxSupply": 1,
                            "type": "PLATINUM"
                        },
                        "tokenId": "680564733841876926926749214863536422913",
                        "wallet": {
                            "address": "0x336A447B2e382DDe1cFf3532C8218e1F57757a7d",
                            "chainId": "1",
                            "id": "dc1ddefc-d272-4c89-b74a-54165fb2086a",
                            "source": "METAMASK",
                            "user": null
                        }
                    }
                },
                {
                    "node": {
                        "__typename": "RightsToken",
                        "animationUrl": null,
                        "artworkAsset": {
                            "__typename": "MultimediaAsset",
                            "contentType": "image/png",
                            "filename": "1027ae94-993d-4e7b-8554-c36ac2aa33b5.png",
                            "id": "1027ae94-993d-4e7b-8554-c36ac2aa33b5",
                            "imageTransform": null
                        },
                        "id": "UmlnaHRzVG9rZW46MTU5YWJkYzMtY2Y5MS00YTBhLTk4MTktNGZmMzAzZGFhM2Ux",
                        "mintNumber": 1,
                        "royalId": "159abdc3-cf91-4a0a-9819-4ff303daa3e1",
                        "tier": {
                            "chainContract": {
                                "chainId": "137"
                            },
                            "edition": {
                                "contractAsset": {
                                    "downloadUrl": "https://royalio-prod-storage.s3.us-east-2.amazonaws.com/1cdd01aa-f6db-445f-bf1a-d4fa2f78be3e.pdf?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAXY35ED6HVSOV45B6%2F20220322%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Date=20220322T013738Z&X-Amz-Expires=3600&X-Amz-SignedHeaders=host&X-Amz-Signature=f900eace01b01e9a88e68256068a57397bf0d94887768501184e926f3e44ab54",
                                    "externalUrl": null,
                                    "filename": "1cdd01aa-f6db-445f-bf1a-d4fa2f78be3e.pdf",
                                    "type": "BLOB"
                                },
                                "contributors": [
                                    {
                                        "profile": {
                                            "avatarAsset": {
                                                "__typename": "MultimediaAsset",
                                                "contentType": "image/jpeg",
                                                "filename": "3b563eb1-01c5-4f4d-8214-bb02eecbd4b5.jpg",
                                                "id": "3b563eb1-01c5-4f4d-8214-bb02eecbd4b5",
                                                "imageTransform": null
                                            },
                                            "name": "Nas",
                                            "slug": "Nas"
                                        }
                                    }
                                ],
                                "permalink": "nas-ultrablack",
                                "rightsAsset": {
                                    "childAssets": [],
                                    "title": "Ultra Black",
                                    "type": "SINGLE"
                                },
                                "title": "Ultra Black"
                            },
                            "maxSupply": 500,
                            "type": "GOLD"
                        },
                        "tokenId": "1361129467683753853853498429727072845825",
                        "wallet": {
                            "address": "0x1C3a11189aF8c60E21FE09b193532A0232F9F6D2",
                            "chainId": "137",
                            "id": "7e3dc908-f8fb-4108-a045-4dc627d12bb6",
                            "source": "ROYAL",
                            "user": {
                                "profiles": [
                                    {
                                        "avatarAsset": {
                                            "__typename": "MultimediaAsset",
                                            "contentType": "image/jpeg",
                                            "filename": "99a37927-40a2-4fec-af07-fb2dc1761d87.jpg",
                                            "id": "99a37927-40a2-4fec-af07-fb2dc1761d87",
                                            "imageTransform": null
                                        },
                                        "slug": "Carib2G"
                                    }
                                ],
                                "royalId": "f26a006f-40e4-4808-9162-911dcf3c1630"
                            }
                        }
                    }
                },
                {
                    "node": {
                        "__typename": "RightsToken",
                        "animationUrl": null,
                        "artworkAsset": {
                            "__typename": "MultimediaAsset",
                            "contentType": "image/png",
                            "filename": "2be7a7d6-216a-4b77-9466-f4895e22f455.png",
                            "id": "2be7a7d6-216a-4b77-9466-f4895e22f455",
                            "imageTransform": null
                        },
                        "id": "UmlnaHRzVG9rZW46Mjc4ZGFkOTEtOTAyMC00MzVmLWFkZDYtNTA1NjAzNmVhOTFh",
                        "mintNumber": 1,
                        "royalId": "278dad91-9020-435f-add6-5056036ea91a",
                        "tier": {
                            "chainContract": {
                                "chainId": "137"
                            },
                            "edition": {
                                "contractAsset": {
                                    "downloadUrl": "https://royalio-prod-storage.s3.us-east-2.amazonaws.com/ded95421-05dd-4f1b-b928-faf51fa446c7.pdf?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAXY35ED6HVSOV45B6%2F20220322%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Date=20220322T013738Z&X-Amz-Expires=3600&X-Amz-SignedHeaders=host&X-Amz-Signature=62f93ef46aab4459c065cefdd6ad67ee0eb153ada44dd1a12a77727460cdcd28",
                                    "externalUrl": null,
                                    "filename": "ded95421-05dd-4f1b-b928-faf51fa446c7.pdf",
                                    "type": "BLOB"
                                },
                                "contributors": [
                                    {
                                        "profile": {
                                            "avatarAsset": {
                                                "__typename": "MultimediaAsset",
                                                "contentType": "image/jpg",
                                                "filename": "aefd8869-318e-4226-8cf6-0c5a3c39aadf.jpeg",
                                                "id": "aefd8869-318e-4226-8cf6-0c5a3c39aadf",
                                                "imageTransform": null
                                            },
                                            "name": "Vérité",
                                            "slug": "Verite"
                                        }
                                    }
                                ],
                                "permalink": "verite-hes-not-you",
                                "rightsAsset": {
                                    "childAssets": [],
                                    "title": "He's Not You",
                                    "type": "SINGLE"
                                },
                                "title": "He's Not You"
                            },
                            "maxSupply": 450,
                            "type": "GOLD"
                        },
                        "tokenId": "8847341539944400050047739793225973497857",
                        "wallet": {
                            "address": "0xa3e235957f651401176372f261df0eae7bf77c1d",
                            "chainId": "137",
                            "id": "4f0daae8-fd5d-4c78-b4bf-c2a2e78cd802",
                            "source": "METAMASK",
                            "user": {
                                "profiles": [
                                    {
                                        "avatarAsset": {
                                            "__typename": "MultimediaAsset",
                                            "contentType": "image/png",
                                            "filename": "233daf06-ab20-4bcc-a546-3a8f37572d45.png",
                                            "id": "233daf06-ab20-4bcc-a546-3a8f37572d45",
                                            "imageTransform": null
                                        },
                                        "slug": "patrickwiden"
                                    }
                                ],
                                "royalId": "78f930f1-dcc0-47ec-9044-53ce7b2013a7"
                            }
                        }
                    }
                },
                {
                    "node": {
                        "__typename": "RightsToken",
                        "animationUrl": null,
                        "artworkAsset": {
                            "__typename": "MultimediaAsset",
                            "contentType": "image/gif",
                            "filename": "247f632c-1f03-45b1-92cd-012bbc18f5e8.gif",
                            "id": "247f632c-1f03-45b1-92cd-012bbc18f5e8",
                            "imageTransform": null
                        },
                        "id": "UmlnaHRzVG9rZW46NmQ2NDVlNTQtMTk1My00YzFmLTgxM2YtNGJhMzgxY2E2Zjgw",
                        "mintNumber": 1,
                        "royalId": "6d645e54-1953-4c1f-813f-4ba381ca6f80",
                        "tier": {
                            "chainContract": {
                                "chainId": "137"
                            },
                            "edition": {
                                "contractAsset": {
                                    "downloadUrl": "https://royalio-prod-storage.s3.us-east-2.amazonaws.com/abfb0e7a-6d0a-4908-a3e9-cf87d1275349.pdf?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAXY35ED6HVSOV45B6%2F20220322%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Date=20220322T013738Z&X-Amz-Expires=3600&X-Amz-SignedHeaders=host&X-Amz-Signature=4f7cb8c4c09d3a41c607caa75e6e515cf5e41adab0f8f2e228295d2dd6be763b",
                                    "externalUrl": null,
                                    "filename": "abfb0e7a-6d0a-4908-a3e9-cf87d1275349.pdf",
                                    "type": "BLOB"
                                },
                                "contributors": [
                                    {
                                        "profile": {
                                            "avatarAsset": {
                                                "__typename": "MultimediaAsset",
                                                "contentType": "image/jpeg",
                                                "filename": "3b563eb1-01c5-4f4d-8214-bb02eecbd4b5.jpg",
                                                "id": "3b563eb1-01c5-4f4d-8214-bb02eecbd4b5",
                                                "imageTransform": null
                                            },
                                            "name": "Nas",
                                            "slug": "Nas"
                                        }
                                    }
                                ],
                                "permalink": "nas-rare",
                                "rightsAsset": {
                                    "childAssets": [],
                                    "title": "Rare",
                                    "type": "SINGLE"
                                },
                                "title": "Rare"
                            },
                            "maxSupply": 10,
                            "type": "DIAMOND"
                        },
                        "tokenId": "2722258935367507707706996859454145691649",
                        "wallet": {
                            "address": "0xbf02ba2db8316e7b0a9ce4ec3c632c1e9bc1aafc",
                            "chainId": "137",
                            "id": "93839335-0f4a-47ec-97a1-ce1df3edb4d6",
                            "source": "METAMASK",
                            "user": null
                        }
                    }
                },
                {
                    "node": {
                        "__typename": "RightsToken",
                        "animationUrl": null,
                        "artworkAsset": {
                            "__typename": "MultimediaAsset",
                            "contentType": "image/png",
                            "filename": "f1a5e6c1-fb14-46a5-848b-852c6787c3a1.png",
                            "id": "f1a5e6c1-fb14-46a5-848b-852c6787c3a1",
                            "imageTransform": null
                        },
                        "id": "UmlnaHRzVG9rZW46MGQyOGFjZDEtNmUwZS00NzhhLTg1MWUtMWIxNmI5YWEzMDU3",
                        "mintNumber": 1,
                        "royalId": "0d28acd1-6e0e-478a-851e-1b16b9aa3057",
                        "tier": {
                            "chainContract": {
                                "chainId": "137"
                            },
                            "edition": {
                                "contractAsset": {
                                    "downloadUrl": "https://royalio-prod-storage.s3.us-east-2.amazonaws.com/ded95421-05dd-4f1b-b928-faf51fa446c7.pdf?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAXY35ED6HVSOV45B6%2F20220322%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Date=20220322T013738Z&X-Amz-Expires=3600&X-Amz-SignedHeaders=host&X-Amz-Signature=62f93ef46aab4459c065cefdd6ad67ee0eb153ada44dd1a12a77727460cdcd28",
                                    "externalUrl": null,
                                    "filename": "ded95421-05dd-4f1b-b928-faf51fa446c7.pdf",
                                    "type": "BLOB"
                                },
                                "contributors": [
                                    {
                                        "profile": {
                                            "avatarAsset": {
                                                "__typename": "MultimediaAsset",
                                                "contentType": "image/jpg",
                                                "filename": "aefd8869-318e-4226-8cf6-0c5a3c39aadf.jpeg",
                                                "id": "aefd8869-318e-4226-8cf6-0c5a3c39aadf",
                                                "imageTransform": null
                                            },
                                            "name": "Vérité",
                                            "slug": "Verite"
                                        }
                                    }
                                ],
                                "permalink": "verite-hes-not-you",
                                "rightsAsset": {
                                    "childAssets": [],
                                    "title": "He's Not You",
                                    "type": "SINGLE"
                                },
                                "title": "He's Not You"
                            },
                            "maxSupply": 50,
                            "type": "PLATINUM"
                        },
                        "tokenId": "8507059173023461586584365185794205286401",
                        "wallet": {
                            "address": "0x96acf191c0112806f9709366bad77642b99b21a9",
                            "chainId": "137",
                            "id": "c1604fc5-dfaa-4b05-82bd-199807daf2f6",
                            "source": "METAMASK",
                            "user": {
                                "profiles": [
                                    {
                                        "avatarAsset": null,
                                        "slug": "C7D"
                                    }
                                ],
                                "royalId": "8d474b92-09ab-4e65-b659-ab9170eed3d1"
                            }
                        }
                    }
                },
                {
                    "node": {
                        "__typename": "RightsToken",
                        "animationUrl": null,
                        "artworkAsset": {
                            "__typename": "MultimediaAsset",
                            "contentType": "image/png",
                            "filename": "13fa6984-6403-4748-8f7c-83fe89824d9f.png",
                            "id": "13fa6984-6403-4748-8f7c-83fe89824d9f",
                            "imageTransform": null
                        },
                        "id": "UmlnaHRzVG9rZW46ZDEyZTYyMTItZWI3Zi00NzBkLTg4N2UtNTM5NWRkZWUyMWU1",
                        "mintNumber": 1,
                        "royalId": "d12e6212-eb7f-470d-887e-5395ddee21e5",
                        "tier": {
                            "chainContract": {
                                "chainId": "137"
                            },
                            "edition": {
                                "contractAsset": {
                                    "downloadUrl": "https://royalio-prod-storage.s3.us-east-2.amazonaws.com/1cdd01aa-f6db-445f-bf1a-d4fa2f78be3e.pdf?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAXY35ED6HVSOV45B6%2F20220322%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Date=20220322T013738Z&X-Amz-Expires=3600&X-Amz-SignedHeaders=host&X-Amz-Signature=f900eace01b01e9a88e68256068a57397bf0d94887768501184e926f3e44ab54",
                                    "externalUrl": null,
                                    "filename": "1cdd01aa-f6db-445f-bf1a-d4fa2f78be3e.pdf",
                                    "type": "BLOB"
                                },
                                "contributors": [
                                    {
                                        "profile": {
                                            "avatarAsset": {
                                                "__typename": "MultimediaAsset",
                                                "contentType": "image/jpeg",
                                                "filename": "3b563eb1-01c5-4f4d-8214-bb02eecbd4b5.jpg",
                                                "id": "3b563eb1-01c5-4f4d-8214-bb02eecbd4b5",
                                                "imageTransform": null
                                            },
                                            "name": "Nas",
                                            "slug": "Nas"
                                        }
                                    }
                                ],
                                "permalink": "nas-ultrablack",
                                "rightsAsset": {
                                    "childAssets": [],
                                    "title": "Ultra Black",
                                    "type": "SINGLE"
                                },
                                "title": "Ultra Black"
                            },
                            "maxSupply": 250,
                            "type": "PLATINUM"
                        },
                        "tokenId": "1020847100762815390390123822295304634369",
                        "wallet": {
                            "address": "0xf5b0533bad49b29a41ea5b96fd1c43acbba37201",
                            "chainId": "137",
                            "id": "c5c1cda8-0c8c-4a6b-824b-49d210c9ef51",
                            "source": "METAMASK",
                            "user": null
                        }
                    }
                },
                {
                    "node": {
                        "__typename": "RightsToken",
                        "animationUrl": "https://api.royal.io/assets/ac66e4b4-6ee2-4a9f-8c46-840b0527b6e8",
                        "artworkAsset": {
                            "__typename": "MultimediaAsset",
                            "contentType": "image/jpeg",
                            "filename": "58a781ab-f2e1-4fb3-993e-b05c2cc255ec.jpg",
                            "id": "58a781ab-f2e1-4fb3-993e-b05c2cc255ec",
                            "imageTransform": null
                        },
                        "id": "UmlnaHRzVG9rZW46ZTFjNjRjNzgtYzVjNS00MWEyLWE2ZmYtY2VjOTk0N2YzMDA4",
                        "mintNumber": 1,
                        "royalId": "e1c64c78-c5c5-41a2-a6ff-cec9947f3008",
                        "tier": {
                            "chainContract": {
                                "chainId": "137"
                            },
                            "edition": {
                                "contractAsset": {
                                    "downloadUrl": "https://royalio-prod-storage.s3.us-east-2.amazonaws.com/ded95421-05dd-4f1b-b928-faf51fa446c7.pdf?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAXY35ED6HVSOV45B6%2F20220322%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Date=20220322T013738Z&X-Amz-Expires=3600&X-Amz-SignedHeaders=host&X-Amz-Signature=62f93ef46aab4459c065cefdd6ad67ee0eb153ada44dd1a12a77727460cdcd28",
                                    "externalUrl": null,
                                    "filename": "ded95421-05dd-4f1b-b928-faf51fa446c7.pdf",
                                    "type": "BLOB"
                                },
                                "contributors": [
                                    {
                                        "profile": {
                                            "avatarAsset": {
                                                "__typename": "MultimediaAsset",
                                                "contentType": "image/jpg",
                                                "filename": "aefd8869-318e-4226-8cf6-0c5a3c39aadf.jpeg",
                                                "id": "aefd8869-318e-4226-8cf6-0c5a3c39aadf",
                                                "imageTransform": null
                                            },
                                            "name": "Vérité",
                                            "slug": "Verite"
                                        }
                                    }
                                ],
                                "permalink": "verite-hes-not-you",
                                "rightsAsset": {
                                    "childAssets": [],
                                    "title": "He's Not You",
                                    "type": "SINGLE"
                                },
                                "title": "He's Not You"
                            },
                            "maxSupply": 5,
                            "type": "DIAMOND"
                        },
                        "tokenId": "8166776806102523123120990578362437074945",
                        "wallet": {
                            "address": "0xfdb26046b3138f5a812fffe027527c0bf9c4d686",
                            "chainId": "137",
                            "id": "f5197d2a-4acb-4bb8-b6a0-a5f499c9bac0",
                            "source": "METAMASK",
                            "user": {
                                "profiles": [
                                    {
                                        "avatarAsset": null,
                                        "slug": "Birdman"
                                    }
                                ],
                                "royalId": "242f21c7-c926-460c-a68e-6013d4cf573b"
                            }
                        }
                    }
                },
                {
                    "node": {
                        "__typename": "RightsToken",
                        "animationUrl": null,
                        "artworkAsset": {
                            "__typename": "MultimediaAsset",
                            "contentType": "image/jpg",
                            "filename": "772d79b8-c3ff-4856-ac8b-1b22a80d162a.jpg",
                            "id": "772d79b8-c3ff-4856-ac8b-1b22a80d162a",
                            "imageTransform": null
                        },
                        "id": "UmlnaHRzVG9rZW46ZmYxNGVlNWUtOWY1ZS00MWY1LTgxMzQtMWFjNWY5NGFhNDg4",
                        "mintNumber": 1,
                        "royalId": "ff14ee5e-9f5e-41f5-8134-1ac5f94aa488",
                        "tier": {
                            "chainContract": {
                                "chainId": "137"
                            },
                            "edition": {
                                "contractAsset": {
                                    "downloadUrl": "https://royalio-prod-storage.s3.us-east-2.amazonaws.com/5c87f98f-4b6f-43f9-bcc0-2f6a2bf73e89.pdf?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAXY35ED6HVSOV45B6%2F20220322%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Date=20220322T013738Z&X-Amz-Expires=3600&X-Amz-SignedHeaders=host&X-Amz-Signature=fb1841fdd807cd233a3f42d265ef82beacf1b8194af7bfe5699dfa35c0e4f6b1",
                                    "externalUrl": null,
                                    "filename": "5c87f98f-4b6f-43f9-bcc0-2f6a2bf73e89.pdf",
                                    "type": "BLOB"
                                },
                                "contributors": [
                                    {
                                        "profile": {
                                            "avatarAsset": {
                                                "__typename": "MultimediaAsset",
                                                "contentType": "image/jpg",
                                                "filename": "4730bb97-77cd-445c-b702-d127c9a85b55.jpg",
                                                "id": "4730bb97-77cd-445c-b702-d127c9a85b55",
                                                "imageTransform": null
                                            },
                                            "name": "Ollie",
                                            "slug": "Ollie"
                                        }
                                    }
                                ],
                                "permalink": "ollie-underrated",
                                "rightsAsset": {
                                    "childAssets": [
                                        {
                                            "royalId": "c8685afa-bd9b-463a-8fe6-9c181319a342"
                                        },
                                        {
                                            "royalId": "1cc88c34-57a4-4b50-96bb-fc091a4de418"
                                        },
                                        {
                                            "royalId": "6c7096bf-c384-4469-800b-f4a13514881b"
                                        },
                                        {
                                            "royalId": "97011f2b-6173-481b-aefe-78379c31df7a"
                                        }
                                    ],
                                    "title": "Underrated",
                                    "type": "EP"
                                },
                                "title": "Underrated"
                            },
                            "maxSupply": 600,
                            "type": "GOLD"
                        },
                        "tokenId": "9868188640707215440437863615521278132225",
                        "wallet": {
                            "address": "0x599ee25595a338453a0e758c0c674eef710c15bd",
                            "chainId": "137",
                            "id": "467a23a4-1b26-42a9-a3c1-dbdd51296dae",
                            "source": "METAMASK",
                            "user": {
                                "profiles": [
                                    {
                                        "avatarAsset": null,
                                        "slug": "cord"
                                    }
                                ],
                                "royalId": "4e1319e1-f59f-486d-a541-15e96d97d979"
                            }
                        }
                    }
                },
                {
                    "node": {
                        "__typename": "RightsToken",
                        "animationUrl": null,
                        "artworkAsset": {
                            "__typename": "MultimediaAsset",
                            "contentType": "image/png",
                            "filename": "027597b0-2638-431f-9ee2-2da4b8b0ceec.png",
                            "id": "027597b0-2638-431f-9ee2-2da4b8b0ceec",
                            "imageTransform": null
                        },
                        "id": "UmlnaHRzVG9rZW46YzViYjhiN2YtODI4Mi00NTQ2LTgwNTQtZjAxN2EyNmRhZGYx",
                        "mintNumber": 1,
                        "royalId": "c5bb8b7f-8282-4546-8054-f017a26dadf1",
                        "tier": {
                            "chainContract": {
                                "chainId": "137"
                            },
                            "edition": {
                                "contractAsset": {
                                    "downloadUrl": "https://royalio-prod-storage.s3.us-east-2.amazonaws.com/abfb0e7a-6d0a-4908-a3e9-cf87d1275349.pdf?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAXY35ED6HVSOV45B6%2F20220322%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Date=20220322T013738Z&X-Amz-Expires=3600&X-Amz-SignedHeaders=host&X-Amz-Signature=4f7cb8c4c09d3a41c607caa75e6e515cf5e41adab0f8f2e228295d2dd6be763b",
                                    "externalUrl": null,
                                    "filename": "abfb0e7a-6d0a-4908-a3e9-cf87d1275349.pdf",
                                    "type": "BLOB"
                                },
                                "contributors": [
                                    {
                                        "profile": {
                                            "avatarAsset": {
                                                "__typename": "MultimediaAsset",
                                                "contentType": "image/jpeg",
                                                "filename": "3b563eb1-01c5-4f4d-8214-bb02eecbd4b5.jpg",
                                                "id": "3b563eb1-01c5-4f4d-8214-bb02eecbd4b5",
                                                "imageTransform": null
                                            },
                                            "name": "Nas",
                                            "slug": "Nas"
                                        }
                                    }
                                ],
                                "permalink": "nas-rare",
                                "rightsAsset": {
                                    "childAssets": [],
                                    "title": "Rare",
                                    "type": "SINGLE"
                                },
                                "title": "Rare"
                            },
                            "maxSupply": 700,
                            "type": "GOLD"
                        },
                        "tokenId": "2381976568446569244243622252022377480193",
                        "wallet": {
                            "address": "0x74408ca60244aab6406ecfa50ffdd33de78a0038",
                            "chainId": "137",
                            "id": "4b049c32-11cd-462e-91f7-d0b9fc11c2bf",
                            "source": "METAMASK",
                            "user": {
                                "profiles": [
                                    {
                                        "avatarAsset": null,
                                        "slug": "DUgehgKAYU9DR7SfXhkS"
                                    }
                                ],
                                "royalId": "b40acc1c-219b-4dfe-8d44-5ebf4f8a600d"
                            }
                        }
                    }
                },
                {
                    "node": {
                        "__typename": "RightsToken",
                        "animationUrl": null,
                        "artworkAsset": {
                            "__typename": "MultimediaAsset",
                            "contentType": "image/jpeg",
                            "filename": "fe62891a-5155-49ab-b2b0-ab45a3fce852.jpg",
                            "id": "fe62891a-5155-49ab-b2b0-ab45a3fce852",
                            "imageTransform": null
                        },
                        "id": "UmlnaHRzVG9rZW46MjI4ZGRkYzAtNTY2Yy00NDRiLThmOGQtMWFmNjhjODUzNTRm",
                        "mintNumber": 1,
                        "royalId": "228dddc0-566c-444b-8f8d-1af68c85354f",
                        "tier": {
                            "chainContract": {
                                "chainId": "1"
                            },
                            "edition": {
                                "contractAsset": {
                                    "downloadUrl": "https://royalio-prod-storage.s3.us-east-2.amazonaws.com/c8e8c5a5-b91d-4508-9bee-3fd67ab28b5d.pdf?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAXY35ED6HVSOV45B6%2F20220322%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Date=20220322T013738Z&X-Amz-Expires=3600&X-Amz-SignedHeaders=host&X-Amz-Signature=bdac10e03b5e75462f4e083e84c41a69d8bfca070100f64cce3ecb849e0dec4a",
                                    "externalUrl": null,
                                    "filename": "c8e8c5a5-b91d-4508-9bee-3fd67ab28b5d.pdf",
                                    "type": "BLOB"
                                },
                                "contributors": [
                                    {
                                        "profile": {
                                            "avatarAsset": {
                                                "__typename": "MultimediaAsset",
                                                "contentType": "image/jpeg",
                                                "filename": "e9b746d6-7aa7-412b-9b18-06e9db4c33d4.jpg",
                                                "id": "e9b746d6-7aa7-412b-9b18-06e9db4c33d4",
                                                "imageTransform": null
                                            },
                                            "name": "3LAU",
                                            "slug": "3LAU"
                                        }
                                    }
                                ],
                                "permalink": "worst-case",
                                "rightsAsset": {
                                    "childAssets": [],
                                    "title": "Worst Case",
                                    "type": "SINGLE"
                                },
                                "title": "Worst Case"
                            },
                            "maxSupply": 333,
                            "type": "PLATINUM"
                        },
                        "tokenId": "340282366920938463463374607431768211457",
                        "wallet": {
                            "address": "0x43862f5cab1975bc67916e8cec099c8f1e618c7f",
                            "chainId": "1",
                            "id": "ab3425fc-026d-4e3b-a883-50a761f3853e",
                            "source": "METAMASK",
                            "user": {
                                "profiles": [
                                    {
                                        "avatarAsset": {
                                            "__typename": "MultimediaAsset",
                                            "contentType": "image/png",
                                            "filename": "7fc05443-5293-4e75-b0a6-c4f502089cf8.png",
                                            "id": "7fc05443-5293-4e75-b0a6-c4f502089cf8",
                                            "imageTransform": null
                                        },
                                        "slug": "dr-manhattan"
                                    }
                                ],
                                "royalId": "6ff91b2c-ed4a-4ede-8d1f-4405988aeb5b"
                            }
                        }
                    }
                },
                {
                    "node": {
                        "__typename": "RightsToken",
                        "animationUrl": null,
                        "artworkAsset": {
                            "__typename": "MultimediaAsset",
                            "contentType": "image/gif",
                            "filename": "9e79d667-858b-40f2-9ce8-faf4364a6edb.gif",
                            "id": "9e79d667-858b-40f2-9ce8-faf4364a6edb",
                            "imageTransform": null
                        },
                        "id": "UmlnaHRzVG9rZW46YTNjOGVlOWEtOTU3NS00NmY0LWIxNzYtNjA1Yjk4MTliNjFj",
                        "mintNumber": 1,
                        "royalId": "a3c8ee9a-9575-46f4-b176-605b9819b61c",
                        "tier": {
                            "chainContract": {
                                "chainId": "137"
                            },
                            "edition": {
                                "contractAsset": {
                                    "downloadUrl": "https://royalio-prod-storage.s3.us-east-2.amazonaws.com/1cdd01aa-f6db-445f-bf1a-d4fa2f78be3e.pdf?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAXY35ED6HVSOV45B6%2F20220322%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Date=20220322T013738Z&X-Amz-Expires=3600&X-Amz-SignedHeaders=host&X-Amz-Signature=f900eace01b01e9a88e68256068a57397bf0d94887768501184e926f3e44ab54",
                                    "externalUrl": null,
                                    "filename": "1cdd01aa-f6db-445f-bf1a-d4fa2f78be3e.pdf",
                                    "type": "BLOB"
                                },
                                "contributors": [
                                    {
                                        "profile": {
                                            "avatarAsset": {
                                                "__typename": "MultimediaAsset",
                                                "contentType": "image/jpeg",
                                                "filename": "3b563eb1-01c5-4f4d-8214-bb02eecbd4b5.jpg",
                                                "id": "3b563eb1-01c5-4f4d-8214-bb02eecbd4b5",
                                                "imageTransform": null
                                            },
                                            "name": "Nas",
                                            "slug": "Nas"
                                        }
                                    }
                                ],
                                "permalink": "nas-ultrablack",
                                "rightsAsset": {
                                    "childAssets": [],
                                    "title": "Ultra Black",
                                    "type": "SINGLE"
                                },
                                "title": "Ultra Black"
                            },
                            "maxSupply": 10,
                            "type": "DIAMOND"
                        },
                        "tokenId": "1701411834604692317316873037158841057281",
                        "wallet": {
                            "address": "0x4dfda666cce4b0f26c49900ded45f42f705398c7",
                            "chainId": "137",
                            "id": "616c06db-5472-4368-b95f-5511345862b7",
                            "source": "METAMASK",
                            "user": null
                        }
                    }
                },
                {
                    "node": {
                        "__typename": "RightsToken",
                        "animationUrl": null,
                        "artworkAsset": {
                            "__typename": "MultimediaAsset",
                            "contentType": "image/png",
                            "filename": "028ba345-0f02-4fa5-b17a-fa0df2510927.png",
                            "id": "028ba345-0f02-4fa5-b17a-fa0df2510927",
                            "imageTransform": null
                        },
                        "id": "UmlnaHRzVG9rZW46MzBiMzU3NzQtNWJiMC00YTJkLTkzZjMtOGZkMDE5ZjM2MTI5",
                        "mintNumber": 2,
                        "royalId": "30b35774-5bb0-4a2d-93f3-8fd019f36129",
                        "tier": {
                            "chainContract": {
                                "chainId": "137"
                            },
                            "edition": {
                                "contractAsset": {
                                    "downloadUrl": "https://royalio-prod-storage.s3.us-east-2.amazonaws.com/abfb0e7a-6d0a-4908-a3e9-cf87d1275349.pdf?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAXY35ED6HVSOV45B6%2F20220322%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Date=20220322T013738Z&X-Amz-Expires=3600&X-Amz-SignedHeaders=host&X-Amz-Signature=4f7cb8c4c09d3a41c607caa75e6e515cf5e41adab0f8f2e228295d2dd6be763b",
                                    "externalUrl": null,
                                    "filename": "abfb0e7a-6d0a-4908-a3e9-cf87d1275349.pdf",
                                    "type": "BLOB"
                                },
                                "contributors": [
                                    {
                                        "profile": {
                                            "avatarAsset": {
                                                "__typename": "MultimediaAsset",
                                                "contentType": "image/jpeg",
                                                "filename": "3b563eb1-01c5-4f4d-8214-bb02eecbd4b5.jpg",
                                                "id": "3b563eb1-01c5-4f4d-8214-bb02eecbd4b5",
                                                "imageTransform": null
                                            },
                                            "name": "Nas",
                                            "slug": "Nas"
                                        }
                                    }
                                ],
                                "permalink": "nas-rare",
                                "rightsAsset": {
                                    "childAssets": [],
                                    "title": "Rare",
                                    "type": "SINGLE"
                                },
                                "title": "Rare"
                            },
                            "maxSupply": 400,
                            "type": "PLATINUM"
                        },
                        "tokenId": "2041694201525630780780247644590609268738",
                        "wallet": {
                            "address": "0x2dd60ccd2dcb0652e600af5ca8682c579cc357c7",
                            "chainId": "137",
                            "id": "f56d50d1-c823-43a9-a60d-ab7b14b5ad4a",
                            "source": "METAMASK",
                            "user": null
                        }
                    }
                },
                {
                    "node": {
                        "__typename": "RightsToken",
                        "animationUrl": null,
                        "artworkAsset": {
                            "__typename": "MultimediaAsset",
                            "contentType": "image/jpg",
                            "filename": "4a774d75-bd11-4a7c-9a36-53d4128d8f13.jpg",
                            "id": "4a774d75-bd11-4a7c-9a36-53d4128d8f13",
                            "imageTransform": null
                        },
                        "id": "UmlnaHRzVG9rZW46M2IxNzBiMjktNzFiMS00NzRmLWI3YzAtMzY5MjZjODIwMGEz",
                        "mintNumber": 2,
                        "royalId": "3b170b29-71b1-474f-b7c0-36926c8200a3",
                        "tier": {
                            "chainContract": {
                                "chainId": "137"
                            },
                            "edition": {
                                "contractAsset": {
                                    "downloadUrl": "https://royalio-prod-storage.s3.us-east-2.amazonaws.com/5c87f98f-4b6f-43f9-bcc0-2f6a2bf73e89.pdf?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAXY35ED6HVSOV45B6%2F20220322%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Date=20220322T013738Z&X-Amz-Expires=3600&X-Amz-SignedHeaders=host&X-Amz-Signature=fb1841fdd807cd233a3f42d265ef82beacf1b8194af7bfe5699dfa35c0e4f6b1",
                                    "externalUrl": null,
                                    "filename": "5c87f98f-4b6f-43f9-bcc0-2f6a2bf73e89.pdf",
                                    "type": "BLOB"
                                },
                                "contributors": [
                                    {
                                        "profile": {
                                            "avatarAsset": {
                                                "__typename": "MultimediaAsset",
                                                "contentType": "image/jpg",
                                                "filename": "4730bb97-77cd-445c-b702-d127c9a85b55.jpg",
                                                "id": "4730bb97-77cd-445c-b702-d127c9a85b55",
                                                "imageTransform": null
                                            },
                                            "name": "Ollie",
                                            "slug": "Ollie"
                                        }
                                    }
                                ],
                                "permalink": "ollie-underrated",
                                "rightsAsset": {
                                    "childAssets": [
                                        {
                                            "royalId": "c8685afa-bd9b-463a-8fe6-9c181319a342"
                                        },
                                        {
                                            "royalId": "1cc88c34-57a4-4b50-96bb-fc091a4de418"
                                        },
                                        {
                                            "royalId": "6c7096bf-c384-4469-800b-f4a13514881b"
                                        },
                                        {
                                            "royalId": "97011f2b-6173-481b-aefe-78379c31df7a"
                                        }
                                    ],
                                    "title": "Underrated",
                                    "type": "EP"
                                },
                                "title": "Underrated"
                            },
                            "maxSupply": 50,
                            "type": "PLATINUM"
                        },
                        "tokenId": "9527906273786276976974489008089509920770",
                        "wallet": {
                            "address": "0xa275412889Cd6717a88c980fb6242631f884BD4d",
                            "chainId": "137",
                            "id": "544e0049-69fb-4966-87a1-1e5ac3c30573",
                            "source": "ROYAL",
                            "user": {
                                "profiles": [
                                    {
                                        "avatarAsset": null,
                                        "slug": "VfyOOi7K8eVRktbXKduG"
                                    }
                                ],
                                "royalId": "37417299-ca9f-49e4-927d-c7b1a6870adb"
                            }
                        }
                    }
                },
                {
                    "node": {
                        "__typename": "RightsToken",
                        "animationUrl": "https://api.royal.io/assets/4b1eca0a-328d-4c88-bb91-5941c9850a3c",
                        "artworkAsset": {
                            "__typename": "MultimediaAsset",
                            "contentType": "image/png",
                            "filename": "b55b54e2-46bc-4adf-bcc8-4e78f1a9577a.png",
                            "id": "b55b54e2-46bc-4adf-bcc8-4e78f1a9577a",
                            "imageTransform": null
                        },
                        "id": "UmlnaHRzVG9rZW46YTAxNzQ4ZDEtYTIxMy00NTUxLTgzYTUtNTg5ZDIyOWQ5ZWNi",
                        "mintNumber": 2,
                        "royalId": "a01748d1-a213-4551-83a5-589d229d9ecb",
                        "tier": {
                            "chainContract": {
                                "chainId": "137"
                            },
                            "edition": {
                                "contractAsset": {
                                    "downloadUrl": "https://royalio-prod-storage.s3.us-east-2.amazonaws.com/5c87f98f-4b6f-43f9-bcc0-2f6a2bf73e89.pdf?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAXY35ED6HVSOV45B6%2F20220322%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Date=20220322T013738Z&X-Amz-Expires=3600&X-Amz-SignedHeaders=host&X-Amz-Signature=fb1841fdd807cd233a3f42d265ef82beacf1b8194af7bfe5699dfa35c0e4f6b1",
                                    "externalUrl": null,
                                    "filename": "5c87f98f-4b6f-43f9-bcc0-2f6a2bf73e89.pdf",
                                    "type": "BLOB"
                                },
                                "contributors": [
                                    {
                                        "profile": {
                                            "avatarAsset": {
                                                "__typename": "MultimediaAsset",
                                                "contentType": "image/jpg",
                                                "filename": "4730bb97-77cd-445c-b702-d127c9a85b55.jpg",
                                                "id": "4730bb97-77cd-445c-b702-d127c9a85b55",
                                                "imageTransform": null
                                            },
                                            "name": "Ollie",
                                            "slug": "Ollie"
                                        }
                                    }
                                ],
                                "permalink": "ollie-underrated",
                                "rightsAsset": {
                                    "childAssets": [
                                        {
                                            "royalId": "c8685afa-bd9b-463a-8fe6-9c181319a342"
                                        },
                                        {
                                            "royalId": "1cc88c34-57a4-4b50-96bb-fc091a4de418"
                                        },
                                        {
                                            "royalId": "6c7096bf-c384-4469-800b-f4a13514881b"
                                        },
                                        {
                                            "royalId": "97011f2b-6173-481b-aefe-78379c31df7a"
                                        }
                                    ],
                                    "title": "Underrated",
                                    "type": "EP"
                                },
                                "title": "Underrated"
                            },
                            "maxSupply": 10,
                            "type": "DIAMOND"
                        },
                        "tokenId": "9187623906865338513511114400657741709314",
                        "wallet": {
                            "address": "0x07f97eea1fcba40eda1d27a294e8b237d4c8feb8",
                            "chainId": "137",
                            "id": "3e5844db-9c88-4877-900f-2960e6db3723",
                            "source": "METAMASK",
                            "user": {
                                "profiles": [
                                    {
                                        "avatarAsset": null,
                                        "slug": "vqOLSRwaHGBg1vFNFAxE"
                                    }
                                ],
                                "royalId": "001cbac9-704b-4d61-8883-ab1c1f89fb77"
                            }
                        }
                    }
                },
                {
                    "node": {
                        "__typename": "RightsToken",
                        "animationUrl": null,
                        "artworkAsset": {
                            "__typename": "MultimediaAsset",
                            "contentType": "image/png",
                            "filename": "1ee36542-f110-428f-baa0-ee46a106f9d3.png",
                            "id": "1ee36542-f110-428f-baa0-ee46a106f9d3",
                            "imageTransform": null
                        },
                        "id": "UmlnaHRzVG9rZW46MTk0ZmZmYjItNzA5NS00ZjlhLWJiNTctN2Y5MmJiNTllOTc1",
                        "mintNumber": 2,
                        "royalId": "194fffb2-7095-4f9a-bb57-7f92bb59e975",
                        "tier": {
                            "chainContract": {
                                "chainId": "137"
                            },
                            "edition": {
                                "contractAsset": {
                                    "downloadUrl": "https://royalio-prod-storage.s3.us-east-2.amazonaws.com/1cdd01aa-f6db-445f-bf1a-d4fa2f78be3e.pdf?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAXY35ED6HVSOV45B6%2F20220322%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Date=20220322T013738Z&X-Amz-Expires=3600&X-Amz-SignedHeaders=host&X-Amz-Signature=f900eace01b01e9a88e68256068a57397bf0d94887768501184e926f3e44ab54",
                                    "externalUrl": null,
                                    "filename": "1cdd01aa-f6db-445f-bf1a-d4fa2f78be3e.pdf",
                                    "type": "BLOB"
                                },
                                "contributors": [
                                    {
                                        "profile": {
                                            "avatarAsset": {
                                                "__typename": "MultimediaAsset",
                                                "contentType": "image/jpeg",
                                                "filename": "3b563eb1-01c5-4f4d-8214-bb02eecbd4b5.jpg",
                                                "id": "3b563eb1-01c5-4f4d-8214-bb02eecbd4b5",
                                                "imageTransform": null
                                            },
                                            "name": "Nas",
                                            "slug": "Nas"
                                        }
                                    }
                                ],
                                "permalink": "nas-ultrablack",
                                "rightsAsset": {
                                    "childAssets": [],
                                    "title": "Ultra Black",
                                    "type": "SINGLE"
                                },
                                "title": "Ultra Black"
                            },
                            "maxSupply": 500,
                            "type": "GOLD"
                        },
                        "tokenId": "1361129467683753853853498429727072845826",
                        "wallet": {
                            "address": "0x5382066ca9e8d4d84fc3a354e1fa2b7bc87744d4",
                            "chainId": "137",
                            "id": "06afdfe5-f8da-44dc-8dcd-3edf35d0f909",
                            "source": "METAMASK",
                            "user": {
                                "profiles": [
                                    {
                                        "avatarAsset": null,
                                        "slug": "RAdROcbJyLpuSfKc-Lrn"
                                    }
                                ],
                                "royalId": "5a99ea24-6b14-4013-8a76-ebc1ea2acfed"
                            }
                        }
                    }
                },
                {
                    "node": {
                        "__typename": "RightsToken",
                        "animationUrl": null,
                        "artworkAsset": {
                            "__typename": "MultimediaAsset",
                            "contentType": "image/png",
                            "filename": "ee083b41-c66f-472b-a83f-5bfddde8b14b.png",
                            "id": "ee083b41-c66f-472b-a83f-5bfddde8b14b",
                            "imageTransform": null
                        },
                        "id": "UmlnaHRzVG9rZW46NzQ3YzM2NGItNzU3Ni00N2Q3LTllOGEtNGI3NzQwNzRjMjFj",
                        "mintNumber": 2,
                        "royalId": "747c364b-7576-47d7-9e8a-4b774074c21c",
                        "tier": {
                            "chainContract": {
                                "chainId": "137"
                            },
                            "edition": {
                                "contractAsset": {
                                    "downloadUrl": "https://royalio-prod-storage.s3.us-east-2.amazonaws.com/ded95421-05dd-4f1b-b928-faf51fa446c7.pdf?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAXY35ED6HVSOV45B6%2F20220322%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Date=20220322T013738Z&X-Amz-Expires=3600&X-Amz-SignedHeaders=host&X-Amz-Signature=62f93ef46aab4459c065cefdd6ad67ee0eb153ada44dd1a12a77727460cdcd28",
                                    "externalUrl": null,
                                    "filename": "ded95421-05dd-4f1b-b928-faf51fa446c7.pdf",
                                    "type": "BLOB"
                                },
                                "contributors": [
                                    {
                                        "profile": {
                                            "avatarAsset": {
                                                "__typename": "MultimediaAsset",
                                                "contentType": "image/jpg",
                                                "filename": "aefd8869-318e-4226-8cf6-0c5a3c39aadf.jpeg",
                                                "id": "aefd8869-318e-4226-8cf6-0c5a3c39aadf",
                                                "imageTransform": null
                                            },
                                            "name": "Vérité",
                                            "slug": "Verite"
                                        }
                                    }
                                ],
                                "permalink": "verite-hes-not-you",
                                "rightsAsset": {
                                    "childAssets": [],
                                    "title": "He's Not You",
                                    "type": "SINGLE"
                                },
                                "title": "He's Not You"
                            },
                            "maxSupply": 450,
                            "type": "GOLD"
                        },
                        "tokenId": "8847341539944400050047739793225973497858",
                        "wallet": {
                            "address": "0xcad45c9937d4d156d42a5e14959d0356e8267672",
                            "chainId": "137",
                            "id": "5f0f0409-c4dd-4ead-b0e2-f92cea0b95db",
                            "source": "METAMASK",
                            "user": {
                                "profiles": [
                                    {
                                        "avatarAsset": null,
                                        "slug": "AlvinAllure"
                                    }
                                ],
                                "royalId": "39e815f9-a4a2-4455-aa56-a81d30bfb202"
                            }
                        }
                    }
                },
                {
                    "node": {
                        "__typename": "RightsToken",
                        "animationUrl": null,
                        "artworkAsset": {
                            "__typename": "MultimediaAsset",
                            "contentType": "image/gif",
                            "filename": "463452fa-55b3-4292-9595-d4e9181e4a8a.gif",
                            "id": "463452fa-55b3-4292-9595-d4e9181e4a8a",
                            "imageTransform": null
                        },
                        "id": "UmlnaHRzVG9rZW46NjY0YzQwNTAtYWE5ZS00YTcxLTgwYjAtYjgyN2Q3N2NiZmUx",
                        "mintNumber": 2,
                        "royalId": "664c4050-aa9e-4a71-80b0-b827d77cbfe1",
                        "tier": {
                            "chainContract": {
                                "chainId": "137"
                            },
                            "edition": {
                                "contractAsset": {
                                    "downloadUrl": "https://royalio-prod-storage.s3.us-east-2.amazonaws.com/abfb0e7a-6d0a-4908-a3e9-cf87d1275349.pdf?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAXY35ED6HVSOV45B6%2F20220322%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Date=20220322T013738Z&X-Amz-Expires=3600&X-Amz-SignedHeaders=host&X-Amz-Signature=4f7cb8c4c09d3a41c607caa75e6e515cf5e41adab0f8f2e228295d2dd6be763b",
                                    "externalUrl": null,
                                    "filename": "abfb0e7a-6d0a-4908-a3e9-cf87d1275349.pdf",
                                    "type": "BLOB"
                                },
                                "contributors": [
                                    {
                                        "profile": {
                                            "avatarAsset": {
                                                "__typename": "MultimediaAsset",
                                                "contentType": "image/jpeg",
                                                "filename": "3b563eb1-01c5-4f4d-8214-bb02eecbd4b5.jpg",
                                                "id": "3b563eb1-01c5-4f4d-8214-bb02eecbd4b5",
                                                "imageTransform": null
                                            },
                                            "name": "Nas",
                                            "slug": "Nas"
                                        }
                                    }
                                ],
                                "permalink": "nas-rare",
                                "rightsAsset": {
                                    "childAssets": [],
                                    "title": "Rare",
                                    "type": "SINGLE"
                                },
                                "title": "Rare"
                            },
                            "maxSupply": 10,
                            "type": "DIAMOND"
                        },
                        "tokenId": "2722258935367507707706996859454145691650",
                        "wallet": {
                            "address": "0x55f22ce5b80829c0bb35349c9fd7d99ab92506a1",
                            "chainId": "137",
                            "id": "8832d907-b57b-48f5-a41f-ca36aa9c6d1c",
                            "source": "METAMASK",
                            "user": null
                        }
                    }
                },
                {
                    "node": {
                        "__typename": "RightsToken",
                        "animationUrl": null,
                        "artworkAsset": {
                            "__typename": "MultimediaAsset",
                            "contentType": "image/png",
                            "filename": "ce525fe8-fdf4-43c6-84ab-9a3e315828a8.png",
                            "id": "ce525fe8-fdf4-43c6-84ab-9a3e315828a8",
                            "imageTransform": null
                        },
                        "id": "UmlnaHRzVG9rZW46MTViMTU1YzEtZjRhMi00MzY4LWFhMDItMjNhNGQ5YzNmZDY2",
                        "mintNumber": 2,
                        "royalId": "15b155c1-f4a2-4368-aa02-23a4d9c3fd66",
                        "tier": {
                            "chainContract": {
                                "chainId": "137"
                            },
                            "edition": {
                                "contractAsset": {
                                    "downloadUrl": "https://royalio-prod-storage.s3.us-east-2.amazonaws.com/ded95421-05dd-4f1b-b928-faf51fa446c7.pdf?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAXY35ED6HVSOV45B6%2F20220322%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Date=20220322T013738Z&X-Amz-Expires=3600&X-Amz-SignedHeaders=host&X-Amz-Signature=62f93ef46aab4459c065cefdd6ad67ee0eb153ada44dd1a12a77727460cdcd28",
                                    "externalUrl": null,
                                    "filename": "ded95421-05dd-4f1b-b928-faf51fa446c7.pdf",
                                    "type": "BLOB"
                                },
                                "contributors": [
                                    {
                                        "profile": {
                                            "avatarAsset": {
                                                "__typename": "MultimediaAsset",
                                                "contentType": "image/jpg",
                                                "filename": "aefd8869-318e-4226-8cf6-0c5a3c39aadf.jpeg",
                                                "id": "aefd8869-318e-4226-8cf6-0c5a3c39aadf",
                                                "imageTransform": null
                                            },
                                            "name": "Vérité",
                                            "slug": "Verite"
                                        }
                                    }
                                ],
                                "permalink": "verite-hes-not-you",
                                "rightsAsset": {
                                    "childAssets": [],
                                    "title": "He's Not You",
                                    "type": "SINGLE"
                                },
                                "title": "He's Not You"
                            },
                            "maxSupply": 50,
                            "type": "PLATINUM"
                        },
                        "tokenId": "8507059173023461586584365185794205286402",
                        "wallet": {
                            "address": "0xc20e57bf666942c7b7c60dd9111d76ef439a23f7",
                            "chainId": "137",
                            "id": "b3f75919-531a-402d-b365-1775def87c70",
                            "source": "METAMASK",
                            "user": {
                                "profiles": [
                                    {
                                        "avatarAsset": null,
                                        "slug": "rgg3xsb5ElJqAUGHkYOs"
                                    }
                                ],
                                "royalId": "798577d0-09d8-4950-a110-69fa95749205"
                            }
                        }
                    }
                },
                {
                    "node": {
                        "__typename": "RightsToken",
                        "animationUrl": null,
                        "artworkAsset": {
                            "__typename": "MultimediaAsset",
                            "contentType": "image/png",
                            "filename": "c8b14a79-126e-4876-ac18-6a2b99f8d60f.png",
                            "id": "c8b14a79-126e-4876-ac18-6a2b99f8d60f",
                            "imageTransform": null
                        },
                        "id": "UmlnaHRzVG9rZW46YmRlOWZlNTMtZjY1MC00NmNlLTk2MjEtOGU0ZmEwNTE1ZjYz",
                        "mintNumber": 2,
                        "royalId": "bde9fe53-f650-46ce-9621-8e4fa0515f63",
                        "tier": {
                            "chainContract": {
                                "chainId": "137"
                            },
                            "edition": {
                                "contractAsset": {
                                    "downloadUrl": "https://royalio-prod-storage.s3.us-east-2.amazonaws.com/1cdd01aa-f6db-445f-bf1a-d4fa2f78be3e.pdf?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAXY35ED6HVSOV45B6%2F20220322%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Date=20220322T013738Z&X-Amz-Expires=3600&X-Amz-SignedHeaders=host&X-Amz-Signature=f900eace01b01e9a88e68256068a57397bf0d94887768501184e926f3e44ab54",
                                    "externalUrl": null,
                                    "filename": "1cdd01aa-f6db-445f-bf1a-d4fa2f78be3e.pdf",
                                    "type": "BLOB"
                                },
                                "contributors": [
                                    {
                                        "profile": {
                                            "avatarAsset": {
                                                "__typename": "MultimediaAsset",
                                                "contentType": "image/jpeg",
                                                "filename": "3b563eb1-01c5-4f4d-8214-bb02eecbd4b5.jpg",
                                                "id": "3b563eb1-01c5-4f4d-8214-bb02eecbd4b5",
                                                "imageTransform": null
                                            },
                                            "name": "Nas",
                                            "slug": "Nas"
                                        }
                                    }
                                ],
                                "permalink": "nas-ultrablack",
                                "rightsAsset": {
                                    "childAssets": [],
                                    "title": "Ultra Black",
                                    "type": "SINGLE"
                                },
                                "title": "Ultra Black"
                            },
                            "maxSupply": 250,
                            "type": "PLATINUM"
                        },
                        "tokenId": "1020847100762815390390123822295304634370",
                        "wallet": {
                            "address": "0x2dd60ccd2dcb0652e600af5ca8682c579cc357c7",
                            "chainId": "137",
                            "id": "f56d50d1-c823-43a9-a60d-ab7b14b5ad4a",
                            "source": "METAMASK",
                            "user": null
                        }
                    }
                },
                {
                    "node": {
                        "__typename": "RightsToken",
                        "animationUrl": "https://api.royal.io/assets/9dae4840-ce27-4ab9-ab21-0eb2fbe990b2",
                        "artworkAsset": {
                            "__typename": "MultimediaAsset",
                            "contentType": "image/jpeg",
                            "filename": "b0585888-af9d-44c8-adab-c7f243ebd03d.jpg",
                            "id": "b0585888-af9d-44c8-adab-c7f243ebd03d",
                            "imageTransform": null
                        },
                        "id": "UmlnaHRzVG9rZW46ZmFmOWU5N2ItMDQxZi00Y2UzLTk0MTgtMDY2OWFmMDBkNzVm",
                        "mintNumber": 2,
                        "royalId": "faf9e97b-041f-4ce3-9418-0669af00d75f",
                        "tier": {
                            "chainContract": {
                                "chainId": "137"
                            },
                            "edition": {
                                "contractAsset": {
                                    "downloadUrl": "https://royalio-prod-storage.s3.us-east-2.amazonaws.com/ded95421-05dd-4f1b-b928-faf51fa446c7.pdf?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAXY35ED6HVSOV45B6%2F20220322%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Date=20220322T013738Z&X-Amz-Expires=3600&X-Amz-SignedHeaders=host&X-Amz-Signature=62f93ef46aab4459c065cefdd6ad67ee0eb153ada44dd1a12a77727460cdcd28",
                                    "externalUrl": null,
                                    "filename": "ded95421-05dd-4f1b-b928-faf51fa446c7.pdf",
                                    "type": "BLOB"
                                },
                                "contributors": [
                                    {
                                        "profile": {
                                            "avatarAsset": {
                                                "__typename": "MultimediaAsset",
                                                "contentType": "image/jpg",
                                                "filename": "aefd8869-318e-4226-8cf6-0c5a3c39aadf.jpeg",
                                                "id": "aefd8869-318e-4226-8cf6-0c5a3c39aadf",
                                                "imageTransform": null
                                            },
                                            "name": "Vérité",
                                            "slug": "Verite"
                                        }
                                    }
                                ],
                                "permalink": "verite-hes-not-you",
                                "rightsAsset": {
                                    "childAssets": [],
                                    "title": "He's Not You",
                                    "type": "SINGLE"
                                },
                                "title": "He's Not You"
                            },
                            "maxSupply": 5,
                            "type": "DIAMOND"
                        },
                        "tokenId": "8166776806102523123120990578362437074946",
                        "wallet": {
                            "address": "0xc1c0e9750fab87ac871ea40d005063f3750fe143",
                            "chainId": "137",
                            "id": "dd22a0ca-9185-428b-94db-20b893fa4277",
                            "source": "METAMASK",
                            "user": {
                                "profiles": [
                                    {
                                        "avatarAsset": {
                                            "__typename": "MultimediaAsset",
                                            "contentType": "image/gif",
                                            "filename": "6c92c7c6-9380-4e65-84a8-fc8a24262489.gif",
                                            "id": "6c92c7c6-9380-4e65-84a8-fc8a24262489",
                                            "imageTransform": null
                                        },
                                        "slug": "DuckingWhimsical"
                                    }
                                ],
                                "royalId": "bc552756-a335-4644-a345-939197a181ac"
                            }
                        }
                    }
                },
                {
                    "node": {
                        "__typename": "RightsToken",
                        "animationUrl": null,
                        "artworkAsset": {
                            "__typename": "MultimediaAsset",
                            "contentType": "image/jpg",
                            "filename": "498b960a-b122-43a8-ac09-e6c956277e64.jpg",
                            "id": "498b960a-b122-43a8-ac09-e6c956277e64",
                            "imageTransform": null
                        },
                        "id": "UmlnaHRzVG9rZW46YTQ2NDViMzYtM2RjMS00Nzk3LTg4NmEtMDBhYWE4ZTI2ZDQ2",
                        "mintNumber": 2,
                        "royalId": "a4645b36-3dc1-4797-886a-00aaa8e26d46",
                        "tier": {
                            "chainContract": {
                                "chainId": "137"
                            },
                            "edition": {
                                "contractAsset": {
                                    "downloadUrl": "https://royalio-prod-storage.s3.us-east-2.amazonaws.com/5c87f98f-4b6f-43f9-bcc0-2f6a2bf73e89.pdf?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAXY35ED6HVSOV45B6%2F20220322%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Date=20220322T013738Z&X-Amz-Expires=3600&X-Amz-SignedHeaders=host&X-Amz-Signature=fb1841fdd807cd233a3f42d265ef82beacf1b8194af7bfe5699dfa35c0e4f6b1",
                                    "externalUrl": null,
                                    "filename": "5c87f98f-4b6f-43f9-bcc0-2f6a2bf73e89.pdf",
                                    "type": "BLOB"
                                },
                                "contributors": [
                                    {
                                        "profile": {
                                            "avatarAsset": {
                                                "__typename": "MultimediaAsset",
                                                "contentType": "image/jpg",
                                                "filename": "4730bb97-77cd-445c-b702-d127c9a85b55.jpg",
                                                "id": "4730bb97-77cd-445c-b702-d127c9a85b55",
                                                "imageTransform": null
                                            },
                                            "name": "Ollie",
                                            "slug": "Ollie"
                                        }
                                    }
                                ],
                                "permalink": "ollie-underrated",
                                "rightsAsset": {
                                    "childAssets": [
                                        {
                                            "royalId": "c8685afa-bd9b-463a-8fe6-9c181319a342"
                                        },
                                        {
                                            "royalId": "1cc88c34-57a4-4b50-96bb-fc091a4de418"
                                        },
                                        {
                                            "royalId": "6c7096bf-c384-4469-800b-f4a13514881b"
                                        },
                                        {
                                            "royalId": "97011f2b-6173-481b-aefe-78379c31df7a"
                                        }
                                    ],
                                    "title": "Underrated",
                                    "type": "EP"
                                },
                                "title": "Underrated"
                            },
                            "maxSupply": 600,
                            "type": "GOLD"
                        },
                        "tokenId": "9868188640707215440437863615521278132226",
                        "wallet": {
                            "address": "0x1d8a407313642f7f07315ff190573aea556fee64",
                            "chainId": "137",
                            "id": "262d8c2f-6582-4210-ba05-4df4d0b17450",
                            "source": "METAMASK",
                            "user": {
                                "profiles": [
                                    {
                                        "avatarAsset": null,
                                        "slug": "mri5S-29PFrHwxPUN7Qi"
                                    }
                                ],
                                "royalId": "d1aff14d-1dcb-426e-9bbb-55487e0c4071"
                            }
                        }
                    }
                },
                {
                    "node": {
                        "__typename": "RightsToken",
                        "animationUrl": null,
                        "artworkAsset": {
                            "__typename": "MultimediaAsset",
                            "contentType": "image/png",
                            "filename": "02a5e9c3-b74f-4d4a-971b-d1b39ae41a85.png",
                            "id": "02a5e9c3-b74f-4d4a-971b-d1b39ae41a85",
                            "imageTransform": null
                        },
                        "id": "UmlnaHRzVG9rZW46NjZlNDBiYzUtNTRmNy00NTU0LWI3ZTgtODMxYzU4ZGI2ZDdm",
                        "mintNumber": 2,
                        "royalId": "66e40bc5-54f7-4554-b7e8-831c58db6d7f",
                        "tier": {
                            "chainContract": {
                                "chainId": "137"
                            },
                            "edition": {
                                "contractAsset": {
                                    "downloadUrl": "https://royalio-prod-storage.s3.us-east-2.amazonaws.com/abfb0e7a-6d0a-4908-a3e9-cf87d1275349.pdf?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAXY35ED6HVSOV45B6%2F20220322%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Date=20220322T013738Z&X-Amz-Expires=3600&X-Amz-SignedHeaders=host&X-Amz-Signature=4f7cb8c4c09d3a41c607caa75e6e515cf5e41adab0f8f2e228295d2dd6be763b",
                                    "externalUrl": null,
                                    "filename": "abfb0e7a-6d0a-4908-a3e9-cf87d1275349.pdf",
                                    "type": "BLOB"
                                },
                                "contributors": [
                                    {
                                        "profile": {
                                            "avatarAsset": {
                                                "__typename": "MultimediaAsset",
                                                "contentType": "image/jpeg",
                                                "filename": "3b563eb1-01c5-4f4d-8214-bb02eecbd4b5.jpg",
                                                "id": "3b563eb1-01c5-4f4d-8214-bb02eecbd4b5",
                                                "imageTransform": null
                                            },
                                            "name": "Nas",
                                            "slug": "Nas"
                                        }
                                    }
                                ],
                                "permalink": "nas-rare",
                                "rightsAsset": {
                                    "childAssets": [],
                                    "title": "Rare",
                                    "type": "SINGLE"
                                },
                                "title": "Rare"
                            },
                            "maxSupply": 700,
                            "type": "GOLD"
                        },
                        "tokenId": "2381976568446569244243622252022377480194",
                        "wallet": {
                            "address": "0x76047b39c5bbaaaf7c60ece95febbbc862867c2f",
                            "chainId": "137",
                            "id": "8590fa8a-546e-4b9a-992b-175563985e98",
                            "source": "METAMASK",
                            "user": {
                                "profiles": [
                                    {
                                        "avatarAsset": {
                                            "__typename": "MultimediaAsset",
                                            "contentType": "image/jpeg",
                                            "filename": "828c4d14-23ae-4677-9c43-52ce78e055dd.jpg",
                                            "id": "828c4d14-23ae-4677-9c43-52ce78e055dd",
                                            "imageTransform": null
                                        },
                                        "slug": "BE7Qd7wYWs_ziYai16_F"
                                    }
                                ],
                                "royalId": "17fa7919-8a90-40b8-8a75-b330f5b3a3d4"
                            }
                        }
                    }
                },
                {
                    "node": {
                        "__typename": "RightsToken",
                        "animationUrl": null,
                        "artworkAsset": {
                            "__typename": "MultimediaAsset",
                            "contentType": "image/png",
                            "filename": "c0d5e329-13e7-4b7a-9717-f58ed8d126b0.png",
                            "id": "c0d5e329-13e7-4b7a-9717-f58ed8d126b0",
                            "imageTransform": null
                        },
                        "id": "UmlnaHRzVG9rZW46YzhjYmEzMDEtOWVhYy00ZDljLTkwMTMtYjdhNzFiYmRkMjlm",
                        "mintNumber": 2,
                        "royalId": "c8cba301-9eac-4d9c-9013-b7a71bbdd29f",
                        "tier": {
                            "chainContract": {
                                "chainId": "1"
                            },
                            "edition": {
                                "contractAsset": {
                                    "downloadUrl": "https://royalio-prod-storage.s3.us-east-2.amazonaws.com/c8e8c5a5-b91d-4508-9bee-3fd67ab28b5d.pdf?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAXY35ED6HVSOV45B6%2F20220322%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Date=20220322T013738Z&X-Amz-Expires=3600&X-Amz-SignedHeaders=host&X-Amz-Signature=bdac10e03b5e75462f4e083e84c41a69d8bfca070100f64cce3ecb849e0dec4a",
                                    "externalUrl": null,
                                    "filename": "c8e8c5a5-b91d-4508-9bee-3fd67ab28b5d.pdf",
                                    "type": "BLOB"
                                },
                                "contributors": [
                                    {
                                        "profile": {
                                            "avatarAsset": {
                                                "__typename": "MultimediaAsset",
                                                "contentType": "image/jpeg",
                                                "filename": "e9b746d6-7aa7-412b-9b18-06e9db4c33d4.jpg",
                                                "id": "e9b746d6-7aa7-412b-9b18-06e9db4c33d4",
                                                "imageTransform": null
                                            },
                                            "name": "3LAU",
                                            "slug": "3LAU"
                                        }
                                    }
                                ],
                                "permalink": "worst-case",
                                "rightsAsset": {
                                    "childAssets": [],
                                    "title": "Worst Case",
                                    "type": "SINGLE"
                                },
                                "title": "Worst Case"
                            },
                            "maxSupply": 333,
                            "type": "PLATINUM"
                        },
                        "tokenId": "340282366920938463463374607431768211458",
                        "wallet": {
                            "address": "0x15c50a2f5e8fe3aca6640bf947fe6dbb772e9acc",
                            "chainId": "1",
                            "id": "c97c6716-fb82-4ca9-8d2b-a050bba1b5cc",
                            "source": "METAMASK",
                            "user": {
                                "profiles": [
                                    {
                                        "avatarAsset": {
                                            "__typename": "MultimediaAsset",
                                            "contentType": "image/png",
                                            "filename": "4889588d-a149-4527-9c43-549a79707521.png",
                                            "id": "4889588d-a149-4527-9c43-549a79707521",
                                            "imageTransform": null
                                        },
                                        "slug": "pri"
                                    }
                                ],
                                "royalId": "37de5247-4737-4b36-beed-8338ee65a209"
                            }
                        }
                    }
                },
                {
                    "node": {
                        "__typename": "RightsToken",
                        "animationUrl": null,
                        "artworkAsset": {
                            "__typename": "MultimediaAsset",
                            "contentType": "image/gif",
                            "filename": "4a5414e4-dc62-4b1b-a5c5-a168fc50d548.gif",
                            "id": "4a5414e4-dc62-4b1b-a5c5-a168fc50d548",
                            "imageTransform": null
                        },
                        "id": "UmlnaHRzVG9rZW46MzA4OWRiNjAtYjlmZi00MTg2LTlmMWYtMjQzZDJmNTVjZjlh",
                        "mintNumber": 2,
                        "royalId": "3089db60-b9ff-4186-9f1f-243d2f55cf9a",
                        "tier": {
                            "chainContract": {
                                "chainId": "137"
                            },
                            "edition": {
                                "contractAsset": {
                                    "downloadUrl": "https://royalio-prod-storage.s3.us-east-2.amazonaws.com/1cdd01aa-f6db-445f-bf1a-d4fa2f78be3e.pdf?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAXY35ED6HVSOV45B6%2F20220322%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Date=20220322T013738Z&X-Amz-Expires=3600&X-Amz-SignedHeaders=host&X-Amz-Signature=f900eace01b01e9a88e68256068a57397bf0d94887768501184e926f3e44ab54",
                                    "externalUrl": null,
                                    "filename": "1cdd01aa-f6db-445f-bf1a-d4fa2f78be3e.pdf",
                                    "type": "BLOB"
                                },
                                "contributors": [
                                    {
                                        "profile": {
                                            "avatarAsset": {
                                                "__typename": "MultimediaAsset",
                                                "contentType": "image/jpeg",
                                                "filename": "3b563eb1-01c5-4f4d-8214-bb02eecbd4b5.jpg",
                                                "id": "3b563eb1-01c5-4f4d-8214-bb02eecbd4b5",
                                                "imageTransform": null
                                            },
                                            "name": "Nas",
                                            "slug": "Nas"
                                        }
                                    }
                                ],
                                "permalink": "nas-ultrablack",
                                "rightsAsset": {
                                    "childAssets": [],
                                    "title": "Ultra Black",
                                    "type": "SINGLE"
                                },
                                "title": "Ultra Black"
                            },
                            "maxSupply": 10,
                            "type": "DIAMOND"
                        },
                        "tokenId": "1701411834604692317316873037158841057282",
                        "wallet": {
                            "address": "0xbf02ba2db8316e7b0a9ce4ec3c632c1e9bc1aafc",
                            "chainId": "137",
                            "id": "93839335-0f4a-47ec-97a1-ce1df3edb4d6",
                            "source": "METAMASK",
                            "user": null
                        }
                    }
                },
                {
                    "node": {
                        "__typename": "RightsToken",
                        "animationUrl": null,
                        "artworkAsset": {
                            "__typename": "MultimediaAsset",
                            "contentType": "image/png",
                            "filename": "0333e9df-c305-4caf-8494-b74686df7382.png",
                            "id": "0333e9df-c305-4caf-8494-b74686df7382",
                            "imageTransform": null
                        },
                        "id": "UmlnaHRzVG9rZW46N2RjNzVhYzItZDhiNS00ODI5LWJjYTctNmIwYzlkZjczZTE3",
                        "mintNumber": 3,
                        "royalId": "7dc75ac2-d8b5-4829-bca7-6b0c9df73e17",
                        "tier": {
                            "chainContract": {
                                "chainId": "137"
                            },
                            "edition": {
                                "contractAsset": {
                                    "downloadUrl": "https://royalio-prod-storage.s3.us-east-2.amazonaws.com/abfb0e7a-6d0a-4908-a3e9-cf87d1275349.pdf?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAXY35ED6HVSOV45B6%2F20220322%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Date=20220322T013738Z&X-Amz-Expires=3600&X-Amz-SignedHeaders=host&X-Amz-Signature=4f7cb8c4c09d3a41c607caa75e6e515cf5e41adab0f8f2e228295d2dd6be763b",
                                    "externalUrl": null,
                                    "filename": "abfb0e7a-6d0a-4908-a3e9-cf87d1275349.pdf",
                                    "type": "BLOB"
                                },
                                "contributors": [
                                    {
                                        "profile": {
                                            "avatarAsset": {
                                                "__typename": "MultimediaAsset",
                                                "contentType": "image/jpeg",
                                                "filename": "3b563eb1-01c5-4f4d-8214-bb02eecbd4b5.jpg",
                                                "id": "3b563eb1-01c5-4f4d-8214-bb02eecbd4b5",
                                                "imageTransform": null
                                            },
                                            "name": "Nas",
                                            "slug": "Nas"
                                        }
                                    }
                                ],
                                "permalink": "nas-rare",
                                "rightsAsset": {
                                    "childAssets": [],
                                    "title": "Rare",
                                    "type": "SINGLE"
                                },
                                "title": "Rare"
                            },
                            "maxSupply": 400,
                            "type": "PLATINUM"
                        },
                        "tokenId": "2041694201525630780780247644590609268739",
                        "wallet": {
                            "address": "0xd474adfa0bc4b5afeaab88a7a7f53475699e859e",
                            "chainId": "137",
                            "id": "bb44c5e1-089b-490d-9cba-4b2c7114a01d",
                            "source": "METAMASK",
                            "user": {
                                "profiles": [
                                    {
                                        "avatarAsset": {
                                            "__typename": "MultimediaAsset",
                                            "contentType": "image/jpeg",
                                            "filename": "a37e4b4d-d0dd-42dd-998c-9d5310e0d85f.jpg",
                                            "id": "a37e4b4d-d0dd-42dd-998c-9d5310e0d85f",
                                            "imageTransform": null
                                        },
                                        "slug": "Quillson"
                                    }
                                ],
                                "royalId": "bbdd8a66-d763-470c-b285-a36826cc9d7c"
                            }
                        }
                    }
                },
                {
                    "node": {
                        "__typename": "RightsToken",
                        "animationUrl": null,
                        "artworkAsset": {
                            "__typename": "MultimediaAsset",
                            "contentType": "image/jpg",
                            "filename": "5f384f47-8fba-4f89-a745-8f9150e7c8b2.jpg",
                            "id": "5f384f47-8fba-4f89-a745-8f9150e7c8b2",
                            "imageTransform": null
                        },
                        "id": "UmlnaHRzVG9rZW46NTU0MzY4YjAtYWViOS00MjMyLWIzMmItNDMyZTdkYmNiZmI5",
                        "mintNumber": 3,
                        "royalId": "554368b0-aeb9-4232-b32b-432e7dbcbfb9",
                        "tier": {
                            "chainContract": {
                                "chainId": "137"
                            },
                            "edition": {
                                "contractAsset": {
                                    "downloadUrl": "https://royalio-prod-storage.s3.us-east-2.amazonaws.com/5c87f98f-4b6f-43f9-bcc0-2f6a2bf73e89.pdf?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAXY35ED6HVSOV45B6%2F20220322%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Date=20220322T013738Z&X-Amz-Expires=3600&X-Amz-SignedHeaders=host&X-Amz-Signature=fb1841fdd807cd233a3f42d265ef82beacf1b8194af7bfe5699dfa35c0e4f6b1",
                                    "externalUrl": null,
                                    "filename": "5c87f98f-4b6f-43f9-bcc0-2f6a2bf73e89.pdf",
                                    "type": "BLOB"
                                },
                                "contributors": [
                                    {
                                        "profile": {
                                            "avatarAsset": {
                                                "__typename": "MultimediaAsset",
                                                "contentType": "image/jpg",
                                                "filename": "4730bb97-77cd-445c-b702-d127c9a85b55.jpg",
                                                "id": "4730bb97-77cd-445c-b702-d127c9a85b55",
                                                "imageTransform": null
                                            },
                                            "name": "Ollie",
                                            "slug": "Ollie"
                                        }
                                    }
                                ],
                                "permalink": "ollie-underrated",
                                "rightsAsset": {
                                    "childAssets": [
                                        {
                                            "royalId": "c8685afa-bd9b-463a-8fe6-9c181319a342"
                                        },
                                        {
                                            "royalId": "1cc88c34-57a4-4b50-96bb-fc091a4de418"
                                        },
                                        {
                                            "royalId": "6c7096bf-c384-4469-800b-f4a13514881b"
                                        },
                                        {
                                            "royalId": "97011f2b-6173-481b-aefe-78379c31df7a"
                                        }
                                    ],
                                    "title": "Underrated",
                                    "type": "EP"
                                },
                                "title": "Underrated"
                            },
                            "maxSupply": 50,
                            "type": "PLATINUM"
                        },
                        "tokenId": "9527906273786276976974489008089509920771",
                        "wallet": {
                            "address": "0x1a79f73b8209aa881ea2f6ac1ed4a15e822b1677",
                            "chainId": "137",
                            "id": "c755ade0-67d5-47da-b34d-3cd30fb8f56f",
                            "source": "METAMASK",
                            "user": {
                                "profiles": [
                                    {
                                        "avatarAsset": null,
                                        "slug": "suzamaki"
                                    }
                                ],
                                "royalId": "a5a56a8d-f18f-4a93-bbe8-cd00b4b4a165"
                            }
                        }
                    }
                },
                {
                    "node": {
                        "__typename": "RightsToken",
                        "animationUrl": "https://api.royal.io/assets/dd879d1f-1c43-4a12-b0f0-8c1cf72c453f",
                        "artworkAsset": {
                            "__typename": "MultimediaAsset",
                            "contentType": "image/png",
                            "filename": "7d64eca4-affe-4ca4-8200-639c1851bf5b.png",
                            "id": "7d64eca4-affe-4ca4-8200-639c1851bf5b",
                            "imageTransform": null
                        },
                        "id": "UmlnaHRzVG9rZW46ZDA4Y2FjN2EtMTUzMy00YmI3LWJmNTctMjk4MTFmNmQyNzMz",
                        "mintNumber": 3,
                        "royalId": "d08cac7a-1533-4bb7-bf57-29811f6d2733",
                        "tier": {
                            "chainContract": {
                                "chainId": "137"
                            },
                            "edition": {
                                "contractAsset": {
                                    "downloadUrl": "https://royalio-prod-storage.s3.us-east-2.amazonaws.com/5c87f98f-4b6f-43f9-bcc0-2f6a2bf73e89.pdf?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAXY35ED6HVSOV45B6%2F20220322%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Date=20220322T013738Z&X-Amz-Expires=3600&X-Amz-SignedHeaders=host&X-Amz-Signature=fb1841fdd807cd233a3f42d265ef82beacf1b8194af7bfe5699dfa35c0e4f6b1",
                                    "externalUrl": null,
                                    "filename": "5c87f98f-4b6f-43f9-bcc0-2f6a2bf73e89.pdf",
                                    "type": "BLOB"
                                },
                                "contributors": [
                                    {
                                        "profile": {
                                            "avatarAsset": {
                                                "__typename": "MultimediaAsset",
                                                "contentType": "image/jpg",
                                                "filename": "4730bb97-77cd-445c-b702-d127c9a85b55.jpg",
                                                "id": "4730bb97-77cd-445c-b702-d127c9a85b55",
                                                "imageTransform": null
                                            },
                                            "name": "Ollie",
                                            "slug": "Ollie"
                                        }
                                    }
                                ],
                                "permalink": "ollie-underrated",
                                "rightsAsset": {
                                    "childAssets": [
                                        {
                                            "royalId": "c8685afa-bd9b-463a-8fe6-9c181319a342"
                                        },
                                        {
                                            "royalId": "1cc88c34-57a4-4b50-96bb-fc091a4de418"
                                        },
                                        {
                                            "royalId": "6c7096bf-c384-4469-800b-f4a13514881b"
                                        },
                                        {
                                            "royalId": "97011f2b-6173-481b-aefe-78379c31df7a"
                                        }
                                    ],
                                    "title": "Underrated",
                                    "type": "EP"
                                },
                                "title": "Underrated"
                            },
                            "maxSupply": 10,
                            "type": "DIAMOND"
                        },
                        "tokenId": "9187623906865338513511114400657741709315",
                        "wallet": {
                            "address": "0x836a2db4a9195bc2117832955f98ec9c2d5e7211",
                            "chainId": "137",
                            "id": "fae8c9c2-397d-4649-b8df-98c611c7f452",
                            "source": "METAMASK",
                            "user": {
                                "profiles": [
                                    {
                                        "avatarAsset": {
                                            "__typename": "MultimediaAsset",
                                            "contentType": "image/jpeg",
                                            "filename": "072528b5-56a8-4cae-9c1d-dea6a70129c7.jpg",
                                            "id": "072528b5-56a8-4cae-9c1d-dea6a70129c7",
                                            "imageTransform": null
                                        },
                                        "slug": "benkopec"
                                    }
                                ],
                                "royalId": "bb6804fa-d9ea-4f56-940d-bb7e7c0ba3aa"
                            }
                        }
                    }
                },
                {
                    "node": {
                        "__typename": "RightsToken",
                        "animationUrl": null,
                        "artworkAsset": {
                            "__typename": "MultimediaAsset",
                            "contentType": "image/png",
                            "filename": "327df5f8-030f-466f-8012-c7eb50d143ba.png",
                            "id": "327df5f8-030f-466f-8012-c7eb50d143ba",
                            "imageTransform": null
                        },
                        "id": "UmlnaHRzVG9rZW46OGI5ZThhM2EtYjk2Ny00MzgzLWIzMDUtNWM3YzkwNjQzZTE2",
                        "mintNumber": 3,
                        "royalId": "8b9e8a3a-b967-4383-b305-5c7c90643e16",
                        "tier": {
                            "chainContract": {
                                "chainId": "137"
                            },
                            "edition": {
                                "contractAsset": {
                                    "downloadUrl": "https://royalio-prod-storage.s3.us-east-2.amazonaws.com/1cdd01aa-f6db-445f-bf1a-d4fa2f78be3e.pdf?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAXY35ED6HVSOV45B6%2F20220322%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Date=20220322T013738Z&X-Amz-Expires=3600&X-Amz-SignedHeaders=host&X-Amz-Signature=f900eace01b01e9a88e68256068a57397bf0d94887768501184e926f3e44ab54",
                                    "externalUrl": null,
                                    "filename": "1cdd01aa-f6db-445f-bf1a-d4fa2f78be3e.pdf",
                                    "type": "BLOB"
                                },
                                "contributors": [
                                    {
                                        "profile": {
                                            "avatarAsset": {
                                                "__typename": "MultimediaAsset",
                                                "contentType": "image/jpeg",
                                                "filename": "3b563eb1-01c5-4f4d-8214-bb02eecbd4b5.jpg",
                                                "id": "3b563eb1-01c5-4f4d-8214-bb02eecbd4b5",
                                                "imageTransform": null
                                            },
                                            "name": "Nas",
                                            "slug": "Nas"
                                        }
                                    }
                                ],
                                "permalink": "nas-ultrablack",
                                "rightsAsset": {
                                    "childAssets": [],
                                    "title": "Ultra Black",
                                    "type": "SINGLE"
                                },
                                "title": "Ultra Black"
                            },
                            "maxSupply": 500,
                            "type": "GOLD"
                        },
                        "tokenId": "1361129467683753853853498429727072845827",
                        "wallet": {
                            "address": "0xf64579f9f76e060e6e3fc88441d5147f722e9405",
                            "chainId": "137",
                            "id": "535cda71-2112-4dc9-9537-184dcc8b3d8e",
                            "source": "METAMASK",
                            "user": null
                        }
                    }
                },
                {
                    "node": {
                        "__typename": "RightsToken",
                        "animationUrl": null,
                        "artworkAsset": {
                            "__typename": "MultimediaAsset",
                            "contentType": "image/png",
                            "filename": "4d963d3f-4a16-4e5e-8577-5ce8219ff7f5.png",
                            "id": "4d963d3f-4a16-4e5e-8577-5ce8219ff7f5",
                            "imageTransform": null
                        },
                        "id": "UmlnaHRzVG9rZW46Yzk0MjhlMzUtZTBhOC00MTYyLWJkMGItODUyYjQ2MjExNzNj",
                        "mintNumber": 3,
                        "royalId": "c9428e35-e0a8-4162-bd0b-852b4621173c",
                        "tier": {
                            "chainContract": {
                                "chainId": "137"
                            },
                            "edition": {
                                "contractAsset": {
                                    "downloadUrl": "https://royalio-prod-storage.s3.us-east-2.amazonaws.com/ded95421-05dd-4f1b-b928-faf51fa446c7.pdf?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAXY35ED6HVSOV45B6%2F20220322%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Date=20220322T013738Z&X-Amz-Expires=3600&X-Amz-SignedHeaders=host&X-Amz-Signature=62f93ef46aab4459c065cefdd6ad67ee0eb153ada44dd1a12a77727460cdcd28",
                                    "externalUrl": null,
                                    "filename": "ded95421-05dd-4f1b-b928-faf51fa446c7.pdf",
                                    "type": "BLOB"
                                },
                                "contributors": [
                                    {
                                        "profile": {
                                            "avatarAsset": {
                                                "__typename": "MultimediaAsset",
                                                "contentType": "image/jpg",
                                                "filename": "aefd8869-318e-4226-8cf6-0c5a3c39aadf.jpeg",
                                                "id": "aefd8869-318e-4226-8cf6-0c5a3c39aadf",
                                                "imageTransform": null
                                            },
                                            "name": "Vérité",
                                            "slug": "Verite"
                                        }
                                    }
                                ],
                                "permalink": "verite-hes-not-you",
                                "rightsAsset": {
                                    "childAssets": [],
                                    "title": "He's Not You",
                                    "type": "SINGLE"
                                },
                                "title": "He's Not You"
                            },
                            "maxSupply": 450,
                            "type": "GOLD"
                        },
                        "tokenId": "8847341539944400050047739793225973497859",
                        "wallet": {
                            "address": "0x66845500e8781642f45e5bfb283c0809b16bca54",
                            "chainId": "137",
                            "id": "46636d3b-a756-4df2-8769-28d54524e1b3",
                            "source": "METAMASK",
                            "user": {
                                "profiles": [
                                    {
                                        "avatarAsset": {
                                            "__typename": "MultimediaAsset",
                                            "contentType": "image/jpeg",
                                            "filename": "997bac24-975e-4607-92d4-6cc706284e4b.jpg",
                                            "id": "997bac24-975e-4607-92d4-6cc706284e4b",
                                            "imageTransform": null
                                        },
                                        "slug": "SamanthaStary"
                                    }
                                ],
                                "royalId": "5c110b1c-2f99-47d9-9c1e-ccd491137c51"
                            }
                        }
                    }
                },
                {
                    "node": {
                        "__typename": "RightsToken",
                        "animationUrl": null,
                        "artworkAsset": {
                            "__typename": "MultimediaAsset",
                            "contentType": "image/gif",
                            "filename": "5743ff90-2f05-48f1-ab8e-5c5c59f98ae3.gif",
                            "id": "5743ff90-2f05-48f1-ab8e-5c5c59f98ae3",
                            "imageTransform": null
                        },
                        "id": "UmlnaHRzVG9rZW46YmUxYjNiNTgtOGRjZS00NDM2LTk2MGEtNzhmZDVkMTA5ZTMz",
                        "mintNumber": 3,
                        "royalId": "be1b3b58-8dce-4436-960a-78fd5d109e33",
                        "tier": {
                            "chainContract": {
                                "chainId": "137"
                            },
                            "edition": {
                                "contractAsset": {
                                    "downloadUrl": "https://royalio-prod-storage.s3.us-east-2.amazonaws.com/abfb0e7a-6d0a-4908-a3e9-cf87d1275349.pdf?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAXY35ED6HVSOV45B6%2F20220322%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Date=20220322T013738Z&X-Amz-Expires=3600&X-Amz-SignedHeaders=host&X-Amz-Signature=4f7cb8c4c09d3a41c607caa75e6e515cf5e41adab0f8f2e228295d2dd6be763b",
                                    "externalUrl": null,
                                    "filename": "abfb0e7a-6d0a-4908-a3e9-cf87d1275349.pdf",
                                    "type": "BLOB"
                                },
                                "contributors": [
                                    {
                                        "profile": {
                                            "avatarAsset": {
                                                "__typename": "MultimediaAsset",
                                                "contentType": "image/jpeg",
                                                "filename": "3b563eb1-01c5-4f4d-8214-bb02eecbd4b5.jpg",
                                                "id": "3b563eb1-01c5-4f4d-8214-bb02eecbd4b5",
                                                "imageTransform": null
                                            },
                                            "name": "Nas",
                                            "slug": "Nas"
                                        }
                                    }
                                ],
                                "permalink": "nas-rare",
                                "rightsAsset": {
                                    "childAssets": [],
                                    "title": "Rare",
                                    "type": "SINGLE"
                                },
                                "title": "Rare"
                            },
                            "maxSupply": 10,
                            "type": "DIAMOND"
                        },
                        "tokenId": "2722258935367507707706996859454145691651",
                        "wallet": {
                            "address": "0x44c00980099e66fd5f2120fcb54fe98884bd3e06",
                            "chainId": "137",
                            "id": "3d8ffee0-2ec6-4906-9030-99784b630d10",
                            "source": "METAMASK",
                            "user": {
                                "profiles": [
                                    {
                                        "avatarAsset": {
                                            "__typename": "MultimediaAsset",
                                            "contentType": "image/jpeg",
                                            "filename": "f6ece52c-f66f-4d92-88d2-9e68c17a60cb.jpg",
                                            "id": "f6ece52c-f66f-4d92-88d2-9e68c17a60cb",
                                            "imageTransform": null
                                        },
                                        "slug": "_Y2Ft2eQMJoca-mBmhhK"
                                    }
                                ],
                                "royalId": "6ef42f9c-e984-450a-a6d0-3fe0c5c907f5"
                            }
                        }
                    }
                },
                {
                    "node": {
                        "__typename": "RightsToken",
                        "animationUrl": null,
                        "artworkAsset": {
                            "__typename": "MultimediaAsset",
                            "contentType": "image/png",
                            "filename": "80a5b3b3-0e8c-4791-bde0-37d49fae7112.png",
                            "id": "80a5b3b3-0e8c-4791-bde0-37d49fae7112",
                            "imageTransform": null
                        },
                        "id": "UmlnaHRzVG9rZW46MWE2MzNmNWEtZjNiNy00MjlkLWEyNGUtZmYxMGM3OWQwYzY0",
                        "mintNumber": 3,
                        "royalId": "1a633f5a-f3b7-429d-a24e-ff10c79d0c64",
                        "tier": {
                            "chainContract": {
                                "chainId": "137"
                            },
                            "edition": {
                                "contractAsset": {
                                    "downloadUrl": "https://royalio-prod-storage.s3.us-east-2.amazonaws.com/ded95421-05dd-4f1b-b928-faf51fa446c7.pdf?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAXY35ED6HVSOV45B6%2F20220322%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Date=20220322T013738Z&X-Amz-Expires=3600&X-Amz-SignedHeaders=host&X-Amz-Signature=62f93ef46aab4459c065cefdd6ad67ee0eb153ada44dd1a12a77727460cdcd28",
                                    "externalUrl": null,
                                    "filename": "ded95421-05dd-4f1b-b928-faf51fa446c7.pdf",
                                    "type": "BLOB"
                                },
                                "contributors": [
                                    {
                                        "profile": {
                                            "avatarAsset": {
                                                "__typename": "MultimediaAsset",
                                                "contentType": "image/jpg",
                                                "filename": "aefd8869-318e-4226-8cf6-0c5a3c39aadf.jpeg",
                                                "id": "aefd8869-318e-4226-8cf6-0c5a3c39aadf",
                                                "imageTransform": null
                                            },
                                            "name": "Vérité",
                                            "slug": "Verite"
                                        }
                                    }
                                ],
                                "permalink": "verite-hes-not-you",
                                "rightsAsset": {
                                    "childAssets": [],
                                    "title": "He's Not You",
                                    "type": "SINGLE"
                                },
                                "title": "He's Not You"
                            },
                            "maxSupply": 50,
                            "type": "PLATINUM"
                        },
                        "tokenId": "8507059173023461586584365185794205286403",
                        "wallet": {
                            "address": "0x389b2ae07d0b341ae8921efc3cc1e9e9ac9ce0c3",
                            "chainId": "137",
                            "id": "dd2effec-5e14-423a-b616-ffc2e9480a75",
                            "source": "METAMASK",
                            "user": {
                                "profiles": [
                                    {
                                        "avatarAsset": null,
                                        "slug": "WLWLt1dr8gET7MOb6jge"
                                    }
                                ],
                                "royalId": "c3ea0e78-acde-4c03-8979-051dda6fb8f2"
                            }
                        }
                    }
                },
                {
                    "node": {
                        "__typename": "RightsToken",
                        "animationUrl": null,
                        "artworkAsset": {
                            "__typename": "MultimediaAsset",
                            "contentType": "image/png",
                            "filename": "cf2ffa1b-a015-4aed-a49a-a2e58459c696.png",
                            "id": "cf2ffa1b-a015-4aed-a49a-a2e58459c696",
                            "imageTransform": null
                        },
                        "id": "UmlnaHRzVG9rZW46ODYzZTk0MzYtYzkxZS00M2Y2LWE1ZDEtMTJlMDE1YmQxOTAz",
                        "mintNumber": 3,
                        "royalId": "863e9436-c91e-43f6-a5d1-12e015bd1903",
                        "tier": {
                            "chainContract": {
                                "chainId": "137"
                            },
                            "edition": {
                                "contractAsset": {
                                    "downloadUrl": "https://royalio-prod-storage.s3.us-east-2.amazonaws.com/1cdd01aa-f6db-445f-bf1a-d4fa2f78be3e.pdf?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAXY35ED6HVSOV45B6%2F20220322%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Date=20220322T013738Z&X-Amz-Expires=3600&X-Amz-SignedHeaders=host&X-Amz-Signature=f900eace01b01e9a88e68256068a57397bf0d94887768501184e926f3e44ab54",
                                    "externalUrl": null,
                                    "filename": "1cdd01aa-f6db-445f-bf1a-d4fa2f78be3e.pdf",
                                    "type": "BLOB"
                                },
                                "contributors": [
                                    {
                                        "profile": {
                                            "avatarAsset": {
                                                "__typename": "MultimediaAsset",
                                                "contentType": "image/jpeg",
                                                "filename": "3b563eb1-01c5-4f4d-8214-bb02eecbd4b5.jpg",
                                                "id": "3b563eb1-01c5-4f4d-8214-bb02eecbd4b5",
                                                "imageTransform": null
                                            },
                                            "name": "Nas",
                                            "slug": "Nas"
                                        }
                                    }
                                ],
                                "permalink": "nas-ultrablack",
                                "rightsAsset": {
                                    "childAssets": [],
                                    "title": "Ultra Black",
                                    "type": "SINGLE"
                                },
                                "title": "Ultra Black"
                            },
                            "maxSupply": 250,
                            "type": "PLATINUM"
                        },
                        "tokenId": "1020847100762815390390123822295304634371",
                        "wallet": {
                            "address": "0x52d55dbb88fda1009a6c141206ed587d8d3442fb",
                            "chainId": "137",
                            "id": "0f350902-9910-474e-a9fc-d2c5554b04da",
                            "source": "METAMASK",
                            "user": null
                        }
                    }
                },
                {
                    "node": {
                        "__typename": "RightsToken",
                        "animationUrl": "https://api.royal.io/assets/86c402cf-dd3e-4219-9139-bade681fb94d",
                        "artworkAsset": {
                            "__typename": "MultimediaAsset",
                            "contentType": "image/jpeg",
                            "filename": "9ccd26d6-f3a5-40de-817c-812b356d2bf9.jpg",
                            "id": "9ccd26d6-f3a5-40de-817c-812b356d2bf9",
                            "imageTransform": null
                        },
                        "id": "UmlnaHRzVG9rZW46YzA5YWIxZmQtMjNlMS00ODg3LWI4MjItNjQ0OGFmNWU2OGYz",
                        "mintNumber": 3,
                        "royalId": "c09ab1fd-23e1-4887-b822-6448af5e68f3",
                        "tier": {
                            "chainContract": {
                                "chainId": "137"
                            },
                            "edition": {
                                "contractAsset": {
                                    "downloadUrl": "https://royalio-prod-storage.s3.us-east-2.amazonaws.com/ded95421-05dd-4f1b-b928-faf51fa446c7.pdf?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAXY35ED6HVSOV45B6%2F20220322%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Date=20220322T013738Z&X-Amz-Expires=3600&X-Amz-SignedHeaders=host&X-Amz-Signature=62f93ef46aab4459c065cefdd6ad67ee0eb153ada44dd1a12a77727460cdcd28",
                                    "externalUrl": null,
                                    "filename": "ded95421-05dd-4f1b-b928-faf51fa446c7.pdf",
                                    "type": "BLOB"
                                },
                                "contributors": [
                                    {
                                        "profile": {
                                            "avatarAsset": {
                                                "__typename": "MultimediaAsset",
                                                "contentType": "image/jpg",
                                                "filename": "aefd8869-318e-4226-8cf6-0c5a3c39aadf.jpeg",
                                                "id": "aefd8869-318e-4226-8cf6-0c5a3c39aadf",
                                                "imageTransform": null
                                            },
                                            "name": "Vérité",
                                            "slug": "Verite"
                                        }
                                    }
                                ],
                                "permalink": "verite-hes-not-you",
                                "rightsAsset": {
                                    "childAssets": [],
                                    "title": "He's Not You",
                                    "type": "SINGLE"
                                },
                                "title": "He's Not You"
                            },
                            "maxSupply": 5,
                            "type": "DIAMOND"
                        },
                        "tokenId": "8166776806102523123120990578362437074947",
                        "wallet": {
                            "address": "0xedbf3e389d6777a764e719439492c909d7228f69",
                            "chainId": "137",
                            "id": "eb2e87a3-929b-4f72-adcf-a1d332c5d15f",
                            "source": "METAMASK",
                            "user": {
                                "profiles": [
                                    {
                                        "avatarAsset": null,
                                        "slug": "MVR7aTCA1iLZXw7riZ6R"
                                    }
                                ],
                                "royalId": "cfc1a0cd-0597-4345-86a4-e31f9c81797e"
                            }
                        }
                    }
                },
                {
                    "node": {
                        "__typename": "RightsToken",
                        "animationUrl": null,
                        "artworkAsset": {
                            "__typename": "MultimediaAsset",
                            "contentType": "image/jpg",
                            "filename": "892d9ae2-faf9-4444-a7a1-41daec6db6cf.jpg",
                            "id": "892d9ae2-faf9-4444-a7a1-41daec6db6cf",
                            "imageTransform": null
                        },
                        "id": "UmlnaHRzVG9rZW46MzI2M2NjYmMtMzYwOC00Y2QwLTlmMmItYmE5Y2NhYzYzZmE0",
                        "mintNumber": 3,
                        "royalId": "3263ccbc-3608-4cd0-9f2b-ba9ccac63fa4",
                        "tier": {
                            "chainContract": {
                                "chainId": "137"
                            },
                            "edition": {
                                "contractAsset": {
                                    "downloadUrl": "https://royalio-prod-storage.s3.us-east-2.amazonaws.com/5c87f98f-4b6f-43f9-bcc0-2f6a2bf73e89.pdf?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAXY35ED6HVSOV45B6%2F20220322%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Date=20220322T013738Z&X-Amz-Expires=3600&X-Amz-SignedHeaders=host&X-Amz-Signature=fb1841fdd807cd233a3f42d265ef82beacf1b8194af7bfe5699dfa35c0e4f6b1",
                                    "externalUrl": null,
                                    "filename": "5c87f98f-4b6f-43f9-bcc0-2f6a2bf73e89.pdf",
                                    "type": "BLOB"
                                },
                                "contributors": [
                                    {
                                        "profile": {
                                            "avatarAsset": {
                                                "__typename": "MultimediaAsset",
                                                "contentType": "image/jpg",
                                                "filename": "4730bb97-77cd-445c-b702-d127c9a85b55.jpg",
                                                "id": "4730bb97-77cd-445c-b702-d127c9a85b55",
                                                "imageTransform": null
                                            },
                                            "name": "Ollie",
                                            "slug": "Ollie"
                                        }
                                    }
                                ],
                                "permalink": "ollie-underrated",
                                "rightsAsset": {
                                    "childAssets": [
                                        {
                                            "royalId": "c8685afa-bd9b-463a-8fe6-9c181319a342"
                                        },
                                        {
                                            "royalId": "1cc88c34-57a4-4b50-96bb-fc091a4de418"
                                        },
                                        {
                                            "royalId": "6c7096bf-c384-4469-800b-f4a13514881b"
                                        },
                                        {
                                            "royalId": "97011f2b-6173-481b-aefe-78379c31df7a"
                                        }
                                    ],
                                    "title": "Underrated",
                                    "type": "EP"
                                },
                                "title": "Underrated"
                            },
                            "maxSupply": 600,
                            "type": "GOLD"
                        },
                        "tokenId": "9868188640707215440437863615521278132227",
                        "wallet": {
                            "address": "0xfbac565c2fbcaffd5cee1baf7acc3b5fcdadfcdd",
                            "chainId": "137",
                            "id": "ac24f3f8-2432-4692-bb63-27302ccfcead",
                            "source": "METAMASK",
                            "user": {
                                "profiles": [
                                    {
                                        "avatarAsset": {
                                            "__typename": "MultimediaAsset",
                                            "contentType": "image/jpeg",
                                            "filename": "b5e8736c-1529-45fb-bbbe-b4fa1fb614e6.jpg",
                                            "id": "b5e8736c-1529-45fb-bbbe-b4fa1fb614e6",
                                            "imageTransform": null
                                        },
                                        "slug": "spacejunkie"
                                    }
                                ],
                                "royalId": "353b73cf-935c-47e7-87a1-51cd738cdb2a"
                            }
                        }
                    }
                },
                {
                    "node": {
                        "__typename": "RightsToken",
                        "animationUrl": null,
                        "artworkAsset": {
                            "__typename": "MultimediaAsset",
                            "contentType": "image/png",
                            "filename": "02b0c9d9-64ba-4ac5-a4f1-a33b623d406f.png",
                            "id": "02b0c9d9-64ba-4ac5-a4f1-a33b623d406f",
                            "imageTransform": null
                        },
                        "id": "UmlnaHRzVG9rZW46NmJmODQxNzctNzBjNy00MTNkLWJmNTYtOGRiZGVhNTY5Njkz",
                        "mintNumber": 3,
                        "royalId": "6bf84177-70c7-413d-bf56-8dbdea569693",
                        "tier": {
                            "chainContract": {
                                "chainId": "137"
                            },
                            "edition": {
                                "contractAsset": {
                                    "downloadUrl": "https://royalio-prod-storage.s3.us-east-2.amazonaws.com/abfb0e7a-6d0a-4908-a3e9-cf87d1275349.pdf?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAXY35ED6HVSOV45B6%2F20220322%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Date=20220322T013738Z&X-Amz-Expires=3600&X-Amz-SignedHeaders=host&X-Amz-Signature=4f7cb8c4c09d3a41c607caa75e6e515cf5e41adab0f8f2e228295d2dd6be763b",
                                    "externalUrl": null,
                                    "filename": "abfb0e7a-6d0a-4908-a3e9-cf87d1275349.pdf",
                                    "type": "BLOB"
                                },
                                "contributors": [
                                    {
                                        "profile": {
                                            "avatarAsset": {
                                                "__typename": "MultimediaAsset",
                                                "contentType": "image/jpeg",
                                                "filename": "3b563eb1-01c5-4f4d-8214-bb02eecbd4b5.jpg",
                                                "id": "3b563eb1-01c5-4f4d-8214-bb02eecbd4b5",
                                                "imageTransform": null
                                            },
                                            "name": "Nas",
                                            "slug": "Nas"
                                        }
                                    }
                                ],
                                "permalink": "nas-rare",
                                "rightsAsset": {
                                    "childAssets": [],
                                    "title": "Rare",
                                    "type": "SINGLE"
                                },
                                "title": "Rare"
                            },
                            "maxSupply": 700,
                            "type": "GOLD"
                        },
                        "tokenId": "2381976568446569244243622252022377480195",
                        "wallet": {
                            "address": "0x1539f47fa2cfa1dee7c703cb4e9c444d1c8faff6",
                            "chainId": "137",
                            "id": "a1be3f45-bb45-4b8f-a222-b67be8af3492",
                            "source": "METAMASK",
                            "user": null
                        }
                    }
                },
                {
                    "node": {
                        "__typename": "RightsToken",
                        "animationUrl": null,
                        "artworkAsset": {
                            "__typename": "MultimediaAsset",
                            "contentType": "image/png",
                            "filename": "76784217-ca1f-4d4b-9981-3504297cd24f.png",
                            "id": "76784217-ca1f-4d4b-9981-3504297cd24f",
                            "imageTransform": null
                        },
                        "id": "UmlnaHRzVG9rZW46NzdiMDhlZDMtNGZiOS00MmMyLWExZTctYjc1NTM3MWEyM2Y4",
                        "mintNumber": 3,
                        "royalId": "77b08ed3-4fb9-42c2-a1e7-b755371a23f8",
                        "tier": {
                            "chainContract": {
                                "chainId": "1"
                            },
                            "edition": {
                                "contractAsset": {
                                    "downloadUrl": "https://royalio-prod-storage.s3.us-east-2.amazonaws.com/c8e8c5a5-b91d-4508-9bee-3fd67ab28b5d.pdf?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAXY35ED6HVSOV45B6%2F20220322%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Date=20220322T013738Z&X-Amz-Expires=3600&X-Amz-SignedHeaders=host&X-Amz-Signature=bdac10e03b5e75462f4e083e84c41a69d8bfca070100f64cce3ecb849e0dec4a",
                                    "externalUrl": null,
                                    "filename": "c8e8c5a5-b91d-4508-9bee-3fd67ab28b5d.pdf",
                                    "type": "BLOB"
                                },
                                "contributors": [
                                    {
                                        "profile": {
                                            "avatarAsset": {
                                                "__typename": "MultimediaAsset",
                                                "contentType": "image/jpeg",
                                                "filename": "e9b746d6-7aa7-412b-9b18-06e9db4c33d4.jpg",
                                                "id": "e9b746d6-7aa7-412b-9b18-06e9db4c33d4",
                                                "imageTransform": null
                                            },
                                            "name": "3LAU",
                                            "slug": "3LAU"
                                        }
                                    }
                                ],
                                "permalink": "worst-case",
                                "rightsAsset": {
                                    "childAssets": [],
                                    "title": "Worst Case",
                                    "type": "SINGLE"
                                },
                                "title": "Worst Case"
                            },
                            "maxSupply": 333,
                            "type": "PLATINUM"
                        },
                        "tokenId": "340282366920938463463374607431768211459",
                        "wallet": {
                            "address": "0x74fa7d401fba1dfe542a9511b25dd83d31ac6d0f",
                            "chainId": "1",
                            "id": "b029478f-d0bc-441b-9666-e5021d32322c",
                            "source": "METAMASK",
                            "user": {
                                "profiles": [
                                    {
                                        "avatarAsset": null,
                                        "slug": "elijah"
                                    }
                                ],
                                "royalId": "52a3cbf9-d3c8-4994-8f6f-02b3090d61fc"
                            }
                        }
                    }
                },
                {
                    "node": {
                        "__typename": "RightsToken",
                        "animationUrl": null,
                        "artworkAsset": {
                            "__typename": "MultimediaAsset",
                            "contentType": "image/gif",
                            "filename": "1898a736-5866-4b2f-9528-fa7f6e698696.gif",
                            "id": "1898a736-5866-4b2f-9528-fa7f6e698696",
                            "imageTransform": null
                        },
                        "id": "UmlnaHRzVG9rZW46YzUzOWRkZDItZWQyOS00NmYxLTk4ODAtZmZiZDk3NDUxZTVl",
                        "mintNumber": 3,
                        "royalId": "c539ddd2-ed29-46f1-9880-ffbd97451e5e",
                        "tier": {
                            "chainContract": {
                                "chainId": "137"
                            },
                            "edition": {
                                "contractAsset": {
                                    "downloadUrl": "https://royalio-prod-storage.s3.us-east-2.amazonaws.com/1cdd01aa-f6db-445f-bf1a-d4fa2f78be3e.pdf?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAXY35ED6HVSOV45B6%2F20220322%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Date=20220322T013738Z&X-Amz-Expires=3600&X-Amz-SignedHeaders=host&X-Amz-Signature=f900eace01b01e9a88e68256068a57397bf0d94887768501184e926f3e44ab54",
                                    "externalUrl": null,
                                    "filename": "1cdd01aa-f6db-445f-bf1a-d4fa2f78be3e.pdf",
                                    "type": "BLOB"
                                },
                                "contributors": [
                                    {
                                        "profile": {
                                            "avatarAsset": {
                                                "__typename": "MultimediaAsset",
                                                "contentType": "image/jpeg",
                                                "filename": "3b563eb1-01c5-4f4d-8214-bb02eecbd4b5.jpg",
                                                "id": "3b563eb1-01c5-4f4d-8214-bb02eecbd4b5",
                                                "imageTransform": null
                                            },
                                            "name": "Nas",
                                            "slug": "Nas"
                                        }
                                    }
                                ],
                                "permalink": "nas-ultrablack",
                                "rightsAsset": {
                                    "childAssets": [],
                                    "title": "Ultra Black",
                                    "type": "SINGLE"
                                },
                                "title": "Ultra Black"
                            },
                            "maxSupply": 10,
                            "type": "DIAMOND"
                        },
                        "tokenId": "1701411834604692317316873037158841057283",
                        "wallet": {
                            "address": "0x8e9312fc2714b58de3899f5537579387e631b8b7",
                            "chainId": "137",
                            "id": "de7bfcd2-9634-4b2f-bf41-86317a62f1ff",
                            "source": "METAMASK",
                            "user": {
                                "profiles": [
                                    {
                                        "avatarAsset": null,
                                        "slug": "Esj3ZGoBg8wL4Hy2kMD1"
                                    }
                                ],
                                "royalId": "e7c58018-ea2a-48e5-87a6-42f1e4029228"
                            }
                        }
                    }
                }
            ],
            "pageInfo": {
                "endCursor": "YXJyYXljb25uZWN0aW9uOjM5",
                "hasNextPage": true
            }
        }
    },
    "editionList": [
        {
            "id": "e98fa657-47a6-4fb7-836d-ae4d65e798ef",
            "name": "Underrated",
            "filename": "24f36df3-5338-4918-a7e3-93aff1ea430a.jpg",
        },
        {
            "id": "88bbfb5d-4069-4be6-b593-6ad90a0d4ee8",
            "name": "He's Not You",
            "filename": "8cab7b9d-8181-4cf6-af2d-b8fe305fb41d.jpg",
        },
        {
            "id": "22c31f6a-9f1d-48c9-9df3-d94ababdd633",
            "name": "Rare",
            "filename": "4b7ddb24-6831-4fd0-af74-3d0d316a4b15.png",
        },
        {
            "id": "dea58850-7092-4300-9819-3db6c83dea48",
            "name": "Ultra Black",
            "filename": "094cbb6b-0c54-463e-b8b2-2573b63192ad.png",
        },
    ],
}
/* eslint-disable global-require */
mock.onGet('/marketplace/data').reply(() => [200, data])
