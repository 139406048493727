<template>
  <div id="app" data-app>
    <component :is="layout" />
  </div>
</template>

<script>

const LayoutMain = () => import('@/layouts/main/LayoutMain.vue')
const LayoutNormal = () => import('@/layouts/normal/LayoutNormal.vue')
const LayoutFull = () => import('@/layouts/full/LayoutFull.vue')

export default {
  components: {
    LayoutMain,
    LayoutFull,
    LayoutNormal,
  },
  created() {
    this.handleWindowResize();
    window.addEventListener('resize', this.handleWindowResize);
  },
  computed: {
    layout() {
      if (this.$route.meta.layout === 'full') {return 'layout-full'}
      else if (this.$route.meta.layout === 'main') {return 'layout-main'}
      return 'layout-normal'
    },
  },
  methods: {
    handleWindowResize() {
      this.$store.commit('app/UPDATE_WINDOW_WIDTH', window.innerWidth)
      if (window.innerWidth < 768) {
        this.$store.commit('app/UPDATE_IS_MOBILE', true)
        return;
      }
      this.$store.commit('app/UPDATE_IS_MOBILE', false)
    }
  }
}
</script>
