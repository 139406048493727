import Vue from 'vue'
import VueRouter from 'vue-router'
import Menu from './routes/menu'
import Detail from './routes/detail'
import User from './routes/user'
import Login from './routes/login'

Vue.use(VueRouter)

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    scrollBehavior() {
        return {x: 0, y: 0}
    },
    routes: [
        {
            path: '/',
            name: 'home',
            component: () => import('@/views/Home.vue'),
            meta: {
                layout: 'main',
            },
        },
        ...Menu,
        ...Detail,
        ...User,
        ...Login,
        {
            path: '/error-404',
            name: 'error-404',
            component: () => import('@/views/error/Error404.vue'),
            meta: {
                layout: 'full',
            },
        },
        {
            path: '*',
            redirect: 'error-404',
        },
    ],
})

router.beforeEach((to, from, next) => {
    const loggedIn = localStorage.getItem("user");

    if (to.matched.some(record => record.meta.requiresAuth)) {
        if (!loggedIn) {
            next("/");
            return;
        }
        next();
    }
    next();
});
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
    const appLoading = document.getElementById('loading-bg')
    if (appLoading) {
        appLoading.style.display = 'none'
    }
})

export default router
