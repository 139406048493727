export default [
    {
        path: '/myprofile',
        name: 'my-profile',
        component: () => import('@/views/User/MyProfile.vue'),
        meta: {
            layout: 'normal',
        },
    },
    {
        path: '/userprofile',
        name: 'user-profile',
        component: () => import('@/views/Detail/UserProfile.vue'),
        meta: {
            layout: 'normal',
        },
    },
    {
        path: '/settings/profile',
        name: 'profile',
        component: () => import('@/views/User/Settings/EditProfile.vue'),
        meta: {
            layout: 'normal',
        },
    },
    {
        path: '/settings/wallets',
        name: 'wallets',
        component: () => import('@/views/User/Settings/Wallets.vue'),
        meta: {
            layout: 'normal',
        },
    },
    {
        path: '/settings/transfers',
        name: 'transfers',
        component: () => import('@/views/User/Settings/Transfers.vue'),
        meta: {
            layout: 'normal',
        },
    },
]
