import mock from '@/@fake-db/mock'
/* eslint-disable global-require */
const data = {
    "data": {
        "me": {
            "wallets": [
                {
                    "tokens": {
                        "edges": []
                    }
                },
                {

                    "tokens": {
                        "edges": []
                    }
                }
            ]
        }
    },
    "flash": null,
    "featuredEdition": {
        "id": "dea58850-7092-4300-9819-3db6c83dea48",
        "name": "Ultra Black",
        "type": "SINGLE",
        "filename": "4b7ddb24-6831-4fd0-af74-3d0d316a4b15.png",
    },
}
/* eslint-disable global-require */
const dashboardUri = "/dashboards";
const url = new RegExp(dashboardUri);

mock.onGet(url).reply(200, data);
