import mock from '@/@fake-db/mock'
/* eslint-disable global-require */
const data = {
    "data": {
        "edition": {
            "contractAsset": {
                "downloadUrl": "https://royalio-prod-storage.s3.us-east-2.amazonaws.com/abfb0e7a-6d0a-4908-a3e9-cf87d1275349.pdf?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAXY35ED6HVSOV45B6%2F20220322%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Date=20220322T083042Z&X-Amz-Expires=3600&X-Amz-SignedHeaders=host&X-Amz-Signature=a37ccfa7da7d6a36eb28b0e6c6792adcfd32e61c1b077f64b8bedda8a4f26251"
            },
            "contributors": [
                {
                    "profile": {
                        "avatarAsset": {
                            "__typename": "MultimediaAsset",
                            "contentType": "image/jpeg",
                            "filename": "3b563eb1-01c5-4f4d-8214-bb02eecbd4b5.jpg",
                            "id": "3b563eb1-01c5-4f4d-8214-bb02eecbd4b5",
                            "imageTransform": null
                        },
                        "name": "Nas",
                        "slug": "Nas",
                        "socialLinks": []
                    },
                    "royaltyRightsPercentage": 50
                }
            ],
            "description": "Keeping the post-Grammy momentum, Nas and Hit-boy return with the lead single \"Rare\" off of King's Disease II. The song shows that Nas is in rare form, cementing his spot as a veteran that's still at the top of his game.",
            "heroAsset": {
                "__typename": "MultimediaAsset",
                "contentType": "image/jpeg",
                "filename": "dcad401a-7dc4-4508-9561-199d3e8366be.jpg",
                "id": "dcad401a-7dc4-4508-9561-199d3e8366be",
                "imageTransform": null
            },
            "id": "UmlnaHRzRWRpdGlvbjoyMmMzMWY2YS05ZjFkLTQ4YzktOWRmMy1kOTRhYmFiZGQ2MzM=",
            "permalink": "nas-rare",
            "rightsAsset": {
                "description": "Keeping the post-Grammy momentum, Nas and Hit-boy return with the lead single \"Rare\" off of King's Disease II. The song shows that Nas is in rare form, cementing his spot as a veteran that's still at the top of his game.",
                "externalIdentifiers": [
                    {
                        "externalUrl": "https://open.spotify.com/embed/track/465eCPwUAz2qz7IdQaxUui?si=9902812521eb4f4a",
                        "source": "spotify"
                    },
                    {
                        "externalUrl": null,
                        "source": "isrc"
                    }
                ],
                "heroAsset": {
                    "__typename": "MultimediaAsset",
                    "contentType": "image/jpeg",
                    "filename": "ef1ddd5d-13d4-4a82-ada0-d276963c5efc.jpg",
                    "id": "ef1ddd5d-13d4-4a82-ada0-d276963c5efc",
                    "imageTransform": null
                },
                "title": "Rare",
                "type": "SINGLE"
            },
            "royalId": "22c31f6a-9f1d-48c9-9df3-d94ababdd633",
            "startTime": "2022-01-21T02:00:00.000000Z",
            "status": "ACTIVE",
            "tiers": [
                {
                    "artworkAsset": {
                        "__typename": "MultimediaAsset",
                        "contentType": "image/png",
                        "filename": "013a5db9-9392-44af-821f-68dcda5ef62c.png",
                        "id": "013a5db9-9392-44af-821f-68dcda5ef62c",
                        "imageTransform": null
                    },
                    "chainContract": {
                        "address": "0x7c885c4bFd179fb59f1056FBea319D579A278075",
                        "chainId": "137",
                        "implAddress": "0x7c885c4bFd179fb59f1056FBea319D579A278075",
                        "network": {
                            "explorers": [
                                {
                                    "name": "polygonscan",
                                    "url": "https://polygonscan.com"
                                }
                            ],
                            "name": "Matic(Polygon) Mainnet"
                        }
                    },
                    "edition": {
                        "permalink": "nas-rare",
                        "rightsAsset": {
                            "type": "SINGLE"
                        },
                        "tiers": [
                            {
                                "chainContract": {
                                    "id": "3b2db955-f11e-4da7-84a4-c7a28494b11a"
                                }
                            },
                            {
                                "chainContract": {
                                    "id": "3b2db955-f11e-4da7-84a4-c7a28494b11a"
                                }
                            },
                            {
                                "chainContract": {
                                    "id": "3b2db955-f11e-4da7-84a4-c7a28494b11a"
                                }
                            }
                        ]
                    },
                    "id": "UmlnaHRzRWRpdGlvblRpZXI6Y2FhMDY3MTAtNjliZi00NGRhLWE3MzEtNmI4YzI4MjMxZTg2",
                    "maxSupply": 700,
                    "number": 7,
                    "royalId": "caa06710-69bf-44da-a731-6b8c28231e86",
                    "royaltyClaimMillionths": 113,
                    "tierSale": {
                        "availableCount": 0,
                        "saleConfig": {
                            "__typename": "PrimeTierSaleStrategyFlash",
                            "acceptablePaymentMethods": [
                                "CARD",
                                "CRYPTO"
                            ],
                            "finishesAt": null,
                            "price": "99",
                            "primaryCurrency": "USD",
                            "startsAt": "2022-01-21T02:00:00Z"
                        },
                        "saleCount": 700,
                        "status": "COMPLETED"
                    },
                    "tokenCount": 700,
                    "type": "GOLD",
                    "royaltyRightsPercentage": 8
                },
                {
                    "artworkAsset": {
                        "__typename": "MultimediaAsset",
                        "contentType": "image/png",
                        "filename": "01dd1450-d7f8-47c7-ac70-8fd68f6f2de0.png",
                        "id": "01dd1450-d7f8-47c7-ac70-8fd68f6f2de0",
                        "imageTransform": null
                    },
                    "chainContract": {
                        "address": "0x7c885c4bFd179fb59f1056FBea319D579A278075",
                        "chainId": "137",
                        "implAddress": "0x7c885c4bFd179fb59f1056FBea319D579A278075",
                        "network": {
                            "explorers": [
                                {
                                    "name": "polygonscan",
                                    "url": "https://polygonscan.com"
                                }
                            ],
                            "name": "Matic(Polygon) Mainnet"
                        }
                    },
                    "edition": {
                        "permalink": "nas-rare",
                        "rightsAsset": {
                            "type": "SINGLE"
                        },
                        "tiers": [
                            {
                                "chainContract": {
                                    "id": "3b2db955-f11e-4da7-84a4-c7a28494b11a"
                                }
                            },
                            {
                                "chainContract": {
                                    "id": "3b2db955-f11e-4da7-84a4-c7a28494b11a"
                                }
                            },
                            {
                                "chainContract": {
                                    "id": "3b2db955-f11e-4da7-84a4-c7a28494b11a"
                                }
                            }
                        ]
                    },
                    "id": "UmlnaHRzRWRpdGlvblRpZXI6MGYxZDZmZjktNjg2OS00MDIyLThiNDktMDUzNmM2ZGIzYWRj",
                    "maxSupply": 400,
                    "number": 6,
                    "royalId": "0f1d6ff9-6869-4022-8b49-0536c6db3adc",
                    "royaltyClaimMillionths": 658,
                    "tierSale": {
                        "availableCount": 0,
                        "saleConfig": {
                            "__typename": "PrimeTierSaleStrategyFlash",
                            "acceptablePaymentMethods": [
                                "CARD",
                                "CRYPTO"
                            ],
                            "finishesAt": null,
                            "price": "499",
                            "primaryCurrency": "USD",
                            "startsAt": "2022-01-21T02:00:00Z"
                        },
                        "saleCount": 400,
                        "status": "COMPLETED"
                    },
                    "tokenCount": 400,
                    "type": "PLATINUM",
                    "royaltyRightsPercentage": 26
                },
                {
                    "artworkAsset": {
                        "__typename": "MultimediaAsset",
                        "contentType": "image/gif",
                        "filename": "0fc3e88c-0bf8-4da1-9c2c-b5507377dc73.gif",
                        "id": "0fc3e88c-0bf8-4da1-9c2c-b5507377dc73",
                        "imageTransform": null
                    },
                    "chainContract": {
                        "address": "0x7c885c4bFd179fb59f1056FBea319D579A278075",
                        "chainId": "137",
                        "implAddress": "0x7c885c4bFd179fb59f1056FBea319D579A278075",
                        "network": {
                            "explorers": [
                                {
                                    "name": "polygonscan",
                                    "url": "https://polygonscan.com"
                                }
                            ],
                            "name": "Matic(Polygon) Mainnet"
                        }
                    },
                    "edition": {
                        "permalink": "nas-rare",
                        "rightsAsset": {
                            "type": "SINGLE"
                        },
                        "tiers": [
                            {
                                "chainContract": {
                                    "id": "3b2db955-f11e-4da7-84a4-c7a28494b11a"
                                }
                            },
                            {
                                "chainContract": {
                                    "id": "3b2db955-f11e-4da7-84a4-c7a28494b11a"
                                }
                            },
                            {
                                "chainContract": {
                                    "id": "3b2db955-f11e-4da7-84a4-c7a28494b11a"
                                }
                            }
                        ]
                    },
                    "id": "UmlnaHRzRWRpdGlvblRpZXI6NDE3NjBmNjYtNGFmNy00MmZmLWIwNWMtMjZmNGUyNDc1ZjBh",
                    "maxSupply": 10,
                    "number": 8,
                    "royalId": "41760f66-4af7-42ff-b05c-26f4e2475f0a",
                    "royaltyClaimMillionths": 15789,
                    "tierSale": {
                        "availableCount": 0,
                        "saleConfig": {
                            "__typename": "PrimeTierSaleStrategyFlash",
                            "acceptablePaymentMethods": [
                                "CARD",
                                "CRYPTO"
                            ],
                            "finishesAt": null,
                            "price": "9999",
                            "primaryCurrency": "USD",
                            "startsAt": "2022-01-21T02:00:00Z"
                        },
                        "saleCount": 10,
                        "status": "COMPLETED"
                    },
                    "tokenCount": 10,
                    "type": "DIAMOND",
                    "royaltyRightsPercentage": 16
                }
            ],
            "title": "Rare",
            "breakdown": {
                "totalTokens": 1110,
                "amountRaised": 369000,


            }
        }
    },
    "tokens": {
        "edges": [
            {
                "node": {
                    "artworkAsset": {
                        "__typename": "MultimediaAsset",
                        "contentType": "image/png",
                        "filename": "01f07919-a2a0-4bfa-9627-51557fe2512e.png",
                        "id": "01f07919-a2a0-4bfa-9627-51557fe2512e",
                        "imageTransform": null
                    },
                    "id": "UmlnaHRzVG9rZW46NjlmYmRmMjktNzc4Yy00ZjU2LTgzMWUtYzBjMmY4YWNmNTNi",
                    "mintNumber": 1,
                    "tier": {
                        "maxSupply": 400,
                        "type": "PLATINUM",
                    },
                    "wallet": {
                        "address": "0xf5b0533bad49b29a41ea5b96fd1c43acbba37201",
                        "user": null
                    }
                }
            },
            {
                "node": {
                    "artworkAsset": {
                        "__typename": "MultimediaAsset",
                        "contentType": "image/gif",
                        "filename": "247f632c-1f03-45b1-92cd-012bbc18f5e8.gif",
                        "id": "247f632c-1f03-45b1-92cd-012bbc18f5e8",
                        "imageTransform": null
                    },
                    "id": "UmlnaHRzVG9rZW46NmQ2NDVlNTQtMTk1My00YzFmLTgxM2YtNGJhMzgxY2E2Zjgw",
                    "mintNumber": 1,
                    "tier": {
                        "maxSupply": 10,
                        "type": "DIAMOND",
                    },
                    "wallet": {
                        "address": "0xbf02ba2db8316e7b0a9ce4ec3c632c1e9bc1aafc",
                        "user": null
                    }
                }
            },
            {
                "node": {
                    "artworkAsset": {
                        "__typename": "MultimediaAsset",
                        "contentType": "image/png",
                        "filename": "027597b0-2638-431f-9ee2-2da4b8b0ceec.png",
                        "id": "027597b0-2638-431f-9ee2-2da4b8b0ceec",
                        "imageTransform": null
                    },
                    "id": "UmlnaHRzVG9rZW46YzViYjhiN2YtODI4Mi00NTQ2LTgwNTQtZjAxN2EyNmRhZGYx",
                    "mintNumber": 1,
                    "tier": {
                        "maxSupply": 700,
                        "type": "GOLD",
                    },
                    "wallet": {
                        "address": "0x74408ca60244aab6406ecfa50ffdd33de78a0038",
                        "user": {
                            "profiles": [
                                {
                                    "avatarAsset": null,
                                    "slug": "DUgehgKAYU9DR7SfXhkS"
                                }
                            ]
                        }
                    }
                }
            },
            {
                "node": {
                    "artworkAsset": {
                        "__typename": "MultimediaAsset",
                        "contentType": "image/gif",
                        "filename": "463452fa-55b3-4292-9595-d4e9181e4a8a.gif",
                        "id": "463452fa-55b3-4292-9595-d4e9181e4a8a",
                        "imageTransform": null
                    },
                    "id": "UmlnaHRzVG9rZW46NjY0YzQwNTAtYWE5ZS00YTcxLTgwYjAtYjgyN2Q3N2NiZmUx",
                    "mintNumber": 2,
                    "tier": {
                        "maxSupply": 10,
                        "type": "DIAMOND"
                    },
                    "wallet": {
                        "address": "0x55f22ce5b80829c0bb35349c9fd7d99ab92506a1",
                        "user": null
                    }
                }
            },
            {
                "node": {
                    "artworkAsset": {
                        "__typename": "MultimediaAsset",
                        "contentType": "image/png",
                        "filename": "028ba345-0f02-4fa5-b17a-fa0df2510927.png",
                        "id": "028ba345-0f02-4fa5-b17a-fa0df2510927",
                        "imageTransform": null
                    },
                    "id": "UmlnaHRzVG9rZW46MzBiMzU3NzQtNWJiMC00YTJkLTkzZjMtOGZkMDE5ZjM2MTI5",
                    "mintNumber": 2,
                    "tier": {
                        "maxSupply": 400,
                        "type": "PLATINUM"
                    },
                    "wallet": {
                        "address": "0x2dd60ccd2dcb0652e600af5ca8682c579cc357c7",
                        "user": null
                    }
                }
            },
            {
                "node": {
                    "artworkAsset": {
                        "__typename": "MultimediaAsset",
                        "contentType": "image/png",
                        "filename": "02a5e9c3-b74f-4d4a-971b-d1b39ae41a85.png",
                        "id": "02a5e9c3-b74f-4d4a-971b-d1b39ae41a85",
                        "imageTransform": null
                    },
                    "id": "UmlnaHRzVG9rZW46NjZlNDBiYzUtNTRmNy00NTU0LWI3ZTgtODMxYzU4ZGI2ZDdm",
                    "mintNumber": 2,
                    "tier": {
                        "maxSupply": 700,
                        "type": "GOLD"
                    },
                    "wallet": {
                        "address": "0x76047b39c5bbaaaf7c60ece95febbbc862867c2f",
                        "user": {
                            "profiles": [
                                {
                                    "avatarAsset": {
                                        "__typename": "MultimediaAsset",
                                        "contentType": "image/jpeg",
                                        "filename": "828c4d14-23ae-4677-9c43-52ce78e055dd.jpg",
                                        "id": "828c4d14-23ae-4677-9c43-52ce78e055dd",
                                        "imageTransform": null
                                    },
                                    "slug": "BE7Qd7wYWs_ziYai16_F"
                                }
                            ]
                        }
                    }
                }
            },
            {
                "node": {
                    "artworkAsset": {
                        "__typename": "MultimediaAsset",
                        "contentType": "image/png",
                        "filename": "02b0c9d9-64ba-4ac5-a4f1-a33b623d406f.png",
                        "id": "02b0c9d9-64ba-4ac5-a4f1-a33b623d406f",
                        "imageTransform": null
                    },
                    "id": "UmlnaHRzVG9rZW46NmJmODQxNzctNzBjNy00MTNkLWJmNTYtOGRiZGVhNTY5Njkz",
                    "mintNumber": 3,
                    "tier": {
                        "maxSupply": 700,
                        "type": "GOLD"
                    },
                    "wallet": {
                        "address": "0x1539f47fa2cfa1dee7c703cb4e9c444d1c8faff6",
                        "user": null
                    }
                }
            },
            {
                "node": {
                    "artworkAsset": {
                        "__typename": "MultimediaAsset",
                        "contentType": "image/png",
                        "filename": "0333e9df-c305-4caf-8494-b74686df7382.png",
                        "id": "0333e9df-c305-4caf-8494-b74686df7382",
                        "imageTransform": null
                    },
                    "id": "UmlnaHRzVG9rZW46N2RjNzVhYzItZDhiNS00ODI5LWJjYTctNmIwYzlkZjczZTE3",
                    "mintNumber": 3,
                    "tier": {
                        "maxSupply": 400,
                        "type": "PLATINUM"
                    },
                    "wallet": {
                        "address": "0xd474adfa0bc4b5afeaab88a7a7f53475699e859e",
                        "user": {
                            "profiles": [
                                {
                                    "avatarAsset": {
                                        "__typename": "MultimediaAsset",
                                        "contentType": "image/jpeg",
                                        "filename": "a37e4b4d-d0dd-42dd-998c-9d5310e0d85f.jpg",
                                        "id": "a37e4b4d-d0dd-42dd-998c-9d5310e0d85f",
                                        "imageTransform": null
                                    },
                                    "slug": "Quillson"
                                }
                            ]
                        }
                    }
                }
            },
            {
                "node": {
                    "artworkAsset": {
                        "__typename": "MultimediaAsset",
                        "contentType": "image/gif",
                        "filename": "5743ff90-2f05-48f1-ab8e-5c5c59f98ae3.gif",
                        "id": "5743ff90-2f05-48f1-ab8e-5c5c59f98ae3",
                        "imageTransform": null
                    },
                    "id": "UmlnaHRzVG9rZW46YmUxYjNiNTgtOGRjZS00NDM2LTk2MGEtNzhmZDVkMTA5ZTMz",
                    "mintNumber": 3,
                    "tier": {
                        "maxSupply": 10,
                        "type": "DIAMOND"
                    },
                    "wallet": {
                        "address": "0x44c00980099e66fd5f2120fcb54fe98884bd3e06",
                        "user": {
                            "profiles": [
                                {
                                    "avatarAsset": {
                                        "__typename": "MultimediaAsset",
                                        "contentType": "image/jpeg",
                                        "filename": "f6ece52c-f66f-4d92-88d2-9e68c17a60cb.jpg",
                                        "id": "f6ece52c-f66f-4d92-88d2-9e68c17a60cb",
                                        "imageTransform": null
                                    },
                                    "slug": "_Y2Ft2eQMJoca-mBmhhK"
                                }
                            ]
                        }
                    }
                }
            },
            {
                "node": {
                    "artworkAsset": {
                        "__typename": "MultimediaAsset",
                        "contentType": "image/png",
                        "filename": "03978bf1-e80d-4f22-bb8a-839f0c57a81e.png",
                        "id": "03978bf1-e80d-4f22-bb8a-839f0c57a81e",
                        "imageTransform": null
                    },
                    "id": "UmlnaHRzVG9rZW46MDIyNjBhYTItNjYyOC00ZTA1LWFlMDMtYWIyZWNhM2EwYWU3",
                    "mintNumber": 4,
                    "tier": {
                        "maxSupply": 700,
                        "type": "GOLD"
                    },
                    "wallet": {
                        "address": "0x8b0586e1388bbf582984232b5a41e40fbd6225b4",
                        "user": null
                    }
                }
            },
            {
                "node": {
                    "artworkAsset": {
                        "__typename": "MultimediaAsset",
                        "contentType": "image/gif",
                        "filename": "5a4cbcfe-3ffe-40de-ba23-99f5d93a715d.gif",
                        "id": "5a4cbcfe-3ffe-40de-ba23-99f5d93a715d",
                        "imageTransform": null
                    },
                    "id": "UmlnaHRzVG9rZW46M2Q2ZmE0NGEtNGFiYy00NmQ0LTllNDQtOGNmYzc0MjJkNzYw",
                    "mintNumber": 4,
                    "tier": {
                        "maxSupply": 10,
                        "type": "DIAMOND"
                    },
                    "wallet": {
                        "address": "0x0b1489dd593ef2d6d1bf701819e4c608245b7e30",
                        "user": {
                            "profiles": [
                                {
                                    "avatarAsset": null,
                                    "slug": "83tBYMc53dOmxRIozq7X"
                                }
                            ]
                        }
                    }
                }
            },
            {
                "node": {
                    "artworkAsset": {
                        "__typename": "MultimediaAsset",
                        "contentType": "image/png",
                        "filename": "056bbe78-2be7-4d59-85df-8f189715be44.png",
                        "id": "056bbe78-2be7-4d59-85df-8f189715be44",
                        "imageTransform": null
                    },
                    "id": "UmlnaHRzVG9rZW46ZDYwZWQzNjMtZmQwMC00NDViLThlYzgtMTM5ZDhkMDQ0NzY0",
                    "mintNumber": 4,
                    "tier": {
                        "maxSupply": 400,
                        "type": "PLATINUM"
                    },
                    "wallet": {
                        "address": "0x06a49994fb30a542ec50ac8fb47c7dbff443e5e6",
                        "user": {
                            "profiles": [
                                {
                                    "avatarAsset": null,
                                    "slug": "dkj717"
                                }
                            ]
                        }
                    }
                }
            },
            {
                "node": {
                    "artworkAsset": {
                        "__typename": "MultimediaAsset",
                        "contentType": "image/gif",
                        "filename": "61e177d1-7efa-436d-bfd4-94a07c5001b1.gif",
                        "id": "61e177d1-7efa-436d-bfd4-94a07c5001b1",
                        "imageTransform": null
                    },
                    "id": "UmlnaHRzVG9rZW46YWI1OWE4ZWMtMTBjNC00NGZhLTlhODUtYmRkNWVjZGU5YjM3",
                    "mintNumber": 5,
                    "tier": {
                        "maxSupply": 10,
                        "type": "DIAMOND"
                    },
                    "wallet": {
                        "address": "0x5fbc08D66aae7573d3cB9E0fc45F2602087866DE",
                        "user": {
                            "profiles": [
                                {
                                    "avatarAsset": null,
                                    "slug": "JdnycRilo7XtR-UA4KsE"
                                }
                            ]
                        }
                    }
                }
            },
            {
                "node": {
                    "artworkAsset": {
                        "__typename": "MultimediaAsset",
                        "contentType": "image/png",
                        "filename": "047f7bbe-579b-4e84-903c-358b54b256dc.png",
                        "id": "047f7bbe-579b-4e84-903c-358b54b256dc",
                        "imageTransform": null
                    },
                    "id": "UmlnaHRzVG9rZW46NmZmMjFmMGItOTRmZS00NmI5LWEzOTctMjFiOTU1NWUzN2Fh",
                    "mintNumber": 5,
                    "tier": {
                        "maxSupply": 700,
                        "type": "GOLD"
                    },
                    "wallet": {
                        "address": "0xa1e9d529dfd727b7d43894c29f82aeb9d486c049",
                        "user": null
                    }
                }
            },
            {
                "node": {
                    "artworkAsset": {
                        "__typename": "MultimediaAsset",
                        "contentType": "image/png",
                        "filename": "06f7d109-af08-4324-8538-606d01381d4d.png",
                        "id": "06f7d109-af08-4324-8538-606d01381d4d",
                        "imageTransform": null
                    },
                    "id": "UmlnaHRzVG9rZW46MWJhODYzZTEtM2QzOC00MTAxLThlMzYtOTZmMjRiYmJhYjAx",
                    "mintNumber": 6,
                    "tier": {
                        "maxSupply": 400,
                        "type": "PLATINUM"
                    },
                    "wallet": {
                        "address": "0x080314581b003b60a61f8bdf457b26d283dd68b1",
                        "user": {
                            "profiles": [
                                {
                                    "avatarAsset": null,
                                    "slug": "4WK"
                                }
                            ]
                        }
                    }
                }
            },
            {
                "node": {
                    "artworkAsset": {
                        "__typename": "MultimediaAsset",
                        "contentType": "image/png",
                        "filename": "04dec6e0-6d3f-4318-a293-7d69d2a7214c.png",
                        "id": "04dec6e0-6d3f-4318-a293-7d69d2a7214c",
                        "imageTransform": null
                    },
                    "id": "UmlnaHRzVG9rZW46NTg3MTVhMTYtYjYwOS00MTgxLWIyYmYtZTYxMzFhMWRlZDc1",
                    "mintNumber": 6,
                    "tier": {
                        "maxSupply": 700,
                        "type": "GOLD"
                    },
                    "wallet": {
                        "address": "0xac66deee7b4380b9937ec4053de5aca03d29e07c",
                        "user": null
                    }
                }
            },
            {
                "node": {
                    "artworkAsset": {
                        "__typename": "MultimediaAsset",
                        "contentType": "image/gif",
                        "filename": "af8eae11-6580-4c03-8814-12ddfe9cecaa.gif",
                        "id": "af8eae11-6580-4c03-8814-12ddfe9cecaa",
                        "imageTransform": null
                    },
                    "id": "UmlnaHRzVG9rZW46MjA0YjUwNGUtYTA4NC00ZjY5LWE3ZTItMjkzYmI5MWJhMDZk",
                    "mintNumber": 6,
                    "tier": {
                        "maxSupply": 10,
                        "type": "DIAMOND"
                    },
                    "wallet": {
                        "address": "0x90f01dff92fc2341bc6cdafc715e9fbf46ed40f7",
                        "user": {
                            "profiles": [
                                {
                                    "avatarAsset": {
                                        "__typename": "MultimediaAsset",
                                        "contentType": "image/jpeg",
                                        "filename": "276e1d0c-b0b9-4830-b9f4-41e8794e42a8.jpg",
                                        "id": "276e1d0c-b0b9-4830-b9f4-41e8794e42a8",
                                        "imageTransform": null
                                    },
                                    "slug": "_hFpSoS0k0cGCI6GWWrK"
                                }
                            ]
                        }
                    }
                }
            },
            {
                "node": {
                    "artworkAsset": {
                        "__typename": "MultimediaAsset",
                        "contentType": "image/gif",
                        "filename": "bda47d34-6a85-4738-a89a-ea695eba97ce.gif",
                        "id": "bda47d34-6a85-4738-a89a-ea695eba97ce",
                        "imageTransform": null
                    },
                    "id": "UmlnaHRzVG9rZW46ZWRlZTYxODItZGYxOS00MTllLWE2MmItZGUwMDk4ZjZjOTJj",
                    "mintNumber": 7,
                    "tier": {
                        "maxSupply": 10,
                        "type": "DIAMOND"
                    },
                    "wallet": {
                        "address": "0xeb1fbb2d250d7a20ecd76a33c079d7c0b74f965e",
                        "user": {
                            "profiles": [
                                {
                                    "avatarAsset": null,
                                    "slug": "MyNameIsJeff"
                                }
                            ]
                        }
                    }
                }
            },
            {
                "node": {
                    "artworkAsset": {
                        "__typename": "MultimediaAsset",
                        "contentType": "image/png",
                        "filename": "053e7e13-0cf8-4c83-ba92-84c40c9f2ba5.png",
                        "id": "053e7e13-0cf8-4c83-ba92-84c40c9f2ba5",
                        "imageTransform": null
                    },
                    "id": "UmlnaHRzVG9rZW46ZWJlNDI5MWUtYzBiNy00YmY2LTg5NzEtZTE2OGFlYjY5N2Jk",
                    "mintNumber": 7,
                    "tier": {
                        "maxSupply": 700,
                        "type": "GOLD"
                    },
                    "wallet": {
                        "address": "0x6e6a33cf94fa81da9d28f1d59fb48c93c8c57571",
                        "user": null
                    }
                }
            },
            {
                "node": {
                    "artworkAsset": {
                        "__typename": "MultimediaAsset",
                        "contentType": "image/png",
                        "filename": "07612055-b8bf-4602-abda-1a71df5c2850.png",
                        "id": "07612055-b8bf-4602-abda-1a71df5c2850",
                        "imageTransform": null
                    },
                    "id": "UmlnaHRzVG9rZW46NDFhNGFjMTctNWY2NC00YzJlLWFhNGQtN2JjYmQzZDEzYTVi",
                    "mintNumber": 7,
                    "tier": {
                        "maxSupply": 400,
                        "type": "PLATINUM"
                    },
                    "wallet": {
                        "address": "0x958e2ebb40147dfee318ab640d9f0e66783ec62d",
                        "user": {
                            "profiles": [
                                {
                                    "avatarAsset": null,
                                    "slug": "augustusbach"
                                }
                            ]
                        }
                    }
                }
            }
        ],
        "pageInfo": {
            "endCursor": "YXJyYXljb25uZWN0aW9uOjE5",
            "hasNextPage": true
        }
    },
    "info": {
        "__typename": "Content_FeatureList",
        "title": "How it works",
        "description": "Artists and fans co-own music together.\n",
        "features": [
            {
                "id": "Q29udGVudF9GZWF0dXJlTGlzdEl0ZW06MQ",
                "title": "Artists sell.",
                "description": "Musicians use royal to sell royalty ownership in their songs and give collectors access to special perks.\n",
                "image": {
                    "url": "https://images.us-east-1.aws.slicknode.com/da58dee653e2858bd511fe74dbca0d9005093de3/600x600/palace-ui-royal-io/6fcfd628-794d-46ef-bdfb-793ba4283caa/37f2b378-b8d8-4fa2-824c-1dc70daf1490.jpeg"
                }
            },
            {
                "id": "Q29udGVudF9GZWF0dXJlTGlzdEl0ZW06Mg",
                "title": "Collectors buy.",
                "description": "Collectors can buy royalty ownership in songs directly from their favorite artists in the form of tokens.\n",
                "image": {
                    "url": "https://images.us-east-1.aws.slicknode.com/e83642a871529035c89e7b1beb5cd9ec7860756b/600x600/palace-ui-royal-io/5dc6a613-0ff8-4f97-b08d-4e0bddf493d7/96136dae-2528-4a84-9636-ca4a7aaceb6a.jpeg"
                }
            },
            {
                "id": "Q29udGVudF9GZWF0dXJlTGlzdEl0ZW06Mw",
                "title": "Both rise.",
                "description": "Collectors can claim royalties for the music they own after they’ve accrued. When artists grow, everyone wins.\n",
                "image": {
                    "url": "https://images.us-east-1.aws.slicknode.com/7feec2efce4766c5eeb1d4ef8e9c8537ade7818d/600x600/palace-ui-royal-io/5ad595b3-5a8f-469e-86dc-47ea10f02e73/5f2e0e70-9cc0-4210-92be-0a9348652e17.jpeg"
                }
            }
        ]
    },
    "featuredEdition": {
        "id": "dea58850-7092-4300-9819-3db6c83dea48",
        "name": "Ultra Black",
        "type": "SINGLE",
        "filename": "4b7ddb24-6831-4fd0-af74-3d0d316a4b15.png",
    },
}
/* eslint-disable global-require */
const editionUri = "/edition";
const url = new RegExp(`${editionUri}/*`);

mock.onGet(url).reply(200, data);
