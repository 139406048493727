export default [
    {
        path: '/auth',
        name: 'auth',
        component: () => import('@/views/Login/Auth.vue'),
        meta: {
            layout: 'full',
        },
    },
    {
        path: '/register',
        name: 'register',
        component: () => import('@/views/Login/Register.vue'),
        meta: {
            layout: 'full',
        },
    },
    {
        path: '/auth/reset-password',
        name: 'reset-password',
        component: () => import('@/views/Login/ResetPassword.vue'),
        meta: {
            layout: 'full'
        }
    },
    {
        path: '/legal/tos',
        name: 'tos',
        component: () => import('@/views/Login/TermsOfService.vue'),
        meta: {
            layout: 'main'
        }
    }
]
