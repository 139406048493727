export default [
    {
        path: '/artist/:artist?',
        name: 'artist',
        component: () => import('@/views/Detail/Artist.vue'),
        meta: {
            layout: 'normal',
        },
    },
    {
        path: '/edition/:edition?',
        name: 'edition',
        component: () => import('@/views/Detail/Edition.vue'),
        meta: {
            layout: 'normal',
        },
    },
    {
        path: '/token/:tokenId',
        name: 'token',
        component: () => import('@/views/Detail/Token.vue'),
        meta: {
            layout: 'normal',
        },
    },
    {
        path: '/userprofile',
        name: 'userprofile',
        component: () => import('@/views/Detail/UserProfile.vue'),
        meta: {
            layout: 'normal',
        },
    },
    {
        path: '/artist-submission',
        name: 'artist-submission',
        component: () => import('@/views/Detail/ArtistSubmission.vue'),
        meta: {
            layout: 'main',
        },
    },
]
