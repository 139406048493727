import Vue from 'vue'

import router from './router'
import store from './store'
import App from './App.vue'

// Axios Mock Adapter
import '@/@fake-db/db'

// 3rd party plugins
import vuetify from '@/libs/vuetify'
import '@/libs/vue-composition-api'
import '@/libs/portal-vue'
import '@/libs/toastification'
import '@/libs/sweet-alerts'
import '@/libs/vue-apexcharts'
import '@/libs/v-tooltip'
import '@/libs/vue-js-toggle-button'
import '@/libs/clipboard'
import '@/libs/vue-select'

// import assets styles
require('@/assets/scss/style.scss')


new Vue({
    router,
    store,
    vuetify,
    created() {
        const userString = localStorage.getItem("user");
        if (userString) {
            const userData = JSON.parse(userString);
            this.$store.commit("app/SET_USER_DATA", userData);
        }
        this.$http.interceptors.response.use(
            response => response,
            error => {
                console.log(error);

                if (error.response.status === 401) {
                    this.$store.dispatch("app/logout");
                }
                return Promise.reject(error);
            }
        );
    },
    render: h => h(App),
}).$mount('#app')
