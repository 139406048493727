import mock from '@/@fake-db/mock'
/* eslint-disable global-require */
const data = {
    "profile": {
        "avatarAsset": {
            "__typename": "MultimediaAsset",
            "contentType": "image/png",
            "filename": "8d385580-24ea-42f6-9f49-afe5e88ec0eb.png",
            "id": "8d385580-24ea-42f6-9f49-afe5e88ec0eb",
            "imageTransform": null
        },
        "description": null,
        "editions": {
            "edges": []
        },
        "heroAsset": null,
        "id": "UHJvZmlsZTo5YjYwMDEzNC0yNDEyLTQwY2QtYjE4Zi0xN2IxNjIzODlhYTM=",
        "name": "DiamondHands",
        "owner": {
            "royalId": "1d18a9be-11f7-4115-9b4c-5b5a80cefb9b",
            "wallets": [
                {
                    "address": "0xc5bf5e2b135d1e07660ead4ee76e46062d41238e",
                    "tokens": {
                        "edges": []
                    }
                },
                {
                    "address": "0xc5bf5e2b135d1e07660ead4ee76e46062d41238e",
                    "tokens": {
                        "edges": [
                            {
                                "node": {
                                    "__typename": "RightsToken",
                                    "animationUrl": "https://api.royal.io/assets/26bd3138-af95-40e8-a443-c939cc3311bb",
                                    "artworkAsset": {
                                        "__typename": "MultimediaAsset",
                                        "contentType": "image/png",
                                        "filename": "69a8b75d-28bd-4754-8df8-81294d1c5d17.png",
                                        "id": "69a8b75d-28bd-4754-8df8-81294d1c5d17",
                                        "imageTransform": null
                                    },
                                    "id": "UmlnaHRzVG9rZW46YjdlYTU2MWQtMmY3Ny00NmI3LWEyOTAtNDE5ZmM4NWZiNThj",
                                    "mintNumber": 1,
                                    "royalId": "b7ea561d-2f77-46b7-a290-419fc85fb58c",
                                    "tier": {
                                        "chainContract": {
                                            "chainId": "137"
                                        },
                                        "edition": {
                                            "contributors": [
                                                {
                                                    "profile": {
                                                        "avatarAsset": {
                                                            "__typename": "MultimediaAsset",
                                                            "contentType": "image/jpg",
                                                            "filename": "4730bb97-77cd-445c-b702-d127c9a85b55.jpg",
                                                            "id": "4730bb97-77cd-445c-b702-d127c9a85b55",
                                                            "imageTransform": null
                                                        },
                                                        "name": "Ollie",
                                                        "slug": "Ollie"
                                                    }
                                                }
                                            ],
                                            "permalink": "ollie-underrated",
                                            "rightsAsset": {
                                                "childAssets": [
                                                    {
                                                        "royalId": "c8685afa-bd9b-463a-8fe6-9c181319a342"
                                                    },
                                                    {
                                                        "royalId": "1cc88c34-57a4-4b50-96bb-fc091a4de418"
                                                    },
                                                    {
                                                        "royalId": "6c7096bf-c384-4469-800b-f4a13514881b"
                                                    },
                                                    {
                                                        "royalId": "97011f2b-6173-481b-aefe-78379c31df7a"
                                                    }
                                                ],
                                                "title": "Underrated",
                                                "type": "EP"
                                            },
                                            "title": "Underrated"
                                        },
                                        "maxSupply": 10,
                                        "type": "DIAMOND"
                                    },
                                    "tokenId": "9187623906865338513511114400657741709313",
                                    "wallet": {
                                        "address": "0xc5bf5e2b135d1e07660ead4ee76e46062d41238e",
                                        "chainId": "137",
                                        "id": "f6cfbaf4-edb9-4f01-bb01-b609bb6ed335",
                                        "source": "METAMASK",
                                        "user": {
                                            "profiles": [
                                                {
                                                    "avatarAsset": {
                                                        "__typename": "MultimediaAsset",
                                                        "contentType": "image/png",
                                                        "filename": "8d385580-24ea-42f6-9f49-afe5e88ec0eb.png",
                                                        "id": "8d385580-24ea-42f6-9f49-afe5e88ec0eb",
                                                        "imageTransform": null
                                                    },
                                                    "slug": "UKHwnWJMbDQD7N6Jgg9l"
                                                }
                                            ],
                                            "royalId": "1d18a9be-11f7-4115-9b4c-5b5a80cefb9b"
                                        }
                                    }
                                }
                            },
                            {
                                "node": {
                                    "__typename": "RightsToken",
                                    "animationUrl": null,
                                    "artworkAsset": {
                                        "__typename": "MultimediaAsset",
                                        "contentType": "image/png",
                                        "filename": "0e830584-47e2-4334-a797-892732934eea.png",
                                        "id": "0e830584-47e2-4334-a797-892732934eea",
                                        "imageTransform": null
                                    },
                                    "id": "UmlnaHRzVG9rZW46N2I4MjY1NzItODIwMi00MTRlLWFmMGEtNzg0MTQzNzQyM2Q1",
                                    "mintNumber": 20,
                                    "royalId": "7b826572-8202-414e-af0a-7841437423d5",
                                    "tier": {
                                        "chainContract": {
                                            "chainId": "137"
                                        },
                                        "edition": {
                                            "contributors": [
                                                {
                                                    "profile": {
                                                        "avatarAsset": {
                                                            "__typename": "MultimediaAsset",
                                                            "contentType": "image/jpeg",
                                                            "filename": "3b563eb1-01c5-4f4d-8214-bb02eecbd4b5.jpg",
                                                            "id": "3b563eb1-01c5-4f4d-8214-bb02eecbd4b5",
                                                            "imageTransform": null
                                                        },
                                                        "name": "Nas",
                                                        "slug": "Nas"
                                                    }
                                                }
                                            ],
                                            "permalink": "nas-rare",
                                            "rightsAsset": {
                                                "childAssets": [],
                                                "title": "Rare",
                                                "type": "SINGLE"
                                            },
                                            "title": "Rare"
                                        },
                                        "maxSupply": 400,
                                        "type": "PLATINUM"
                                    },
                                    "tokenId": "2041694201525630780780247644590609268756",
                                    "wallet": {
                                        "address": "0xc5bf5e2b135d1e07660ead4ee76e46062d41238e",
                                        "chainId": "137",
                                        "id": "f6cfbaf4-edb9-4f01-bb01-b609bb6ed335",
                                        "source": "METAMASK",
                                        "user": {
                                            "profiles": [
                                                {
                                                    "avatarAsset": {
                                                        "__typename": "MultimediaAsset",
                                                        "contentType": "image/png",
                                                        "filename": "8d385580-24ea-42f6-9f49-afe5e88ec0eb.png",
                                                        "id": "8d385580-24ea-42f6-9f49-afe5e88ec0eb",
                                                        "imageTransform": null
                                                    },
                                                    "slug": "UKHwnWJMbDQD7N6Jgg9l"
                                                }
                                            ],
                                            "royalId": "1d18a9be-11f7-4115-9b4c-5b5a80cefb9b"
                                        }
                                    }
                                }
                            },
                            {
                                "node": {
                                    "__typename": "RightsToken",
                                    "animationUrl": null,
                                    "artworkAsset": {
                                        "__typename": "MultimediaAsset",
                                        "contentType": "image/png",
                                        "filename": "c2ccc905-0d9c-4d5e-bcc3-fb6859b2bd8d.png",
                                        "id": "c2ccc905-0d9c-4d5e-bcc3-fb6859b2bd8d",
                                        "imageTransform": null
                                    },
                                    "id": "UmlnaHRzVG9rZW46ZmM0ODQ4YjUtZDcwZi00M2E5LWIwNWQtYTc4YTJhMzYzM2Ez",
                                    "mintNumber": 294,
                                    "royalId": "fc4848b5-d70f-43a9-b05d-a78a2a3633a3",
                                    "tier": {
                                        "chainContract": {
                                            "chainId": "137"
                                        },
                                        "edition": {
                                            "contributors": [
                                                {
                                                    "profile": {
                                                        "avatarAsset": {
                                                            "__typename": "MultimediaAsset",
                                                            "contentType": "image/jpeg",
                                                            "filename": "3b563eb1-01c5-4f4d-8214-bb02eecbd4b5.jpg",
                                                            "id": "3b563eb1-01c5-4f4d-8214-bb02eecbd4b5",
                                                            "imageTransform": null
                                                        },
                                                        "name": "Nas",
                                                        "slug": "Nas"
                                                    }
                                                }
                                            ],
                                            "permalink": "nas-rare",
                                            "rightsAsset": {
                                                "childAssets": [],
                                                "title": "Rare",
                                                "type": "SINGLE"
                                            },
                                            "title": "Rare"
                                        },
                                        "maxSupply": 400,
                                        "type": "PLATINUM"
                                    },
                                    "tokenId": "2041694201525630780780247644590609269030",
                                    "wallet": {
                                        "address": "0xc5bf5e2b135d1e07660ead4ee76e46062d41238e",
                                        "chainId": "137",
                                        "id": "f6cfbaf4-edb9-4f01-bb01-b609bb6ed335",
                                        "source": "METAMASK",
                                        "user": {
                                            "profiles": [
                                                {
                                                    "avatarAsset": {
                                                        "__typename": "MultimediaAsset",
                                                        "contentType": "image/png",
                                                        "filename": "8d385580-24ea-42f6-9f49-afe5e88ec0eb.png",
                                                        "id": "8d385580-24ea-42f6-9f49-afe5e88ec0eb",
                                                        "imageTransform": null
                                                    },
                                                    "slug": "UKHwnWJMbDQD7N6Jgg9l"
                                                }
                                            ],
                                            "royalId": "1d18a9be-11f7-4115-9b4c-5b5a80cefb9b"
                                        }
                                    }
                                }
                            },
                            {
                                "node": {
                                    "__typename": "RightsToken",
                                    "animationUrl": null,
                                    "artworkAsset": {
                                        "__typename": "MultimediaAsset",
                                        "contentType": "image/png",
                                        "filename": "e361d3eb-afba-430d-9659-63403375e493.png",
                                        "id": "e361d3eb-afba-430d-9659-63403375e493",
                                        "imageTransform": null
                                    },
                                    "id": "UmlnaHRzVG9rZW46ZGU3NDEyMTctOTY4ZS00ZWZjLThjZmYtOThkZDBlYTZhYTMy",
                                    "mintNumber": 350,
                                    "royalId": "de741217-968e-4efc-8cff-98dd0ea6aa32",
                                    "tier": {
                                        "chainContract": {
                                            "chainId": "137"
                                        },
                                        "edition": {
                                            "contributors": [
                                                {
                                                    "profile": {
                                                        "avatarAsset": {
                                                            "__typename": "MultimediaAsset",
                                                            "contentType": "image/jpeg",
                                                            "filename": "3b563eb1-01c5-4f4d-8214-bb02eecbd4b5.jpg",
                                                            "id": "3b563eb1-01c5-4f4d-8214-bb02eecbd4b5",
                                                            "imageTransform": null
                                                        },
                                                        "name": "Nas",
                                                        "slug": "Nas"
                                                    }
                                                }
                                            ],
                                            "permalink": "nas-rare",
                                            "rightsAsset": {
                                                "childAssets": [],
                                                "title": "Rare",
                                                "type": "SINGLE"
                                            },
                                            "title": "Rare"
                                        },
                                        "maxSupply": 400,
                                        "type": "PLATINUM"
                                    },
                                    "tokenId": "2041694201525630780780247644590609269086",
                                    "wallet": {
                                        "address": "0xc5bf5e2b135d1e07660ead4ee76e46062d41238e",
                                        "chainId": "137",
                                        "id": "f6cfbaf4-edb9-4f01-bb01-b609bb6ed335",
                                        "source": "METAMASK",
                                        "user": {
                                            "profiles": [
                                                {
                                                    "avatarAsset": {
                                                        "__typename": "MultimediaAsset",
                                                        "contentType": "image/png",
                                                        "filename": "8d385580-24ea-42f6-9f49-afe5e88ec0eb.png",
                                                        "id": "8d385580-24ea-42f6-9f49-afe5e88ec0eb",
                                                        "imageTransform": null
                                                    },
                                                    "slug": "UKHwnWJMbDQD7N6Jgg9l"
                                                }
                                            ],
                                            "royalId": "1d18a9be-11f7-4115-9b4c-5b5a80cefb9b"
                                        }
                                    }
                                }
                            }
                        ]
                    }
                }
            ]
        },
        "profileStars": [
            {
                "id": "aea277f2-0831-42c2-b33f-8e2d1d78f07b",
                "user": {
                    "wallets": [
                        {
                            "address": "0xc5bf5e2b135d1e07660ead4ee76e46062d41238e"
                        },
                        {
                            "address": "0xc5bf5e2b135d1e07660ead4ee76e46062d41238e"
                        }
                    ]
                }
            }
        ],
        "slug": "UKHwnWJMbDQD7N6Jgg9l",
        "socialLinks": []
    }
}
/* eslint-disable global-require */
const userprofiletUri = "/userprofile";
const url = new RegExp(`${userprofiletUri}/*`);

mock.onGet(url).reply(200, data);
