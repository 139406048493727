import axios from 'axios'

export default {
    namespaced: true,
    state: {
        windowWidth: 0,
        isMobile: false,
        layout: '',
        user: null,
    },
    getters: {
        isMobile: state => state.isMobile,
        loggedIn: state => {
            return !!state.user
        }
    },
    mutations: {
        UPDATE_WINDOW_WIDTH(state, val) {
            state.windowWidth = val
        },
        UPDATE_IS_MOBILE(state, val) {
            state.isMobile = val;
        },
        SET_USER_DATA(state, userData) {
            state.user = userData;
            localStorage.setItem("user", JSON.stringify(userData));
            axios.defaults.headers.common[
                "Authorization"
                ] = `Bearer ${userData.token}`;
        },
        SET_USER_AVATAR(state, userAvatar) {
            state.user.userData.data.profile.avatarAsset.filename = userAvatar;
        },
        LOGOUT() {
            localStorage.removeItem("user");
            location.reload();
        }
    },
    actions: {
        register({commit}, credentials) {
            return axios
                .post("//13.125.234.124:3002/register", credentials)
                .then(({data}) => {
                    console.log("user data is", data);
                    commit("SET_USER_DATA", data);
                });
        },
        login({commit}, credentials) {
            return axios
                .post('//13.125.234.124:3002/login', credentials)
                .then(({data}) => {
                    commit('SET_USER_DATA', data)
                })
        },
        logout({ commit }) {
            commit("LOGOUT");
        },
    },
}
