import mock from '@/@fake-db/mock'
/* eslint-disable global-require */
const data = {

    "profile": {
        "avatarAsset": {
            "__typename": "MultimediaAsset",
            "contentType": "image/jpg",
            "filename": "aefd8869-318e-4226-8cf6-0c5a3c39aadf.jpeg",
            "id": "aefd8869-318e-4226-8cf6-0c5a3c39aadf",
            "imageTransform": null
        },
        "description": "Vérité’s career has always been an exploration of how to create and build outside traditional structures and her experimentation in web3 is a natural progression for an artist who’s always existed outside the lines. A prolific and “spellbinding” (NPR) songwriter, producer and performer, her independently released albums, EPs and singles have garnered 350M+ streams across all platforms with 1M+ monthly listeners. Experimenting in web 3 is a natural extension of her commitment to independence, innovation and direct connection between artists and their fans. Currently, she’s focusing on the intersection of music and tech, community building, production and reimagining music valuation in web 3.",
        "editions": {
            "edges": [
                {
                    "node": {
                        "heroAsset": {
                            "filename": "44477fdb-7a6a-48a8-8b0d-cd2cce6af359.png"
                        },
                        "permalink": "verite-hes-not-you",
                        "rightsAsset": {
                            "type": "SINGLE"
                        },
                        "royalId": "88bbfb5d-4069-4be6-b593-6ad90a0d4ee8",
                        "tiers": [
                            {
                                "tierSale": {
                                    "saleConfig": {
                                        "__typename": "PrimeTierSaleStrategyFlash",
                                        "startsAt": "2022-02-10T23:00:00Z"
                                    },
                                    "status": "COMPLETED"
                                }
                            },
                            {
                                "tierSale": {
                                    "saleConfig": {
                                        "__typename": "PrimeTierSaleStrategyFlash",
                                        "startsAt": "2022-02-10T23:00:00Z"
                                    },
                                    "status": "COMPLETED"
                                }
                            },
                            {
                                "tierSale": {
                                    "saleConfig": {
                                        "__typename": "PrimeTierSaleStrategyFlash",
                                        "startsAt": "2022-02-10T23:00:00Z"
                                    },
                                    "status": "COMPLETED"
                                }
                            }
                        ],
                        "title": "He's Not You"
                    }
                },
                {
                    "node": {
                        "heroAsset": {
                            "filename": "a0f3fc4a-b6c8-48d9-b2d0-1db317256471.jpg"
                        },
                        "permalink": "waveform",
                        "rightsAsset": {
                            "type": "SINGLE"
                        },
                        "royalId": "2b353463-fff6-4392-8a1b-e3ca8f13269b",
                        "tiers": [
                            {
                                "tierSale": null
                            }
                        ],
                        "title": "WAVEFORM"
                    }
                }
            ]
        },
        "heroAsset": {
            "__typename": "MultimediaAsset",
            "contentType": "image/jpg",
            "filename": "https://royal-io.imgix.net/ce568298-4671-422c-9ec0-3b2f689b8ef2.jpeg",
            "id": "ce568298-4671-422c-9ec0-3b2f689b8ef2",
            "imageTransform": null
        },
        "id": "UHJvZmlsZTo0MzVjNmQ5Ny1kZDZlLTQ1MTYtYmZkMi03ZTY1ZDlmZmMwNTY=",
        "name": "Vérité",
        "owner": {
            "royalId": "12ced4ea-f5ba-46a2-9005-272c56c25300",
            "wallets": [
                {
                    "address": "0x69941a001733ad076cfbf8dec429a4d08d5284b2",
                    "tokens": {
                        "edges": []
                    }
                },
                {
                    "address": "0x69941a001733ad076cfbf8dec429a4d08d5284b2",
                    "tokens": {
                        "edges": [
                            {
                                "node": {
                                    "__typename": "RightsToken",
                                    "animationUrl": null,
                                    "artworkAsset": {
                                        "__typename": "MultimediaAsset",
                                        "contentType": "image/jpg",
                                        "filename": "fdb42eac-6599-44fc-910c-396f178b6866.jpg",
                                        "id": "fdb42eac-6599-44fc-910c-396f178b6866",
                                        "imageTransform": null
                                    },
                                    "id": "UmlnaHRzVG9rZW46NDRiODcxZGMtN2NiNC00N2QxLWEwOTktMTNhZmEzNzAxOGEw",
                                    "mintNumber": 4,
                                    "royalId": "44b871dc-7cb4-47d1-a099-13afa37018a0",
                                    "tier": {
                                        "chainContract": {
                                            "chainId": "137"
                                        },
                                        "edition": {
                                            "contributors": [
                                                {
                                                    "profile": {
                                                        "avatarAsset": {
                                                            "__typename": "MultimediaAsset",
                                                            "contentType": "image/jpg",
                                                            "filename": "4730bb97-77cd-445c-b702-d127c9a85b55.jpg",
                                                            "id": "4730bb97-77cd-445c-b702-d127c9a85b55",
                                                            "imageTransform": null
                                                        },
                                                        "name": "Ollie",
                                                        "slug": "Ollie"
                                                    }
                                                }
                                            ],
                                            "permalink": "ollie-underrated",
                                            "rightsAsset": {
                                                "childAssets": [
                                                    {
                                                        "royalId": "c8685afa-bd9b-463a-8fe6-9c181319a342"
                                                    },
                                                    {
                                                        "royalId": "1cc88c34-57a4-4b50-96bb-fc091a4de418"
                                                    },
                                                    {
                                                        "royalId": "6c7096bf-c384-4469-800b-f4a13514881b"
                                                    },
                                                    {
                                                        "royalId": "97011f2b-6173-481b-aefe-78379c31df7a"
                                                    }
                                                ],
                                                "title": "Underrated",
                                                "type": "EP"
                                            },
                                            "title": "Underrated"
                                        },
                                        "maxSupply": 600,
                                        "type": "GOLD"
                                    },
                                    "tokenId": "9868188640707215440437863615521278132228",
                                    "wallet": {
                                        "address": "0xe54444985a178d608f29e71976aaeaef48c2d419",
                                        "chainId": "137",
                                        "id": "fdadeabf-6246-4547-bb6a-9df9a4874f11",
                                        "source": "METAMASK",
                                        "user": {
                                            "profiles": [
                                                {
                                                    "avatarAsset": {
                                                        "__typename": "MultimediaAsset",
                                                        "contentType": "image/jpg",
                                                        "filename": "4730bb97-77cd-445c-b702-d127c9a85b55.jpg",
                                                        "id": "4730bb97-77cd-445c-b702-d127c9a85b55",
                                                        "imageTransform": null
                                                    },
                                                    "slug": "Ollie"
                                                }
                                            ],
                                            "royalId": "b891a47f-b223-4a36-8ad7-b03293f62a7e"
                                        }
                                    }
                                }
                            },
                            {
                                "node": {
                                    "__typename": "RightsToken",
                                    "animationUrl": null,
                                    "artworkAsset": {
                                        "__typename": "MultimediaAsset",
                                        "contentType": "image/jpg",
                                        "filename": "fc02a4d2-cf45-49cc-a47d-38f96825726d.jpg",
                                        "id": "fc02a4d2-cf45-49cc-a47d-38f96825726d",
                                        "imageTransform": null
                                    },
                                    "id": "UmlnaHRzVG9rZW46MzI1ZDZiMDAtYTNhMy00OTEyLWFjYTctYjkyNzdjOTk4MzQz",
                                    "mintNumber": 5,
                                    "royalId": "325d6b00-a3a3-4912-aca7-b9277c998343",
                                    "tier": {
                                        "chainContract": {
                                            "chainId": "137"
                                        },
                                        "edition": {
                                            "contributors": [
                                                {
                                                    "profile": {
                                                        "avatarAsset": {
                                                            "__typename": "MultimediaAsset",
                                                            "contentType": "image/jpg",
                                                            "filename": "4730bb97-77cd-445c-b702-d127c9a85b55.jpg",
                                                            "id": "4730bb97-77cd-445c-b702-d127c9a85b55",
                                                            "imageTransform": null
                                                        },
                                                        "name": "Ollie",
                                                        "slug": "Ollie"
                                                    }
                                                }
                                            ],
                                            "permalink": "ollie-underrated",
                                            "rightsAsset": {
                                                "childAssets": [
                                                    {
                                                        "royalId": "c8685afa-bd9b-463a-8fe6-9c181319a342"
                                                    },
                                                    {
                                                        "royalId": "1cc88c34-57a4-4b50-96bb-fc091a4de418"
                                                    },
                                                    {
                                                        "royalId": "6c7096bf-c384-4469-800b-f4a13514881b"
                                                    },
                                                    {
                                                        "royalId": "97011f2b-6173-481b-aefe-78379c31df7a"
                                                    }
                                                ],
                                                "title": "Underrated",
                                                "type": "EP"
                                            },
                                            "title": "Underrated"
                                        },
                                        "maxSupply": 600,
                                        "type": "GOLD"
                                    },
                                    "tokenId": "9868188640707215440437863615521278132229",
                                    "wallet": {
                                        "address": "0xe54444985a178d608f29e71976aaeaef48c2d419",
                                        "chainId": "137",
                                        "id": "fdadeabf-6246-4547-bb6a-9df9a4874f11",
                                        "source": "METAMASK",
                                        "user": {
                                            "profiles": [
                                                {
                                                    "avatarAsset": {
                                                        "__typename": "MultimediaAsset",
                                                        "contentType": "image/jpg",
                                                        "filename": "4730bb97-77cd-445c-b702-d127c9a85b55.jpg",
                                                        "id": "4730bb97-77cd-445c-b702-d127c9a85b55",
                                                        "imageTransform": null
                                                    },
                                                    "slug": "Ollie"
                                                }
                                            ],
                                            "royalId": "b891a47f-b223-4a36-8ad7-b03293f62a7e"
                                        }
                                    }
                                }
                            },
                            {
                                "node": {
                                    "__typename": "RightsToken",
                                    "animationUrl": null,
                                    "artworkAsset": {
                                        "__typename": "MultimediaAsset",
                                        "contentType": "image/jpg",
                                        "filename": "18960b1d-f152-40f3-90c1-bdce1d29aad5.jpg",
                                        "id": "18960b1d-f152-40f3-90c1-bdce1d29aad5",
                                        "imageTransform": null
                                    },
                                    "id": "UmlnaHRzVG9rZW46NTU2ZjUxNzctZGUxOS00ZTZkLWI0ZTctYTRiOTI5ODY0NzZj",
                                    "mintNumber": 272,
                                    "royalId": "556f5177-de19-4e6d-b4e7-a4b92986476c",
                                    "tier": {
                                        "chainContract": {
                                            "chainId": "137"
                                        },
                                        "edition": {
                                            "contributors": [
                                                {
                                                    "profile": {
                                                        "avatarAsset": {
                                                            "__typename": "MultimediaAsset",
                                                            "contentType": "image/jpg",
                                                            "filename": "4730bb97-77cd-445c-b702-d127c9a85b55.jpg",
                                                            "id": "4730bb97-77cd-445c-b702-d127c9a85b55",
                                                            "imageTransform": null
                                                        },
                                                        "name": "Ollie",
                                                        "slug": "Ollie"
                                                    }
                                                }
                                            ],
                                            "permalink": "ollie-underrated",
                                            "rightsAsset": {
                                                "childAssets": [
                                                    {
                                                        "royalId": "c8685afa-bd9b-463a-8fe6-9c181319a342"
                                                    },
                                                    {
                                                        "royalId": "1cc88c34-57a4-4b50-96bb-fc091a4de418"
                                                    },
                                                    {
                                                        "royalId": "6c7096bf-c384-4469-800b-f4a13514881b"
                                                    },
                                                    {
                                                        "royalId": "97011f2b-6173-481b-aefe-78379c31df7a"
                                                    }
                                                ],
                                                "title": "Underrated",
                                                "type": "EP"
                                            },
                                            "title": "Underrated"
                                        },
                                        "maxSupply": 600,
                                        "type": "GOLD"
                                    },
                                    "tokenId": "9868188640707215440437863615521278132496",
                                    "wallet": {
                                        "address": "0xe54444985a178d608f29e71976aaeaef48c2d419",
                                        "chainId": "137",
                                        "id": "fdadeabf-6246-4547-bb6a-9df9a4874f11",
                                        "source": "METAMASK",
                                        "user": {
                                            "profiles": [
                                                {
                                                    "avatarAsset": {
                                                        "__typename": "MultimediaAsset",
                                                        "contentType": "image/jpg",
                                                        "filename": "4730bb97-77cd-445c-b702-d127c9a85b55.jpg",
                                                        "id": "4730bb97-77cd-445c-b702-d127c9a85b55",
                                                        "imageTransform": null
                                                    },
                                                    "slug": "Ollie"
                                                }
                                            ],
                                            "royalId": "b891a47f-b223-4a36-8ad7-b03293f62a7e"
                                        }
                                    }
                                }
                            }
                        ]
                    }
                }
            ]
        },
        "profileStars": [
            {
                "id": "bdc9da90-a336-49ba-8528-04a307c7f5ce",
                "user": {
                    "wallets": [
                        {
                            "address": "0x69941a001733ad076cfbf8dec429a4d08d5284b2"
                        },
                        {
                            "address": "0x69941a001733ad076cfbf8dec429a4d08d5284b2"
                        }
                    ]
                }
            }
        ],
        "slug": "Verite",
        "socialLinks": [
            {
                "id": "51876f29-a132-48b8-bdb4-4b2d12fc67bc",
                "url": "https://twitter.com/3lau"
            },
            {
                "id": "1ecea524-2327-456f-9e8c-3a9497d31945",
                "url": "https://open.spotify.com/artist/4YLQaW1UU3mrVetC8gNkg5"
            },
            {
                "id": "b1fb712c-87e8-48f6-99d5-7f97392773f0",
                "url": "https://www.youtube.com/channel/UCdCWh6Kw9tNKOon5lnf09bg"
            }]
    }

}
/* eslint-disable global-require */
const artistUri = "/artist";
const url = new RegExp(`${artistUri}/*`);

mock.onGet(url).reply(200, data);
