import mock from '@/@fake-db/mock'
/* eslint-disable global-require */
const data = {
    "profile": {
        "avatarAsset": {
            "__typename": "MultimediaAsset",
            "contentType": "image/jpeg",
            "filename": "43042933-3aa6-4ab6-9f14-222873e602f1.jpg",
            "id": "43042933-3aa6-4ab6-9f14-222873e602f1",
            "imageTransform": null
        },
        "description": null,
        "editions": {
            "edges": []
        },
        "heroAsset": null,
        "id": "UHJvZmlsZTpiN2RkMDY2My1iM2U5LTRlNDItYjA5NS0zOTk2NmQyNmE5MTM=",
        "name": "홍대의",
        "owner": {
            "royalId": "d8e14bb8-5900-4052-af1c-41016bad927a",
            "wallets": [
                {
                    "address": "0xb66e1651f52e9b520af194fbd4aeb36e43924ec2",
                    "tokens": {
                        "edges": []
                    }
                },
                {
                    "address": "0xb66e1651f52e9b520af194fbd4aeb36e43924ec2",
                    "tokens": {
                        "edges": [
                            {
                                "node": {
                                    "__typename": "RightsToken",
                                    "animationUrl": null,
                                    "artworkAsset": {
                                        "__typename": "MultimediaAsset",
                                        "contentType": "image/png",
                                        "filename": "01f07919-a2a0-4bfa-9627-51557fe2512e.png",
                                        "id": "01f07919-a2a0-4bfa-9627-51557fe2512e",
                                        "imageTransform": null
                                    },
                                    "id": "UmlnaHRzVG9rZW46NjlmYmRmMjktNzc4Yy00ZjU2LTgzMWUtYzBjMmY4YWNmNTNi",
                                    "mintNumber": 1,
                                    "royalId": "69fbdf29-778c-4f56-831e-c0c2f8acf53b",
                                    "tier": {
                                        "chainContract": {
                                            "chainId": "137"
                                        },
                                        "edition": {
                                            "contractAsset": {
                                                "downloadUrl": "https://royalio-prod-storage.s3.us-east-2.amazonaws.com/abfb0e7a-6d0a-4908-a3e9-cf87d1275349.pdf?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAXY35ED6HVSOV45B6%2F20220322%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Date=20220322T013738Z&X-Amz-Expires=3600&X-Amz-SignedHeaders=host&X-Amz-Signature=4f7cb8c4c09d3a41c607caa75e6e515cf5e41adab0f8f2e228295d2dd6be763b",
                                                "externalUrl": null,
                                                "filename": "abfb0e7a-6d0a-4908-a3e9-cf87d1275349.pdf",
                                                "type": "BLOB"
                                            },
                                            "contributors": [
                                                {
                                                    "profile": {
                                                        "avatarAsset": {
                                                            "__typename": "MultimediaAsset",
                                                            "contentType": "image/jpeg",
                                                            "filename": "3b563eb1-01c5-4f4d-8214-bb02eecbd4b5.jpg",
                                                            "id": "3b563eb1-01c5-4f4d-8214-bb02eecbd4b5",
                                                            "imageTransform": null
                                                        },
                                                        "name": "Nas",
                                                        "slug": "Nas"
                                                    }
                                                }
                                            ],
                                            "permalink": "nas-rare",
                                            "rightsAsset": {
                                                "childAssets": [],
                                                "title": "Rare",
                                                "type": "SINGLE"
                                            },
                                            "title": "Rare"
                                        },
                                        "maxSupply": 400,
                                        "type": "PLATINUM"
                                    },
                                    "tokenId": "2041694201525630780780247644590609268737",
                                    "wallet": {
                                        "address": "0xf5b0533bad49b29a41ea5b96fd1c43acbba37201",
                                        "chainId": "137",
                                        "id": "c5c1cda8-0c8c-4a6b-824b-49d210c9ef51",
                                        "source": "METAMASK",
                                        "user": null
                                    }
                                }
                            },
                            {
                                "node": {
                                    "__typename": "RightsToken",
                                    "animationUrl": null,
                                    "artworkAsset": {
                                        "__typename": "MultimediaAsset",
                                        "contentType": "image/jpg",
                                        "filename": "dde26975-0af0-4401-88e6-e7c6c145ae27.jpg",
                                        "id": "dde26975-0af0-4401-88e6-e7c6c145ae27",
                                        "imageTransform": null
                                    },
                                    "id": "UmlnaHRzVG9rZW46NjlhMTg2MmYtZTVmZC00ZTU5LWJiZDEtZjJiNGRiNzliZjRm",
                                    "mintNumber": 1,
                                    "royalId": "69a1862f-e5fd-4e59-bbd1-f2b4db79bf4f",
                                    "tier": {
                                        "chainContract": {
                                            "chainId": "137"
                                        },
                                        "edition": {
                                            "contractAsset": {
                                                "downloadUrl": "https://royalio-prod-storage.s3.us-east-2.amazonaws.com/5c87f98f-4b6f-43f9-bcc0-2f6a2bf73e89.pdf?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAXY35ED6HVSOV45B6%2F20220322%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Date=20220322T013738Z&X-Amz-Expires=3600&X-Amz-SignedHeaders=host&X-Amz-Signature=fb1841fdd807cd233a3f42d265ef82beacf1b8194af7bfe5699dfa35c0e4f6b1",
                                                "externalUrl": null,
                                                "filename": "5c87f98f-4b6f-43f9-bcc0-2f6a2bf73e89.pdf",
                                                "type": "BLOB"
                                            },
                                            "contributors": [
                                                {
                                                    "profile": {
                                                        "avatarAsset": {
                                                            "__typename": "MultimediaAsset",
                                                            "contentType": "image/jpg",
                                                            "filename": "4730bb97-77cd-445c-b702-d127c9a85b55.jpg",
                                                            "id": "4730bb97-77cd-445c-b702-d127c9a85b55",
                                                            "imageTransform": null
                                                        },
                                                        "name": "Ollie",
                                                        "slug": "Ollie"
                                                    }
                                                }
                                            ],
                                            "permalink": "ollie-underrated",
                                            "rightsAsset": {
                                                "childAssets": [
                                                    {
                                                        "royalId": "c8685afa-bd9b-463a-8fe6-9c181319a342"
                                                    },
                                                    {
                                                        "royalId": "1cc88c34-57a4-4b50-96bb-fc091a4de418"
                                                    },
                                                    {
                                                        "royalId": "6c7096bf-c384-4469-800b-f4a13514881b"
                                                    },
                                                    {
                                                        "royalId": "97011f2b-6173-481b-aefe-78379c31df7a"
                                                    }
                                                ],
                                                "title": "Underrated",
                                                "type": "EP"
                                            },
                                            "title": "Underrated"
                                        },
                                        "maxSupply": 50,
                                        "type": "PLATINUM"
                                    },
                                    "tokenId": "9527906273786276976974489008089509920769",
                                    "wallet": {
                                        "address": "0x975461115fb1c728349be48b526241ff0b859870",
                                        "chainId": "137",
                                        "id": "2aa096c8-eaa4-45e8-9816-385754cccf95",
                                        "source": "METAMASK",
                                        "user": {
                                            "profiles": [
                                                {
                                                    "avatarAsset": null,
                                                    "slug": "_UG1X4KLwsTYx13TR35N"
                                                }
                                            ],
                                            "royalId": "5da5d633-4bf6-4f27-96ae-b6c1a7f0d988"
                                        }
                                    }
                                }
                            },
                            {
                                "node": {
                                    "__typename": "RightsToken",
                                    "animationUrl": "https://api.royal.io/assets/26bd3138-af95-40e8-a443-c939cc3311bb",
                                    "artworkAsset": {
                                        "__typename": "MultimediaAsset",
                                        "contentType": "image/png",
                                        "filename": "69a8b75d-28bd-4754-8df8-81294d1c5d17.png",
                                        "id": "69a8b75d-28bd-4754-8df8-81294d1c5d17",
                                        "imageTransform": null
                                    },
                                    "id": "UmlnaHRzVG9rZW46YjdlYTU2MWQtMmY3Ny00NmI3LWEyOTAtNDE5ZmM4NWZiNThj",
                                    "mintNumber": 1,
                                    "royalId": "b7ea561d-2f77-46b7-a290-419fc85fb58c",
                                    "tier": {
                                        "chainContract": {
                                            "chainId": "137"
                                        },
                                        "edition": {
                                            "contractAsset": {
                                                "downloadUrl": "https://royalio-prod-storage.s3.us-east-2.amazonaws.com/5c87f98f-4b6f-43f9-bcc0-2f6a2bf73e89.pdf?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAXY35ED6HVSOV45B6%2F20220322%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Date=20220322T013738Z&X-Amz-Expires=3600&X-Amz-SignedHeaders=host&X-Amz-Signature=fb1841fdd807cd233a3f42d265ef82beacf1b8194af7bfe5699dfa35c0e4f6b1",
                                                "externalUrl": null,
                                                "filename": "5c87f98f-4b6f-43f9-bcc0-2f6a2bf73e89.pdf",
                                                "type": "BLOB"
                                            },
                                            "contributors": [
                                                {
                                                    "profile": {
                                                        "avatarAsset": {
                                                            "__typename": "MultimediaAsset",
                                                            "contentType": "image/jpg",
                                                            "filename": "4730bb97-77cd-445c-b702-d127c9a85b55.jpg",
                                                            "id": "4730bb97-77cd-445c-b702-d127c9a85b55",
                                                            "imageTransform": null
                                                        },
                                                        "name": "Ollie",
                                                        "slug": "Ollie"
                                                    }
                                                }
                                            ],
                                            "permalink": "ollie-underrated",
                                            "rightsAsset": {
                                                "childAssets": [
                                                    {
                                                        "royalId": "c8685afa-bd9b-463a-8fe6-9c181319a342"
                                                    },
                                                    {
                                                        "royalId": "1cc88c34-57a4-4b50-96bb-fc091a4de418"
                                                    },
                                                    {
                                                        "royalId": "6c7096bf-c384-4469-800b-f4a13514881b"
                                                    },
                                                    {
                                                        "royalId": "97011f2b-6173-481b-aefe-78379c31df7a"
                                                    }
                                                ],
                                                "title": "Underrated",
                                                "type": "EP"
                                            },
                                            "title": "Underrated"
                                        },
                                        "maxSupply": 10,
                                        "type": "DIAMOND"
                                    },
                                    "tokenId": "9187623906865338513511114400657741709313",
                                    "wallet": {
                                        "address": "0xc5bf5e2b135d1e07660ead4ee76e46062d41238e",
                                        "chainId": "137",
                                        "id": "f6cfbaf4-edb9-4f01-bb01-b609bb6ed335",
                                        "source": "METAMASK",
                                        "user": {
                                            "profiles": [
                                                {
                                                    "avatarAsset": {
                                                        "__typename": "MultimediaAsset",
                                                        "contentType": "image/png",
                                                        "filename": "8d385580-24ea-42f6-9f49-afe5e88ec0eb.png",
                                                        "id": "8d385580-24ea-42f6-9f49-afe5e88ec0eb",
                                                        "imageTransform": null
                                                    },
                                                    "slug": "UKHwnWJMbDQD7N6Jgg9l"
                                                }
                                            ],
                                            "royalId": "1d18a9be-11f7-4115-9b4c-5b5a80cefb9b"
                                        }
                                    }
                                }
                            },
                            {
                                "node": {
                                    "__typename": "RightsToken",
                                    "animationUrl": null,
                                    "artworkAsset": {
                                        "__typename": "MultimediaAsset",
                                        "contentType": "image/jpeg",
                                        "filename": "a0f3fc4a-b6c8-48d9-b2d0-1db317256471.jpg",
                                        "id": "a0f3fc4a-b6c8-48d9-b2d0-1db317256471",
                                        "imageTransform": null
                                    },
                                    "id": "UmlnaHRzVG9rZW46MjJmOWFjYTQtODk2OC00NjI0LWJmNjktZGYzYTE1OWRmZDBl",
                                    "mintNumber": 1,
                                    "royalId": "22f9aca4-8968-4624-bf69-df3a159dfd0e",
                                    "tier": {
                                        "chainContract": {
                                            "chainId": "1"
                                        },
                                        "edition": {
                                            "contractAsset": {
                                                "downloadUrl": "https://royalio-prod-storage.s3.us-east-2.amazonaws.com/a2fe6b89-a80e-4bca-b5de-0ab77a915ce8.pdf?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAXY35ED6HVSOV45B6%2F20220322%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Date=20220322T013738Z&X-Amz-Expires=3600&X-Amz-SignedHeaders=host&X-Amz-Signature=d9a2fb0e9b43d8ad47f4ddb509faf766c9926774fd2e022eb6a4392a01db09fe",
                                                "externalUrl": null,
                                                "filename": "a2fe6b89-a80e-4bca-b5de-0ab77a915ce8.pdf",
                                                "type": "BLOB"
                                            },
                                            "contributors": [
                                                {
                                                    "profile": {
                                                        "avatarAsset": {
                                                            "__typename": "MultimediaAsset",
                                                            "contentType": "image/jpeg",
                                                            "filename": "e9b746d6-7aa7-412b-9b18-06e9db4c33d4.jpg",
                                                            "id": "e9b746d6-7aa7-412b-9b18-06e9db4c33d4",
                                                            "imageTransform": null
                                                        },
                                                        "name": "3LAU",
                                                        "slug": "3LAU"
                                                    }
                                                }
                                            ],
                                            "permalink": "waveform",
                                            "rightsAsset": {
                                                "childAssets": [],
                                                "title": "WAVEFORM",
                                                "type": "SINGLE"
                                            },
                                            "title": "WAVEFORM"
                                        },
                                        "maxSupply": 1,
                                        "type": "PLATINUM"
                                    },
                                    "tokenId": "680564733841876926926749214863536422913",
                                    "wallet": {
                                        "address": "0x336A447B2e382DDe1cFf3532C8218e1F57757a7d",
                                        "chainId": "1",
                                        "id": "dc1ddefc-d272-4c89-b74a-54165fb2086a",
                                        "source": "METAMASK",
                                        "user": null
                                    }
                                }
                            },
                            {
                                "node": {
                                    "__typename": "RightsToken",
                                    "animationUrl": null,
                                    "artworkAsset": {
                                        "__typename": "MultimediaAsset",
                                        "contentType": "image/png",
                                        "filename": "1027ae94-993d-4e7b-8554-c36ac2aa33b5.png",
                                        "id": "1027ae94-993d-4e7b-8554-c36ac2aa33b5",
                                        "imageTransform": null
                                    },
                                    "id": "UmlnaHRzVG9rZW46MTU5YWJkYzMtY2Y5MS00YTBhLTk4MTktNGZmMzAzZGFhM2Ux",
                                    "mintNumber": 1,
                                    "royalId": "159abdc3-cf91-4a0a-9819-4ff303daa3e1",
                                    "tier": {
                                        "chainContract": {
                                            "chainId": "137"
                                        },
                                        "edition": {
                                            "contractAsset": {
                                                "downloadUrl": "https://royalio-prod-storage.s3.us-east-2.amazonaws.com/1cdd01aa-f6db-445f-bf1a-d4fa2f78be3e.pdf?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAXY35ED6HVSOV45B6%2F20220322%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Date=20220322T013738Z&X-Amz-Expires=3600&X-Amz-SignedHeaders=host&X-Amz-Signature=f900eace01b01e9a88e68256068a57397bf0d94887768501184e926f3e44ab54",
                                                "externalUrl": null,
                                                "filename": "1cdd01aa-f6db-445f-bf1a-d4fa2f78be3e.pdf",
                                                "type": "BLOB"
                                            },
                                            "contributors": [
                                                {
                                                    "profile": {
                                                        "avatarAsset": {
                                                            "__typename": "MultimediaAsset",
                                                            "contentType": "image/jpeg",
                                                            "filename": "3b563eb1-01c5-4f4d-8214-bb02eecbd4b5.jpg",
                                                            "id": "3b563eb1-01c5-4f4d-8214-bb02eecbd4b5",
                                                            "imageTransform": null
                                                        },
                                                        "name": "Nas",
                                                        "slug": "Nas"
                                                    }
                                                }
                                            ],
                                            "permalink": "nas-ultrablack",
                                            "rightsAsset": {
                                                "childAssets": [],
                                                "title": "Ultra Black",
                                                "type": "SINGLE"
                                            },
                                            "title": "Ultra Black"
                                        },
                                        "maxSupply": 500,
                                        "type": "GOLD"
                                    },
                                    "tokenId": "1361129467683753853853498429727072845825",
                                    "wallet": {
                                        "address": "0x1C3a11189aF8c60E21FE09b193532A0232F9F6D2",
                                        "chainId": "137",
                                        "id": "7e3dc908-f8fb-4108-a045-4dc627d12bb6",
                                        "source": "ROYAL",
                                        "user": {
                                            "profiles": [
                                                {
                                                    "avatarAsset": {
                                                        "__typename": "MultimediaAsset",
                                                        "contentType": "image/jpeg",
                                                        "filename": "99a37927-40a2-4fec-af07-fb2dc1761d87.jpg",
                                                        "id": "99a37927-40a2-4fec-af07-fb2dc1761d87",
                                                        "imageTransform": null
                                                    },
                                                    "slug": "Carib2G"
                                                }
                                            ],
                                            "royalId": "f26a006f-40e4-4808-9162-911dcf3c1630"
                                        }
                                    }
                                }
                            }
                        ]
                    }
                }
            ]
        },
        "profileStars": [
            {
                "id": "67a268fd-3d15-4af6-a3d6-90bc77d8a9f6",
                "user": {
                    "wallets": [
                        {
                            "address": "0xb66e1651f52e9b520af194fbd4aeb36e43924ec2"
                        },
                        {
                            "address": "0xb66e1651f52e9b520af194fbd4aeb36e43924ec2"
                        }
                    ]
                }
            }
        ],
        "slug": "ukyoKdsOgqe7oAcdbyY8",
        "socialLinks": []
    }
}
/* eslint-disable global-require */
const myprofiletUri = "/myprofile";
const url = new RegExp(`${myprofiletUri}/*`);

mock.onGet(url).reply(200, data);
